import React from "react";
import {Link} from "react-router-dom";

const HelpSubContent = (props) => {
    return(
        <>
            <div className="col-lg-3 col-md-6 mb-4">
                <Link to={props.url}>
                    <div className="important-topics-content text-center text-white">
                        <span><img src={props.image} alt="union" /></span>
                        <h6 className="mt-4">{props.name}</h6>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default HelpSubContent;
