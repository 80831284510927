/*
import React, {useState, useEffect} from "react";

const TablePagination = ({goToPreviousPage, getPaginationGroup, goToNextPage , currentPage, changePage,pages}) => {

    /!*const [counter,setCounter] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(Math.ceil(total_users/showPerPage));

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(10);
    const [pageArray] = useState([]);


    const [showNavLink,setShowNavLink] = useState(total_users);
    /!*const[upperPageBound, setUpperPageBound] = useState(3);
    const[lowerPageBound, setLowerPageBound] = useState(0);
    const[pageBound, setPageBound] = useState(3);
*!/
    useEffect(() => {
        const end_value = showPerPage * counter;
        const start_value = end_value - showPerPage;
        onPaginationChange(start_value,end_value);

    },[counter]);

    const updatePage = (key) => {
        setCounter(key);
        setEndIndex(endIndex+1)
        pageArray.push((key))
        console.log(pageArray)


    };

    console.log(counter);


    const getCounter = (type) => {
     if(type === 'prev'){
            if(counter === 1){
                setCounter(1)
            }else{
                setCounter(counter - 1);
            }
        }else if(type === 'next'){
            if(numberOfPages === counter){
                setCounter(counter);
            }else{
                setCounter(counter + 1);
            }
        }
    };*!/



    return(
        <React.Fragment>
            <nav aria-label="Page navigation example" style={{width:"100%", overflow:"auto"}}>
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}><a className="page-link" onClick={goToPreviousPage}>Previous</a></li>

                    { getPaginationGroup().map((item, index) => (
                        <li className={`page-item ${currentPage === item ? 'active' : ""}`} key={index}>
                            <a className="page-link" onClick={changePage}>{item}</a>
                        </li>
                    ))}

                    <li className="page-item"><a className="page-link" onClick={goToNextPage}>Next</a></li>


                    {/!*<li className="page-item"><a className="page-link" onClick={() => getCounter('prev')}>Previous</a></li>
                    {
                        new Array(showNavLink).fill('').slice(startIndex, endIndex).map((val, key)=>(
                            <li className={`page-item ${key+1 === counter ? "active" : ""}`} key={key}>
                                <a className="page-link" onClick={()=> updatePage(key+1)}>{key+1}</a>
                            </li>
                        ))
                    }
                    <li className="page-item"><a className="page-link" onClick={() => getCounter('next')}>Next</a></li>*!/}
                </ul>
            </nav>
        </React.Fragment>
    )
}

export default TablePagination;
*/

import React, {Component, useState} from 'react'
import axios from 'axios'
import ReactPaginate from 'react-paginate';
/*const [offset,setOffset] = useState(0);
const [perPage, setPerPage] = useState(10);
const [currentPage, setCurrentPage] = useState(0);*/

export default class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0
        };
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }
    receivedData() {
        axios
            .get(`https://jsonplaceholder.typicode.com/photos`)
            .then(res => {

                const data = res.data;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(pd => <React.Fragment>
                    <p>{pd.title}</p>
                    <img src={pd.thumbnailUrl} alt=""/>
                </React.Fragment>)

                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),

                    postData
                })
            });
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        this.receivedData()
    }
    render() {
        return (
            <div>
                {this.state.postData}
                <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
            </div>

        )
    }
}
