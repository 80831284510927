import axios from "axios";
import {baseURL} from "../global/global";
import {setAlert} from "./alert";

export const uploadVideo = (data, setLoader,history)=>(dispatch, getState)=>{
    axios({
        method: 'POST',
        url: `${baseURL}/create-campaign`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token }
    }).then(res=>{
        if(res.data.status===true){
            dispatch({type: "UPLOAD_VIDEO", payload: res.data.data});
            history.push(`/editor?id=${res.data.data.id}`);
        }
        else{
            dispatch(setAlert(res.data.message,'danger'))
        }
        setLoader(false);
    }).catch(err=>{
        setLoader(false);
    })
}

export const fetchCampaign = ()=>(dispatch, getState)=>{
    axios({
        method: 'POST',
        url: `${baseURL}/create-campaign`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token }
    }).then(res=>{
        if(res.data.status===true){
            dispatch({type: "CURRENT_CAMP", payload: res.data.data});
            console.log(res.data.data)
        }
        else{
            console.log('not found')
        }
    }).catch(err=>{
        console.log(err)
    })
}



export const uploadThumbnail = (data, setLoadLogo)=>(dispatch, getState)=>{
    axios({
        method: 'POST',
        url: `${baseURL}/upload-media`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token }
    }).then(res=>{
        if(res.data.status==="success"){
            dispatch({type: "UPLOAD_THUMBNAIL", payload: res.data.data.file_url});
            console.log(res.data.data.file_url)
        }
        else{
            console.log('not found')
        }
        setLoadLogo(false);
    }).catch(err=>{
        setLoadLogo(false);
    })
}

export const searchedDataThumbnail = (data)=>(dispatch)=>{
    dispatch({type: "UPLOAD_THUMBNAIL", payload: data});
}

export const customSKin = (data)=>(dispatch)=>{
    dispatch({type: "CUSTOM_SKIN", payload: data});
}

export const midAdsTime = (data)=>(dispatch)=>{
    dispatch({type: "MID_TIME", payload: data});
}


export const removeThumbnail = (type) => (dispatch) => {
    dispatch({type: type})
}


export const updateMetaData = (type, data) => (dispatch) => {
    dispatch({type: type, payload: data});
}


export const addSocialShare = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_SOCIAL_SHARE', payload: data});
}

export const addAnalyticsData = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_SETTING_DATA', payload: data});
    dispatch(setAlert('Analytic data saved', 'success'));
}

export const addMenuData = (data) => (dispatch) => {
    dispatch({type: 'ADD_MENU_DATA', payload: data});
    dispatch(setAlert('Menu button saved', 'success'));

}

export const updateCtaData = (email, start, end) => (dispatch) => {
    let obj = {
        cta_type: email,
        start_time: (start.minutes*60)+parseInt(start.seconds),
        end_time: (end.minutes*60)+parseInt(end.seconds)
    }
    dispatch({type: 'UPDATE_CTA_DATA', payload: obj})
}

export const addCtaData = (data) => (dispatch) =>{
    dispatch({type: 'ADD_CTA_DATA', payload: data})
    dispatch(setAlert('CTA data saved', 'success'));
}

export const saveAdsData = (data) => (dispatch) => {
    dispatch({type: 'ADD_ADS_DATA', payload: data})
    dispatch(setAlert('Ads data saved', 'success'));
}

export const updateSettings = (type, data) => (dispatch) => {
    dispatch({type: type, payload: data});
}

export const addSkinColor = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_SKIN_COLOR', payload: data});
}

export const updateControlStyle = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_CONTROL_STYLE', payload: data});
}

export const updateControlIcon = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_CONTROL_ICON', payload: data});
}

export const updateControlProgress = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_CONTROL_PROGRESS', payload: data});
}

export const updateControlPlay = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_CONTROL_PLAY', payload: data});
}

export const updateControlVolume = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_CONTROL_VOLUME', payload: data});
}

export const updateControlResize = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_CONTROL_RESIZE', payload: data});
}

export const updateControlPIP = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_CONTROL_PIP', payload: data});
}

export const updateControlSpeed = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_CONTROL_SPEED', payload: data});
}
export const updateControlSetting = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_CONTROL_SETTING', payload: data});
}


export const updateButtonText = (type, data) => (dispatch) => {
    dispatch({type: type, payload: data});
}

export const updateCountDown = (type, data) => (dispatch) => {
    dispatch({type: type, payload: data});
}


export const updateLogoData = (position, file) => (dispatch) => {
    let data = {
        file: file,
        position: position
    }

    dispatch({type: 'UPDATE_LOGO_DATA', payload: data});
}

export const removeLogoData = () => (dispatch) => {
    dispatch({type: 'REMOVE_LOGO_DATA'})
}
