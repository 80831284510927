import Thumbnail from "../thumbnail/Thumbnail";
import React, {useEffect, useState} from "react";
import fbIcon from "../../../images/facebook-shared.png";
import twitterIcon from "../../../images/twitter-shared.png";
import linkedinIcon from "../../../images/linkedin-shared.png";
import instagramIcon from "../../../images/reddit.png";
import {useDispatch, useSelector} from "react-redux";
import {addAnalyticsData, addSocialShare, updateMetaData} from "../../../actions/currentCampaignAction";
import swal from "sweetalert";
import axios from "axios";
import {baseURL} from "../../../global/global";
import {setAlert} from "../../../actions/alert";
import sample from "../../../images/sample.png";
import randomstring from "randomstring";
import AnalyticCode from "./AnalyticCode";
import Alert from "../../Alert";
import MenuButtons from "./MenuButtons";
import CallToAction from "./CallToAction";
import Ads from "./Ads";
import Settings from "./Settings";
import Customize from "./Customize";

const Media = ({currentLayer}) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    // Meta Data
    const [metaTitle,setMetaTitle] = useState(currentLayer.title);
    const [metaDesc,setMetaDesc] = useState(currentLayer.description);
    const [metaPoster, setMetaPoster] = useState(currentLayer.poster);
    const [loadPoster, setLoadPoster] = useState(false);
    const [removePoster, setRemovePoster] = useState(1);

    const [initialData, setInitialData] = useState({
        id: randomstring.generate({length: 9, charset : 'numeric'}),
        value: ''
    })
    const [analyticsCount, setAnalyticsCount] = useState([
        initialData
    ])

    const [socialShare, setSocialShare] = useState({
        facebook: 'none',
        twitter: 'none',
        linkedIn : 'none',
        instagram: 'none'
    })

    const addMoreAnalytics = () => {
        let obj = {
            id: randomstring.generate({length: 9, charset : 'numeric'}),
            value: ''
        }
        let arr = [...analyticsCount];
        arr.push(obj)

        setAnalyticsCount(arr);
    }

    function search(nameKey, myArray){
        for (let i=0; i < myArray.length; i++) {
            if (myArray[i].id === nameKey) {
                return i;
            }
        }
    }

    const inputAnalyticsData = (value, id) => {
        let index = search(id, analyticsCount);

        let newTrans = [...analyticsCount];
        newTrans[index].value = value

        setAnalyticsCount(newTrans);
    }

    const removeMenuButtons = (index) => {
        let arr = [...analyticsCount];
        arr.splice(index,1);

        setAnalyticsCount(arr);
    }

    const saveAnalytics = () => {

        const validation = analyticsCount.every((item) => {
            return Boolean(item.value);
        });

        console.log(validation)

        if(validation){
            dispatch(addAnalyticsData(analyticsCount));
        }
        else{
            swal('Please, fill the analytics code!');
        }
    }

    const onChangeMetaData = (value, type) => {
        if(type === 'title'){
            setMetaTitle(value);

            dispatch(updateMetaData('UPDATE_META_TITLE', value));
        }else if(type === 'description'){
            setMetaDesc(value);

            dispatch(updateMetaData('UPDATE_META_DESC', value));
        }
    }

    const onChangeFile = (e) => {

        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {

            if (e.target.files[0].size < 5000000) {
                setLoadPoster(true);

                let formData = new FormData();
                formData.append('file', e.target.files[0]);
                formData.append('file_type', 'image');

                axios({
                    method: 'POST',
                    url: `${baseURL}/upload-media`,
                    data: formData,
                    headers: {'Content-Type': 'application/json','Authorization': auth.token }
                }).then(res=>{
                    if(res.data.status==="success"){
                        setMetaPoster(res.data.data.file_url);
                        dispatch(updateMetaData('UPDATE_META_POSTER', res.data.data.file_url));
                        dispatch(setAlert(res.data.message, 'success'));
                    }
                    else{
                        dispatch(setAlert(res.data.message, 'danger'));
                    }
                    setLoadPoster(false);

                }).catch(err=>{
                    dispatch(setAlert('Something went wrong, Please try again', 'danger'));
                    setLoadPoster(false);
                })
            } else {
                swal("Oops!", "Max allowed size 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }

    const handleRemoveLogo = () => {
        if (removePoster === 1) {
            setRemovePoster(0);
            dispatch(updateMetaData('REMOVE_META_POSTER', ""));
        } else {
            setRemovePoster(1);
        }
    }

    const handleSocialShare = (type) => {
        let arr = {
            facebook: socialShare.facebook,
            linkedIn: socialShare.linkedIn,
            twitter: socialShare.twitter,
            instagram: socialShare.instagram
        }

        if(type === 'facebook'){
            if(socialShare.facebook === 'none'){
                setSocialShare({...socialShare, facebook: 'block'})
                arr.facebook = 'block';
            }else{
                setSocialShare({...socialShare, facebook: 'none'})
                arr.facebook = 'none';
            }
        }

        if(type === 'twitter'){
            if(socialShare.twitter === 'none'){
                setSocialShare({...socialShare, twitter: 'block'})
                arr.twitter = 'block';
            }else{
                setSocialShare({...socialShare, twitter: 'none'})
                arr.twitter = 'none';
            }
        }

        if(type === 'linkedIn'){
            if(socialShare.linkedIn === 'none'){
                setSocialShare({...socialShare, linkedIn: 'block'})
                arr.linkedIn = 'block';
            }else{
                setSocialShare({...socialShare, linkedIn: 'none'})
                arr.linkedIn = 'none';
            }
        }

        if(type === 'instagram'){
            if(socialShare.instagram === 'none'){
                setSocialShare({...socialShare, instagram: 'block'})
                arr.instagram = 'block';
            }else{
                setSocialShare({...socialShare, instagram: 'none'})
                arr.instagram = 'none';
            }
        }


        dispatch(addSocialShare(arr));
    }


    useEffect(()=>{
        setMetaTitle(currentLayer.title);
        setMetaDesc(currentLayer.description);
        setMetaPoster(currentLayer.poster);
    },[currentLayer.poster])


    useEffect(()=>{
       if(currentLayer.social_share !== false && currentLayer.social_share !=="null" && currentLayer.social_share!==null && currentLayer.social_share!==""){
           setSocialShare({
               facebook: currentLayer.social_share.facebook,
               instagram: currentLayer.social_share.instagram,
               linkedIn: currentLayer.social_share.linkedIn,
               twitter: currentLayer.social_share.twitter
           })
       }
    },[currentLayer.social_share])

    useEffect(()=>{
        if(currentLayer.settings !== ""){
            setAnalyticsCount(currentLayer.settings);
        }
    },[])

    return (
        <>
            <Alert/>
            <div className="col-12 col-lg-4  order-md-first p-0 position-relative">
                <div className="tab-content img-gallery tab-editor" id="pills-tabContent">
                    <Thumbnail/>

                    <div className="tab-pane fade" id="pills-social" role="tabpanel"
                         aria-labelledby="pills-social-tab">
                        <div className="social-content">
                            <h5>Social Metadata</h5>
                            <div className="social-content-box">
                                <div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input type="text" onChange={(e)=>onChangeMetaData(e.target.value, 'title')}
                                                       className="form-control" id="inputTitle" placeholder="Meta title" defaultValue={metaTitle}/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea onChange={(e)=>onChangeMetaData(e.target.value, 'description')} className="form-control"
                                                          defaultValue={metaDesc} rows="4" placeholder="Meta description"/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group thumbnail-form">
                                                <div className="dropzone-wrapper">
                                                    <div className="dropzone-desc">
                                                        <p>
                                                            <i className="fa fa-cloud-upload" aria-hidden="true"/> Upload a Meta Thumbnail <br/>
                                                            <small>(JPG, JPEG, PNG upto 5MB allowed)</small>
                                                        </p>
                                                    </div>
                                                    <input type="file" onChange={(e)=>onChangeFile(e)} name="img_logo" className="dropzone"/>
                                                </div>
                                                <div className="preview-zone hidden">
                                                    <div className="box box-solid">
                                                        <div className="col-sm-2 col-3 p-0 d-flex justify-content-center">
                                                            <div className="style-remove-cricle">
                                                                {
                                                                    <img
                                                                        src={metaPoster !== "" ? metaPoster : sample}
                                                                        alt
                                                                        className="img-fluid"
                                                                    />
                                                                }
                                                                {
                                                                    loadPoster ? <div className="loader-icon"><i
                                                                            className="fa fa-spinner fa-spin"/></div>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-10 col-9 p-0 d-flex ">
                                                            <div className="box-header with-border">
                                                                <div className="box-tools pull-right">
                                                                    <button type="button" className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center">
                                                                        Remove
                                                                        <label className="switch ms-3">
                                                                            <input type="checkbox"
                                                                                   checked={currentLayer.poster === '' ? false : true}
                                                                                   disabled={metaPoster === '' ? true : false}
                                                                                   onChange={()=>handleRemoveLogo()}/>
                                                                            <span className="slider round"/>
                                                                        </label>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="box-body"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="social-share">
                            <h5>Social Share</h5>
                            <div className="row">
                                <div className="col-12 col-md-12 col-xl-6 mb-3">
                                    <div className="social-share-box d-flex align-items-center">
                                        <div className="social-share-box-left d-flex  align-items-center">
                                            <img src={fbIcon} alt="Facebook"/>
                                            <p className="ps-2">Facebook</p>
                                        </div>
                                        <div className="social-share-box-right">
                                            <label className="switch">
                                                <input type="checkbox" onChange={()=>handleSocialShare('facebook')} checked={socialShare.facebook==="block"?true:false} />
                                                <span className="slider round"/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-xl-6 mb-3">
                                    <div className="social-share-box d-flex align-items-center">
                                        <div
                                            className="social-share-box-left d-flex  align-items-center">
                                            <img src={instagramIcon} alt="Reddit"/>
                                            <p className="ps-2">Reddit</p>
                                        </div>
                                        <div className="social-share-box-right">
                                            <label className="switch">
                                                <input type="checkbox" onChange={()=>handleSocialShare('instagram')} checked={socialShare.instagram==="block"?true:false}/>
                                                <span className="slider round"/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-xl-6 mb-3">
                                    <div className="social-share-box d-flex align-items-center">
                                        <div
                                            className="social-share-box-left d-flex  align-items-center">
                                            <img src={twitterIcon} alt="Twitter"/>
                                            <p className="ps-2">Twitter</p>
                                        </div>
                                        <div className="social-share-box-right">
                                            <label className="switch">
                                                <input type="checkbox" onChange={()=>handleSocialShare('twitter')} checked={socialShare.twitter==="block"?true:false} />
                                                <span className="slider round"/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-xl-6 mb-3">
                                    <div className="social-share-box d-flex align-items-center">
                                        <div
                                            className="social-share-box-left d-flex  align-items-center">
                                            <img src={linkedinIcon} alt="linkedin"/>
                                            <p className="ps-2">Linkedin</p>
                                        </div>
                                        <div className="social-share-box-right">
                                            <label className="switch">
                                                <input type="checkbox" onChange={()=>handleSocialShare('linkedIn')} checked={socialShare.linkedIn==="block"?true:false} />
                                                <span className="slider round"/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="analytics-content mt-4">
                            <h5>Analytics</h5>
                            <div className="social-content-box">
                                <form action="" method="POST">
                                    <div className="row">
                                        {
                                            analyticsCount.length > 0 ?
                                                analyticsCount.map((item,index)=>(
                                                    <AnalyticCode key={item.id} index={index} id={item.id} item={item} inputAnalyticsData={inputAnalyticsData} removeMenuButtons={removeMenuButtons} />
                                                ))
                                            : ''
                                        }
                                    </div>
                                </form>
                                <div className="row">
                                    <div className="col-12 d-md-flex d-block justify-content-md-end justify-content-center text-center text-md-right">
                                        <a onClick={()=>addMoreAnalytics()} className="btn btn-link btn-gradient-orange btn-small border-radius-circle" role="button"><span>
                                            <i className="fa fa-plus-circle" aria-hidden="true"/></span> Add More
                                        </a>
                                        <button onClick={()=>saveAnalytics()} className="btn btn-link btn-gradient-purple btn-small border-radius-circle " role="button">
                                            <span><i className="fa fa-film" aria-hidden="true"/></span> Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-menu" role="tabpanel" aria-labelledby="pills-menu-tab">
                        <div className="menu-buttons">
                            <h5>Menu Buttons</h5>
                            <MenuButtons menuButtons={currentLayer.menu_buttons}/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-cta" role="tabpanel" aria-labelledby="pills-cta-tab">
                        <div className="cta-box">
                            <h5>Call To Action</h5>
                            <CallToAction
                                startTime={currentLayer.start_time}
                                endTime={currentLayer.end_time}
                                ctaData={currentLayer.cta_data}
                                ctaType={currentLayer.cta_type}
                            />
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-promo" role="tabpanel" aria-labelledby="pills-promo-tab">
                        <div className="promo-box">
                            <h5>Promo</h5>
                            <Ads/>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-settings" role="tabpanel" aria-labelledby="pills-settings-tab">
                        <div className="settings-box">
                            <h5>Playlist</h5>
                            <Settings />
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-customization" role="tabpanel" aria-labelledby="pills-customization-tab">
                        <div className="customization-box">
                           <Customize/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Media
