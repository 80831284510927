import UploadVideo from "./UploadVideo";
import Navbar from "../Navbar";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchSocialAccounts} from "../../actions/socialAction";

const CustomUpload = () => {
    const brandName = useSelector(state => state.rebrand.data);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(fetchSocialAccounts());
    },[])

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Create Player </title>
            </Helmet>
            <Navbar/>

            <UploadVideo/>
            <Footer/>
        </>
    )
}

export default CustomUpload;
