import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import swal from "sweetalert";
import UploadVideo from "../upload/UploadVideo";

const FetchVideos = ()=>{

    const [video, setVideo] = useState([]);
    const videoData = useSelector(state => state.currentCampaign);

    useEffect(()=>{
        setVideo(videoData)
    });


    return(
        <>
<UploadVideo/>

        </>
    )
}

export default FetchVideos;
