const initialData = {
    data: {
        mid_time: 0,
        duration: 0
    }
}

export default function (state = initialData, action) {
    switch (action.type) {
        case 'GET_MID TIME':
            return {
                ...state,
                mid_time: action.payload
            }
        case 'GET_DURATION':
            return {
                ...state,
                duration: action.payload
            }
        default:
            return state
    }
}
