import Preview from "./preview/Preview";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Countdown from "../timer/Countdown";
import queryString from "query-string";

const EditorHead = ({id}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useSelector(state => state.auth);
    const currentLayer = useSelector(state => state.currentCampaign.data);
    const [saveLoader, setSaveLoader] = useState(false);
    const [publishLoader, setPublishLoader] = useState(false);


    const saveData = (type) => {

        if (type === 'publish') {
            currentLayer.type = 'publish';
            setPublishLoader(true);
        } else {
            currentLayer.type = 'save';
            setSaveLoader(true);
        }

        if(id==='ab'){
            currentLayer.player_type = 'ab';
        }

        axios({
            method: "POST",
            url: `${baseURL}update-campaign`,
            data: currentLayer,
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));

                if (type === 'publish') {
                    history.push('/my-players');
                }
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setSaveLoader(false);
            setPublishLoader(false);
        }).catch(err => {
            dispatch(setAlert('Something went wrong, Please try again', 'success'));
            setSaveLoader(false);
            setPublishLoader(false);
        })
    }

    let d = Date.parse(`${currentLayer.countdown.date} ${currentLayer.countdown.time} ${currentLayer.countdown.timezone.slice(1,9)}`);
    const startDate = new Date(d).getTime();


    return (
        <>
            <Alert/>
            <div className="col-12 col-lg-8 order-md-last p-0">
                <div
                    className="page-tabs editor-page-pills d-flex align-items-center justify-content-center">
                        <div className="editor-page-pills-box align-items-center">
                            <div className="editor-page-pills-box-left">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-home" type="button" role="tab"
                                                aria-controls="pills-home" aria-selected="true">
                                            <i className="fa fa-th-large" aria-hidden="true"/> Thumbnail
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-social-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-social" type="button" role="tab"
                                                aria-controls="pills-social" aria-selected="false">
                                            <span><i className="fa fa-users" aria-hidden="true"/></span> Social
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-menu-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-menu" type="button" role="tab"
                                                aria-controls="pills-menu" aria-selected="false">
                                            <i className="fa fa-list-ul" aria-hidden="true"/> Menu
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-cta-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-cta" type="button" role="tab"
                                                aria-controls="pills-cta" aria-selected="false">
                                            <i className="fa fa-toggle-on" aria-hidden="true"/> CTA
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-promo-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-promo" type="button" role="tab"
                                                aria-controls="pills-promo" aria-selected="false">
                                            <i className="fa fa-bullhorn" aria-hidden="true"/> Promo
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-settings-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-settings" type="button" role="tab"
                                                aria-controls="pills-settings" aria-selected="false">
                                            <i className="fa fa-cog" aria-hidden="true"/> Settings
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-customization-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-customization" type="button" role="tab"
                                                aria-controls="pills-customization" aria-selected="false">
                                            <i className="fa fa-sliders" aria-hidden="true"/> Customization
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="editor-page-pills-box-right">
                                        <div className="editor-page-btn">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-start justify-md-content-end">
                                            <a onClick={()=>saveData('save')} className="btn btn-link btn-gradient-orange btn-xs-small" role="button">
                                                <span>
                                                    {saveLoader ? <i className="fa fa-spinner fa-spin"/> : <i className="fa fa-film" aria-hidden="true"/>}
                                                </span> Save
                                            </a>
                                            <a onClick={()=>saveData('publish')} className="btn btn-link btn-gradient-purple btn-xs-small" role="button">
                                                <span>
                                                    {publishLoader ? <i className="fa fa-spinner fa-spin"/> : <i className="fa fa-film" aria-hidden="true"/> }
                                                </span> Publish
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="video-preview">
                    <div className="video-preview-portrait d-flex align-items-center">
                        <Preview currentLayer={currentLayer} />
                    </div> 
                </div>
                 <Countdown startDate={startDate} skin={currentLayer.countdown}  />
              </div>
        </>
    )
}
export default EditorHead;
