import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import useDrivePicker from "react-google-drive-picker";
import project_1 from "../../images/project1.jpg";
import storyImage from "../../images/Storyreel Logo.jpg"
import videoImage from "../../images/VideoReel Logo.jpg"
import axios from "axios";
import {baseURL} from "../../global/global";
import { Dropbox } from 'dropbox';
import fetch from 'isomorphic-fetch';

const ImportFiles = ({onInputFileUrl}) => {
    const appData = useSelector(state => state.integrate);
    const auth = useSelector(state => state.auth);

    const [modelStatus, setModelStatus] = useState(false);
    const [dropModelStatus, setDropModelStatus] = useState(false);
    const [gData, setGData] = useState([]);
    const [reelappsData, setReelAppsData] = useState([]);
    const [appType, setVideoType] = useState('');
    const [loadGdrive, setLoadGdrive] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [openPicker, data, authResponse] = useDrivePicker();
    const handleOpenPicker = (id) => {
        setLoadGdrive(true);
        axios({
            method: "POST",
            url: `${baseURL}fetch-refresh-token`,
            data: {user_id: auth.user.id,id : id},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                openGooglePicker(res.data.token.access_token)
            }
            setLoadGdrive(false);
        }).catch(err => {
            setLoadGdrive(false);
        })

    }

    const openGooglePicker = (token) => {
        openPicker({
            clientId: "385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com",
            developerKey: "",
            viewId: "DOCS_VIDEOS",
            token: token, // pass oauth token in case you already have one
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: false,
            // customViews: customViewsArray, // custom view
        })
    }

    const handleReelApps = (type,index=0) => {
        setModelStatus(true);
        setVideoType(type);
        setReelAppsData([]);

        if(type==="storyreel"){
            setReelAppsData(JSON.parse(appData.storydata[index].data))
            setSelectedIndex(index);
        }else if(type==="clipsreel"){
            setReelAppsData(JSON.parse(appData.clipsdata[index].data))
            setSelectedIndex(index);
        }else if(type==="videoreel"){
            setReelAppsData(JSON.parse(appData.videodata[index].data))
            setSelectedIndex(index);
        }
    }

    const chooseFile = (url) => {
        onInputFileUrl(url);
        setModelStatus(false);
    }

    const handleDropboxFiles = (path='', token)=>{
        setDropModelStatus(true);
        const accessToken = token;
        console.log(path);

        const dbx = new Dropbox({
            accessToken,
            fetch
        });

        dbx.filesListFolder({
            path: path
        }).then(response => {
            let data = response.result.entries.map((item,index)=> (
               item['.tag'] === "file" ?  `<li class="drop-file">${item['path_display']}</li>` : `<li onclick="handleDropboxFiles(${item['path_display']}, ${accessToken})" class="drop-folder">${item['path_display']}</li>`
            )).join('')

            document.getElementById('dropbox-data').innerHTML = data;

        })
            .catch(err=>console.log(err))
    }

    useEffect(() => {

        if (data) {
            data.docs.map(i => onInputFileUrl(`https://drive.google.com/u/0/uc?id=${i.id}&export=download`));
            setGData(data.docs);
        }

    }, [data, appData]);

    return(
        <>
            <div className="wrap-import">
                {
                    appData.gDrive.length > 0 ?
                        <button onClick={()=>handleOpenPicker(appData.gDrive[0].id)} className="btn btn-bundle primary-gradient">
                            {loadGdrive ? <i className="fa fa-spinner fa-spin mr-2"/> : ''}   Google Drive
                        </button>
                    : <button style={{opacity:.65}} title="Google Drive is not integrated. Please go to integration and connect first" className="btn btn-bundle primary-gradient">Google Drive</button>
                }
                {
                    appData.dropdata.length > 0 ?
                        <button onClick={()=>handleDropboxFiles('', appData.dropdata[0].data)} className="btn btn-bundle primary-gradient">Dropbox</button>
                    : <button disabled className="btn btn-bundle primary-gradient">Dropbox</button>
                }
                {
                    appData.clipsdata.length > 0 ?
                        <button onClick={()=>handleReelApps('clipsreel')} className="btn btn-bundle primary-gradient">ClipsReel</button>
                    : <button style={{opacity:.65}} title="ClipsReel is not integrated. Please go to integration and connect first" className="btn btn-bundle primary-gradient">ClipsReel</button>
                }
                {
                    appData.videodata.length > 0 ?
                        <button onClick={()=>handleReelApps('videoreel')} className="btn btn-bundle primary-gradient">VideoReel</button>
                    : <button style={{opacity:.65}} title="VoiceReel is not integrated. Please go to integration and connect first" className="btn btn-bundle primary-gradient">VideoReel</button>
                }
                {
                    appData.storydata.length > 0 ?
                        <button onClick={()=>handleReelApps('storyreel')} className="btn btn-bundle primary-gradient">StoryReel</button>
                    : <button style={{opacity:.65}} title="StoryReel is not integrated. Please go to integration and connect first" className="btn btn-bundle primary-gradient">StoryReel</button>
                }
            </div>


            <div className={`modal ${dropModelStatus ? 'show-modal' : ''} `}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{color: '#FF8F4B', margin: 'auto'}}>
                        <div className="modal-header">
                            <h4 style={{fontSize: '18px'}} className="modal-title">Dropbox</h4>
                            <button onClick={(e) => setDropModelStatus(false)} type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}}/>
                                </span>
                            </button>
                        </div>
                        <div className="modal-body custom-body">
                            <ul id="dropbox-data">

                            </ul>
                        </div>

                    </div>
                </div>
            </div>


            <div className={`modal ${modelStatus ? 'show-modal' : ''} `}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{color: '#FF8F4B', margin: 'auto'}}>
                        <div className="modal-header">
                            <h4 style={{fontSize: '18px'}} className="modal-title">Choose a Video</h4>
                            <button onClick={(e) => setModelStatus(false)} type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}}/>
                                </span>
                            </button>
                        </div>
                        <div className="modal-body custom-body">
                            <div className="row mb-3">
                                {
                                    appType === "storyreel" ?
                                        appData.storydata.map((item,index)=>(
                                            <div className="col-md-3">
                                                <input checked={selectedIndex===index?true:false} onClick={(e)=>handleReelApps('storyreel',index)} value={index} type="radio" name="account_type" /> {item.email}
                                            </div>
                                        ))
                                    : ''
                                }
                                {
                                    appType === "videoreel" ?
                                        appData.videodata.map((item,index)=>(
                                            <div className="col-md-3">
                                                <input checked={selectedIndex===index?true:false} onClick={(e)=>handleReelApps('videoreel',index)} value={index} type="radio" name="account_type" /> {item.email}
                                            </div>
                                        ))
                                    : ''
                                }
                                {
                                    appType === "clipsreel" ?
                                        appData.clipsdata.map((item,index)=>(
                                            <div className="col-md-3">
                                                <input checked={selectedIndex===index?true:false} onClick={(e)=>handleReelApps('clipsreel',index)} value={index} type="radio" name="account_type" /> {item.email}
                                            </div>
                                        ))
                                    : ''
                                }
                            </div>
                            <div className="row">
                                {
                                    reelappsData.length > 0 ?
                                        reelappsData.map((item,index)=>(
                                            <div className="col-md-3">
                                                <div className="card" style={{padding: "5px"}}>
                                                    <div className="card-box">
                                                        {
                                                            appType === "storyreel" ?
                                                                <img className="card-img-top" src={item.thumbnail} alt="Projects"/>
                                                            : ''
                                                        }
                                                        {
                                                            appType === "videoreel" ?
                                                                <img className="card-img-top" src={item.thumbnail} alt="Projects"/>
                                                                : ''
                                                        }
                                                        {
                                                            appType === "clipsreel" ?
                                                            <img className="card-img-top" src={item.thumbnail!==""?item.thumbnail:project_1} alt="Projects"/>
                                                            : ''
                                                        }
                                                    </div>
                                                    <div className="card-body" style={{display: "flex", backgroundColor: "#ff5050"}}>
                                                        <div className="video-title" style={{backgroundColor: "#ffffff", margin: "0px"}}>
                                                            <p className="card-text">{item?.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group text-center mt-2">
                                                    <button onClick={()=>chooseFile(item.video)}  type="submit" style={{margin: "0px", color: '#fff'}} className="btn btn-link btn-gradient-orange border-radius-circle" role="button">
                                                        Use
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    : ''
                                }
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


export default React.memo(ImportFiles);
