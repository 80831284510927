import React, {useState} from "react";
import swal from "sweetalert";
import axios from "axios";
import {baseURL} from "../../../global/global";
import {setAlert} from "../../../actions/alert";
import {useDispatch, useSelector} from "react-redux";
import sample from "../../../images/sample.png";

const AdsContent = ({index,id,inputAdsData,removeAdsButtons, thumbnail}) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [postType, setPostType] = useState('pre');
    const [status, setStatus] = useState(false);
    const [adsPoster, setAdsPoster] = useState('');
    const [loadAds, setLoadAds] = useState(false);

    const handleInputChange = (e, type) => {
        inputAdsData(e, id, type);

        if(type === 'remove_thumbnail'){
            setAdsPoster('');
        }
        if(type === 'type'){
            setPostType(e);
        }
        if (e === 'info'){
            inputAdsData('text', id, 'file_type');
        }
    }

    const removeIcon = () => {
        removeAdsButtons(index)
        setStatus(true);
    }

    const handleFileUpload = (e) => {

        if(e.target.files[0].type === 'video/mp4') {

            if (e.target.files[0].size < 20000000) {
                setLoadAds(true);

                let formData = new FormData();
                formData.append('file', e.target.files[0]);
                formData.append('file_type', 'video');

                axios({
                    method: 'POST',
                    url: `${baseURL}/upload-media`,
                    data: formData,
                    headers: {'Content-Type': 'application/json','Authorization': auth.token }
                }).then(res=>{
                    if(res.data.status==="success"){
                       setAdsPoster(res.data.data.poster);

                       inputAdsData(res.data.data.poster, id, 'thumbnail');
                       inputAdsData(res.data.data.file_url, id, 'video_url');
                       inputAdsData(res.data.data.file_type, id, 'video');
                    }
                    else{
                        dispatch(setAlert(res.data.message, 'danger'));
                    }
                    setLoadAds(false);

                }).catch(err=>{
                    dispatch(setAlert('Something went wrong, Please try again', 'danger'));
                    setLoadAds(false);
                })
            } else {
                swal("Oops!", "Max allowed size 20MB");
            }

        }else{
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }

    return(
        <>
            <div className={`social-content-box bg-sec-box mt-4 ${status ? 'hide-html' : ''}`}>
                <div className="header-close d-flex justify-content-end">
                    <button onClick={()=>removeIcon()} type="button" className="btn-close">
                        <i className="fa fa-times-circle"
                           aria-hidden="true"/>
                    </button>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="form-grou">
                            <label htmlFor="MenuTitle" className="form-label">Select
                                Promo
                            </label>
                            <select onChange={(e)=>handleInputChange(e.target.value,'type')} className="form-select" aria-label="Default select example">
                                <option selected value="pre">PRE</option>
                                <option value="mid">MID</option>
                                <option value="post">POST</option>
                                <option value="info">INFO</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12">
                        {
                            postType !== 'info' ?
                                <div className="form-group upload-form">
                                    <div className="dropzone-wrapper">
                                        <div className="dropzone-desc">
                                            <p>
                                                <i className="fa fa-cloud-upload" aria-hidden="true"/> Upload a Promo Video <br/>
                                                <small>(JPG, JPEG, PNG upto 5MB allowed)</small>
                                            </p>
                                        </div>
                                        <input onChange={(e)=>handleFileUpload(e)} type="file" name="img_logo" className="dropzone"/>
                                    </div>
                                    <div className="preview-zone hidden">
                                        <div className="box box-solid">
                                            <div className="col-sm-2 col-3 p-0 d-flex justify-content-center">
                                                <div className="style-remove-cricle">
                                                    {
                                                        <img
                                                            src={adsPoster !== "" ? adsPoster : sample}
                                                            alt
                                                            className="img-fluid"
                                                        />
                                                    }
                                                    {
                                                        loadAds ? <div className="loader-icon"><i
                                                                className="fa fa-spinner fa-spin"/></div>
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-10 col-9 p-0 d-flex ">
                                                <div className="box-header with-border">
                                                    <div className="box-tools pull-right">
                                                        <button type="button" className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center">
                                                            Remove
                                                            <label className="switch ms-3">
                                                                <input type="checkbox"
                                                                       disabled={adsPoster === '' ? true : false}
                                                                       onChange={()=>handleInputChange('','remove_thumbnail')}/>
                                                                <span className="slider round"/>
                                                            </label>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-body"/>
                                        </div>
                                    </div>
                                </div>
                            :
                                <>
                                    <div className="form-group">
                                        <label htmlFor="Headline"
                                               className="form-label">Title
                                            {/* <a href="#" data-toggle="tooltip"
                                               title="Hooray!"
                                               className="tooltip-box"><i
                                                className="fa fa-question-circle"
                                                aria-hidden="true"/></a> */}
                                            <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                                <span className="tooltiptext">Enter the title of your info ad</span>
                                            </div>
                                        </label>
                                        <textarea className="form-control" name="text" onChange={(e)=>handleInputChange(e.target.value,'text')} rows="4" placeholder="Your Title"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="MenuTitle"
                                               className="form-label">URL
                                            {/* <a href="#" data-toggle="tooltip"
                                               title="Hooray!"
                                               className="tooltip-box"><i
                                                className="fa fa-question-circle"
                                                aria-hidden="true"/></a> */}
                                            <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                                <span className="tooltiptext">Enter URL for your Info Ads banner</span>
                                            </div>
                                        </label>
                                        <input type="url" name="title" className="form-control" onChange={(e)=>handleInputChange(e.target.value, 'url')} placeholder="Your Url"/>
                                    </div>
                                </>

                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(AdsContent);
