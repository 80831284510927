const initialState = {
    data: {
        id: false,
        userid: false,
        playlist_id: null,
        campaign: false,
        video_type: false,
        url: "",
        mid_time: null,
        thumbnail: "",
        title: "",
        description: "",
        poster: "",
        skin: "#ffffff",
        menu_buttons: false,
        cta_type: "none",
        start_time: 0,
        end_time: 0,
        cta_data: false,
        logo: false,
        social_share: false,
        countdown: {
            time:"",
            date:"",
            timezone:"",
            skin:""
        },
        settings: "",
        resolution: false,
        ads_data: false,
        status: "1",
        password: null,
        is_protected: false,
        is_test: "0",
        privacy: "public",
        embed_type: "normal",
        controls: {
            volume: false,
            resize: false,
            resolution: false,
            play: false,
            speed: false,
            pip: false,
            progressBar:false,
            style: false,
            icon: false,
            text: "",
            color: '#ffffff',
            size: "size"
        },
        loop: false,
        autoplay: true,
        related_videos: false,
        allow_embed: true,
        right_click: true
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'UPLOAD_VIDEO':
            return {
                ...state,
                data: action.payload
            }
        case 'UPLOAD_THUMBNAIL':
            return {
                ...state,
                data: {
                    ...state.data,
                    thumbnail: action.payload
                }
            }

        case 'MID_TIME' :
            return{
                ...state,
                data: {
                    ...state.data,
                    mid_time: action.payload
                }
            }

        case 'UPLOAD_IMAGE':
            return {
                ...state,
                data: {
                    ...state.data,
                    thumbnail: action.payload
                }
            }

        case 'REMOVE_THUMBNAIL':
            return {
                ...state,
                data: {
                    ...state.data,
                    thumbnail: ""
                }
            }

        case 'UPDATE_META_TITLE':
            return {
                ...state,
                data: {
                    ...state.data,
                    title: action.payload
                }
            }

        case 'UPDATE_META_DESC':
            return {
                ...state,
                data: {
                    ...state.data,
                    description: action.payload
                }
            }

        case 'UPDATE_META_POSTER':
            return {
                ...state,
                data: {
                    ...state.data,
                    poster: action.payload
                }
            }

        case 'REMOVE_META_POSTER':
            return {
                ...state,
                data: {
                    ...state.data,
                    poster: ""
                }
            }

        case 'UPDATE_SOCIAL_SHARE':
            return {
                ...state,
                data: {
                    ...state.data,
                    social_share: action.payload
                }
            }

        case 'UPDATE_SETTING_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    settings: action.payload
                }
            }

        case 'ADD_MENU_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    menu_buttons: action.payload
                }
            }

        case 'ADD_CTA_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    cta_data: action.payload
                }
            }

        case 'ADD_ADS_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    ads_data: action.payload
                }
            }

        case 'UPDATE_PRIVACY':
            return {
                ...state,
                data: {
                    ...state.data,
                    privacy: action.payload
                }
            }

        case 'UPDATE_EMBED_TYPE':
            return {
                ...state,
                data: {
                    ...state.data,
                    embed_type: action.payload
                }
            }

        case 'UPDATE_PLAYLIST':
            return {
                ...state,
                data: {
                    ...state.data,
                    playlist_id: action.payload
                }
            }

        case 'UPDATE_CTA_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    cta_type: action.payload.cta_type,
                    end_time: action.payload.end_time,
                    start_time: action.payload.start_time
                }
            }

        case 'UPDATE_SKIN_COLOR':
            return {
                ...state,
                data: {
                    ...state.data,
                    skin: action.payload
                }
            }

        case 'UPDATE_CONTROL_STYLE':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        style: action.payload
                    }
                }
            }

        case 'UPDATE_CONTROL_SETTING':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        resolution: action.payload
                    }
                }
            }


        case 'UPDATE_CONTROL_PLAY':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        play: action.payload
                    }
                }
            }

        case 'UPDATE_CONTROL_VOLUME':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        volume: action.payload
                    }
                }
            }

        case 'UPDATE_CONTROL_RESIZE':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        resize: action.payload
                    }
                }
            }
            case 'UPDATE_CONTROL_PROGRESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        progressBar: action.payload
                    }
                }
            }


        case 'UPDATE_CONTROL_SPEED':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        speed: action.payload
                    }
                }
            }
            case 'UPDATE_CONTROL_PIP':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        pip: action.payload
                    }
                }
            }


        case 'UPDATE_CONTROL_ICON':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        icon: action.payload
                    }
                }
            }

        case 'UPDATE_BUTTON_TEXT':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        text: action.payload
                    }
                }
            }

        case 'UPDATE_BUTTON_COLOR':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        color: action.payload
                    }
                }
            }

        case 'UPDATE_BUTTON_SIZE':
            return {
                ...state,
                data: {
                    ...state.data,
                    controls: {
                        ...state.data.controls,
                        size: action.payload
                    }
                }
            }

        case 'UPDATE_COUNTDOWN_TIME':
            return {
                ...state,
                data: {
                    ...state.data,
                    countdown: {
                        ...state.data.countdown,
                        time: action.payload
                    }
                }
            }

        case 'UPDATE_COUNTDOWN_DATE':
            return {
                ...state,
                data: {
                    ...state.data,
                    countdown: {
                        ...state.data.countdown,
                        date: action.payload
                    }
                }
            }

        case 'UPDATE_COUNTDOWN_TIMEZONE':
            return {
                ...state,
                data: {
                    ...state.data,
                    countdown: {
                        ...state.data.countdown,
                        timezone: action.payload
                    }
                }
            }

        case 'UPDATE_COUNTDOWN_SKIN':
            return {
                ...state,
                data: {
                    ...state.data,
                    countdown: {
                        ...state.data.countdown,
                        skin: action.payload
                    }
                }
            }

        case 'REMOVE_COUNTDOWN':
            return {
                ...state,
                data: {
                    ...state.data,
                    countdown: {
                        time:"",
                        date:"",
                        timezone:"",
                        skin:""
                    }
                }
            }

        case 'UPDATE_AUTOPLAY_STATUS':
            return {
                ...state,
                data: {
                    ...state.data,
                    autoplay: action.payload
                }
            }

        case 'UPDATE_CLICK_STATUS':
            return {
                ...state,
                data: {
                    ...state.data,
                    right_click: action.payload
                }
            }

        case 'UPDATE_EMBED_STATUS':
            return {
                ...state,
                data: {
                    ...state.data,
                    allow_embed: action.payload
                }
            }


        case 'UPDATE_LOOP_STATUS':
            return {
                ...state,
                data: {
                    ...state.data,
                    loop: action.payload
                }
            }

        case 'UPDATE_LOGO_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    logo: action.payload
                }
            }

        case 'REMOVE_LOGO_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    logo: ''
                }
            }

        case 'UPDATE_RELATED_VIDEOS':
            return {
                ...state,
                data: {
                    ...state.data,
                    related_videos: action.payload
                }
            }

        case 'CURRENT_CAMP':
            return {
                ...state,
                data: action.payload
            }


        default:
            return state;
    }
}
