import React, {useEffect, useState, useMemo} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {baseURL} from "../../../global/global";
import {setAlert} from "../../../actions/alert";
import Alert from "../../Alert";
import {updateCountDown, updateSettings} from "../../../actions/currentCampaignAction";
import {MultiSelect} from "react-multi-select-component";
import countryList from "react-select-country-list";

const Settings = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const campaign = useSelector(state => state.currentCampaign.data);
    const camp_id = campaign.id;
    const privacy = campaign.privacy;
    const embed_type = campaign.embed_type;
    const vid_type = campaign.video_type;

    const skin = campaign.skin;
    const autoPlay = campaign.autoplay;
    const loop = campaign.loop;
    const rightClick = campaign.right_click;


    const [loopStatus, setLoopStatus] = useState(false);

    const [loader, setLoader] = useState(false);
    const [passLoader, setPassLoader] = useState(false);
    const [blockLoader, setBlockLoader] = useState(false);

    const options = useMemo(() => countryList().getData(), []);
    const [selected, setSelected] = useState([]);

    const [modelStatus, setModelStatus] = useState(false);
    const [privacyType, setPrivacyType] = useState(privacy);
    const [playlistName, setPlaylistName] = useState('');
    const [playLists, setPlayLists] = useState([]);
    const [embedType, setEmbedTpe] = useState(embed_type);
    const [password, setPassword] = useState('');

    const handlePrivacy = (e) => {
        setPrivacyType(e.target.value);
        dispatch(updateSettings('UPDATE_PRIVACY', e.target.value));
    }

    const handleEmbedType = (e) => {
        setEmbedTpe(e.target.value);
        dispatch(updateSettings('UPDATE_EMBED_TYPE', e.target.value));
    }

    const handlePlaylist = (e) => {
        dispatch(updateSettings('UPDATE_PLAYLIST', e.target.value));
    }

    const handleRegion = (region) => {
        setSelected(region);
        console.log(region);
    }

    const handleBlock = () => {
        setBlockLoader(true);
        axios({
            method: "POST",
            url: `${baseURL}geo-location`,
            data: {locations: selected, player_id: camp_id},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            console.log(res);
            setBlockLoader(false);
        }).catch(err => {
            setBlockLoader(false);
        })
    }

    const addPassword = () => {
        setPassLoader(true);
        axios({
            method: "POST",
            url: `${baseURL}create-campaign-password`,
            data: {id: camp_id, password: password},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'))
                dispatch(updateSettings('UPDATE_PRIVACY', 'password'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'))
            }
            setPassLoader(false);
        }).catch(err => {
            setPassLoader(false);
            dispatch(setAlert('Something went wrong, please try again', 'danger'))
        })
    }

    const fetchPlaylist = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-playlist`,
            data: {},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                setPlayLists(res.data.data);
            }
        }).catch(err => {

        })
    }

    const customStyle = {
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? "yellow" : "red"
        })
    }
    const handleCreatePlaylist = (e) => {
        e.preventDefault();
        setLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}create-playlist`,
            data: {name: playlistName},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, "success"));
                fetchPlaylist();
            } else {
                dispatch(setAlert(res.data.message, "danger"));
            }
            setModelStatus(false);
            setLoader(false);
        }).catch(err => {
            setModelStatus(false);
            setLoader(false);
        })
    }

    const handleRelatedVideos = () => {
        if (campaign.related_videos === false) {
            dispatch(updateSettings('UPDATE_RELATED_VIDEOS', true));
        } else {
            dispatch(updateSettings('UPDATE_RELATED_VIDEOS', false));
        }
    }

    const timeSelectStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '2px dotted green',
            color: state.isSelected ? 'yellow' : 'black',
            backgroundColor: state.isSelected ? 'green' : 'white',
            backgroundColor: state.isSelected.checkbox ? 'green' : 'white',
            backgroundColor: state.isOption ? 'green' : 'white',
        }),
        control: (provided) => ({
            ...provided,
            marginTop: "5%",
        })
    }

    const handleLoop = () => {
        if (loopStatus === false) {
            setLoopStatus(true);

            dispatch(updateCountDown('UPDATE_LOOP_STATUS', true));
        } else {
            setLoopStatus(false);

            dispatch(updateCountDown('UPDATE_LOOP_STATUS', false));
        }
    }

    const handleAutoPlay = () => {
        if (autoPlay === false) {
            dispatch(updateCountDown('UPDATE_AUTOPLAY_STATUS', true));
        } else {
            dispatch(updateCountDown('UPDATE_AUTOPLAY_STATUS', false));
        }
    }


    const handleRight = () => {
        if (rightClick === false) {
            dispatch(updateCountDown('UPDATE_CLICK_STATUS', true));
        } else {
            dispatch(updateCountDown('UPDATE_CLICK_STATUS', false));
        }
    }


    useEffect(() => {
        fetchPlaylist();
    }, [])

    return (
        <>
            <Alert/>
            <div className="ads-content-box bg-sec-box mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <select onChange={(e) => handlePlaylist(e)} className="form-select"
                                    aria-label="Default select example">
                                <option selected>Select a Playlist</option>
                                {
                                    playLists.length > 0 ?
                                        playLists.map((item, index) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))
                                        : ''
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-md-end justify-content-center">
                    <a style={{margin: '0px'}} onClick={() => setModelStatus(true)}
                       className="btn btn-link btn-gradient-orange border-radius-circle" role="button">
                        <span><i className="fa fa-pencil-alt" aria-hidden="true"/></span> Create New Playlist
                    </a>
                </div>
            </div>

            <h5>Visibility & Privacy</h5>
            <div className="ads-content-box bg-sec-box mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <select onChange={(e) => handlePrivacy(e)} className="form-select"
                                    aria-label="Default select example">
                                <option selected={privacyType === "public" ? true : false} value="public">Public
                                </option>
                                <option selected={privacyType === "private" ? true : false} value="private">Private
                                </option>
                                <option selected={privacyType === "password" ? true : false} value="password">Choose
                                    Password Protected
                                </option>
                            </select>
                        </div>

                        {
                            privacyType === 'password' ?
                                <>
                                    <div className="form-group">
                                        <label htmlFor="MenuTitle" className="form-label">Password
                                            <div className="my_tooltip"><i className="fa fa-question-circle"
                                                                           aria-hidden="true"/>
                                                <span className="tooltiptext">Hooray!</span>
                                            </div>
                                        </label>
                                        <input onChange={(e) => setPassword(e.target.value)} type="password"
                                               className="form-control" placeholder="Create password for protect"/>
                                    </div>
                                    <div className="form-group text-right">
                                        <button onClick={() => addPassword()} disabled={password !== "" ? false : true}
                                                style={{margin: "0px", color: "#ffffff"}}
                                                className="btn btn-link btn-gradient-orange border-radius-circle"
                                                role="button">
                                            {passLoader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Apply
                                        </button>
                                    </div>
                                </>
                                : ''
                        }
                    </div>
                </div>
            </div>
            <h5>Related Videos</h5>
            <div className="ads-content-box bg-sec-box mb-4 related-videos">
                <div className="row d-flex align-items-center">
                    <div className="col-9 col-sm-8">
                        <div className="form-group">
                            <label htmlFor="RelatedVideos" className="form-label">
                                Show Related Videos
                            </label>
                        </div>
                    </div>
                    <div className="col-3 col-sm-4">
                        <div className="box-tools pull-right">
                            {vid_type === "youtube" || vid_type === "vimeo" ? <button disabled={true} type="button"
                                                                                      className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">
                                <label className="switch ms-3">
                                    <input type="checkbox"
                                           checked={false}/>
                                    <span className="slider round"/>
                                </label>
                            </button> : <button type="button"
                                                className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">
                                <label className="switch ms-3">
                                    <input type="checkbox" onChange={() => handleRelatedVideos()}
                                           checked={campaign.related_videos}/>
                                    <span className="slider round"/>
                                </label>
                            </button>}
                        </div>
                    </div>

                    {vid_type === "youtube" || vid_type === "vimeo" ?
                        <div className="col-12">
                            <div className="form-group mt-3">
                                <div className="alert alert-info" role="alert">
                                    <p className="tos">Sorry, As per {vid_type === "youtube" ?
                                        <a target="_blank"
                                           href="https://developers.google.com/youtube/iframe_api_reference#Playback_controls">YouTube
                                            TOS</a> :
                                        <a target="_blank" href="https://developer.vimeo.com/player/sdk/embed">Vimeo
                                            TOS</a>}, you
                                        can't show your own related videos. Choose another video source to use this
                                        feature.
                                    </p>

                                </div>
                            </div>
                        </div> : ""}


                </div>
            </div>

            <h5>Embed Type</h5>
            <div className="ads-content-box bg-sec-box mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <select onChange={(e) => handleEmbedType(e)} className="form-select"
                                    aria-label="Default select example">
                                <option selected={embedType === "normal" ? true : false} value="normal">Normal</option>
                                <option selected={embedType === "popup" ? true : false} value="popup">Popup</option>
                                <option selected={embedType === "email" ? true : false} value="email">Email</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <h5>Block Geo Location</h5>
            <div className="ads-content-box bg-sec-box mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group mb-3">
                            <MultiSelect
                                styles={customStyle}
                                options={options}
                                value={selected}
                                // onChange={(e)=>handleRegion(e)}
                                onChange={setSelected}
                                labelledBy="Select"
                                multiple="multiple"
                                className="geo-location-select"
                            />
                        </div>
                        <div className="text-right">
                            <button onClick={() => handleBlock()} style={{margin: "0px", color: "#ffffff"}}
                                    className="btn btn-link btn-gradient-orange border-radius-circle" role="button">
                                {blockLoader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Block
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <h5>Loop</h5>
            <div className="ads-content-box bg-sec-box mb-4 remove-box">
                <div className="row d-flex align-items-center">
                    <div className="col-8 col-sm-8">
                        <div className="form-group">
                            <label htmlFor="Loop" className="form-label">Enable Video Looping
                                <div className="my_tooltip"><i className="fa fa-question-circle" aria-hidden="true"/>
                                    <span className="tooltiptext">Play in loop when video ends</span>
                                </div></label>
                        </div>
                    </div>
                    <div className="col-4 col-sm-4">
                        <div className="box-tools pull-right">
                            <button type="button"
                                    className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">
                                <label className="switch ms-3">
                                    <input type="checkbox" onChange={() => handleLoop()}
                                           checked={loop === true ? true : false}/>
                                    <span className="slider round"/>
                                </label>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <h5>Autoplay</h5>
            <div className="ads-content-box bg-sec-box mb-4 remove-box">
                <div className="row d-flex align-items-center">
                    <div className="col-8 col-sm-8">
                        <div className="form-group">
                            <label htmlFor="RelatedVideos" className="form-label">Autoplay The Video
                                <div className="my_tooltip"><i className="fa fa-question-circle" aria-hidden="true"/>
                                    <span className="tooltiptext">Set your player on Autoplay</span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col-4 col-sm-4">
                        <div className="box-tools pull-right">
                            <button type="button"
                                    className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">
                                <label className="switch ms-3">
                                    <input type="checkbox" onChange={() => handleAutoPlay()}
                                           checked={autoPlay}/>
                                    <span className="slider round"/>
                                </label>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <h5>Disable Right Click</h5>
            <div className="ads-content-box bg-sec-box  remove-box">
                <div className="row d-flex align-items-center">
                    <div className="col-9 col-sm-8">
                        <div className="form-group">
                            <label htmlFor="RelatedVideos" className="form-label">Disable Right Click On This Player
                                <div className="my_tooltip drc"><i className="fa fa-question-circle" aria-hidden="true"/>
                                    <span className="tooltiptext">When set on, viewer can't right click on player</span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col-3 col-sm-4">
                        <div className="box-tools pull-right">
                            {vid_type === "youtube" || vid_type === "vimeo" ?
                                <button style={{cursor: "notAllowed"}} disabled={true} type="button"
                                        className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">
                                    <label className="switch ms-3">
                                        <input type="checkbox"
                                               checked={false}/>
                                        <span className="slider round"/>
                                    </label>
                                </button> : <button type="button"
                                                    className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">
                                    <label className="switch ms-3">
                                        <input type="checkbox" onChange={() => handleRight()}
                                               checked={rightClick === true ? true : false}/>
                                        <span className="slider round"/>
                                    </label>
                                </button>}
                        </div>
                    </div>

                    {vid_type === "youtube" || vid_type === "vimeo" ? <div className="form-group mt-3">
                        <div className="col-12 col-sm-12">
                            <div className="alert alert-info" role="alert">
                                <p className="tos">Sorry, As per {vid_type === "youtube" ?
                                    <a target="_blank"
                                       href="https://developers.google.com/youtube/iframe_api_reference#Playback_controls">YouTube
                                        TOS</a> :
                                    <a target="_blank" href="https://developer.vimeo.com/player/sdk/embed">Vimeo
                                        TOS</a>},
                                    you can't prevent right-click. Choose another
                                    video source to use this feature.</p></div>

                        </div>
                    </div> : ""}

                </div>
            </div>


            <div className={`modal ${modelStatus ? 'show-modal' : ''} `}>
                <div className="modal-dialog create-playlist-modal" role="document" style={{top: "20%"}}>
                    <div className="modal-content" style={{width: "400px", color: '#FF8F4B'}}>
                        <div className="modal-header">
                            <h4 style={{fontSize: '18px'}} className="modal-title">Create Playlist</h4>
                            <button onClick={(e) => setModelStatus(false)} type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}}/>
                                </span>
                            </button>
                        </div>
                        <form method="post" onSubmit={(e) => handleCreatePlaylist(e)} className="modal-body">
                            <div className="form-group">
                                <label htmlFor="MenuTitle" className="form-label">Name</label>
                                <input type="text" required name="title" className="form-control"
                                       onChange={(e) => setPlaylistName(e.target.value)}
                                       placeholder="Enter playlist name"/>
                            </div>
                            <div className="form-group text-right">
                                <button type="submit" style={{margin: "0px", color: '#fff'}}
                                        className="btn btn-link btn-gradient-orange border-radius-circle" role="button">
                                    {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Create
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(Settings);
