/*
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const HelpVideos = (props) => {

    const supportData = useSelector(state => state.support);

    const [videosData, setVideosDara] = useState(supportData.videos);
    const [url, setUrl] = useState('');
    const [showModal, setShowModal] = useState(false);

    const playVideo = (url) => {
        setUrl(url);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrl('');
        setShowModal(false);
    }

    useEffect(()=>{
        setVideosDara(supportData.videos)
    },[supportData.videos])

    return (
        <>
            <section className="popular-see">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="popular-video-sec">
                                <div className="row">
                                    <div className="col-md-12 text-center text-white">
                                        <h3>Popular Video Tutorials</h3>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    {
                                        videosData.length > 0 ?
                                            videosData.map((video, index) => {
                                                return (
                                                    <div className="col-lg-3 col-md-6 mb-3" key={index}
                                                         style={{cursor: "pointer"}}
                                                         onClick={(e) => playVideo(video.url)}>
                                                        <div className="player-box">
                                                            <img src={video?.thumbnail} alt="player"
                                                                 className="img-fluid"/>
                                                            <div
                                                                className="player-title mt-2 d-flex text-white d-flex justify-content-between ">
                                                                <span><i className="fas fa-play-circle mr-2"></i>{video.title}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : ''
                                    }
                                    {
                                        props.loadVideos ?
                                            <div className="col-12">
                                                <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2"/></h4>
                                            </div>
                                            : ''
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className={`modal ${showModal ? 'show-modal' : ''}`} id="myModal" aria-labelledby="myModalLabel" aria-hidden="true" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"></h5>
                            <button onClick={(e) => closeModal()} type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}}></i>
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe id="frameSrc" className="embed-responsive-item" src={url} allowFullScreen=""></iframe>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpVideos;
*/


import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
 import TrainingTiles from "./TrainingTiles";
import queryString from "query-string";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import TrainingBreadcrumbs from "./TrainingBreadcrumbs";
import TrainingTitles from "./TrainingTiles";

const TrainingArticles = ({location}) => {

    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;

    const articleData = useSelector(state => state.support.articles);
    const brandName = useSelector(state => state.rebrand.data);

    const filterArticle = articleData.filter(article => article.id === id)[0];

    const filterName = filterArticle.items.filter((id) => id.id == art_id)[0];


    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Help & Support</title>
            </Helmet>
            <Navbar/>

            <section className="traning-sec">
                <div className="container">
                    <div className="row">
                        <TrainingBreadcrumbs location={location} name={filterName} />
                    </div>
                    <div className="row">
                        <TrainingTitles location={location} name={filterName}/>

                        <div className="col-md-8" style={{margin: "0px 0px 60px 0px"}}>
                            <div className="popular-topics-content">
                                <div className="upgrades-sec-content-box" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                                        {
                                            filterArticle.items.length > 0 ?
                                                filterArticle.items.map((item,index)=>{
                                                    return(
                                                        item.id == art_id ?
                                                            <>
                                                                <div key={item.id} className="text-white"
                                                                     dangerouslySetInnerHTML={{
                                                                         __html: item.content
                                                                     }}>
                                                                </div>
                                                            </>
                                                            : ''
                                                    )
                                                })
                                                : ''
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default TrainingArticles;
