import React, {useState} from "react";

const ButtonDisplay = ({content, skin}) => {
    const [menuDisplay, setMenuDisplay] = useState("none");



    const showMenu = () => {
        setMenuDisplay("block")
    }

    const closeMenu = () => {
        setMenuDisplay("none")
    }


    return (
        <div className="videojs-row-box">
            <div className="videojs-box">
                <div className="videojs-menu mb-2 mr-2">
                    <a href="#"  className="videojs-menu-btn " onClick={() => showMenu()} style={{backgroundColor: skin}}>
                        <p className="vidMenuBTN">{content.title}</p>
                    </a>
                </div>
            </div>
            <div id="vidMenuContent2" className="vidMenuContent" style={{display: menuDisplay}}>
                <a id="2" className="vidMenuClose close-menu close" href="#" onClick={() => closeMenu()}>×</a>
                <h3 className="menuHeadline mb-3 text-center">{content.heading}</h3>
                <div className="menuDescription mb-3">
                    <p>
                        {content.text}
                    </p>
                </div>
                <a className="vid-btn-cta" style={{backgroundColor: skin}} href={`${content.button_url}`} target="_blank">{content.button_text}</a>
            </div>
        </div>
    )
}

export default ButtonDisplay;
