import Preview from "./preview/Preview";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {midAdTime} from "../../actions/embedAction";
import axios from "axios";
import {baseURL} from "../../global/global";
import {Helmet} from "react-helmet";
import 'react-sticky-header/styles.css';
import "./embed.css";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";
import Countdown from "../timer/Countdown";
import 'react-sticky-video/dist/index.css';
import queryString from "query-string";

const Embed = (props) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const currentLayer = useSelector(state => state.currentCampaign.data)

    const split_id = queryString.parse(props.location.search).split_id;

    const [vidData, setVidData] = useState(false);
    const [fetchStatus, setFetchStatus] = useState(false);
    const [blockVid, setBlockVid] = useState("");
    const [loadEdit, setLoadEdit] = useState(false);
    const [privacy, setPrivacy] = useState("public");
    const [authStatus, setAuthStatus] = useState(false);
    const campaignData = useSelector(state => state.currentCampaign.data.embed_type);
    const [playId, setPlayId] = useState(null);
    const [showAuthModal, setShowAuthModal] = useState(true);
    const [user, setUser] = useState('');
    const [loader, setLoader] = useState(false);
    const [countryIP, setCountryIP] = useState('');
    const [countryName, setCountryName] = useState('');
    const [vidDuration, setVidDuration] = useState('');
    const [vidURL, setVidURL] = useState('');


    const handleInputChange = (e) => {
        setUser(e.target.value);
    }

    const authUser = (e) => {
        e.preventDefault();
        setLoader(true);
        axios({
            method: "POST",
            url: `${baseURL}validate-campaign-password`,
            data: {id: playId, password: user},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                setAuthStatus(res.data.status);
                setPrivacy("public");

                setFetchStatus(true);

            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
            setShowAuthModal(true);
        }).catch(err => {
            setLoader(false);
            setShowAuthModal(true);
        })
    }


    const getEmbedData = async () => {
        setLoadEdit(true);
        let region;
        let countryIp;
        await axios({
            method: 'POST',
            url: `https://ipapi.co/json`,
            data: {}
        }).then(res => {
            region = res.data.country_name;
            setCountryName(region);
            countryIp = res.data.ip;
            setCountryIP(countryIp)

        }).catch(err => {

        })

        axios({
            method: 'POST',
            url: `${baseURL}fetch-campaign`,
            data: {id: props.match.params.id, region: region, split_id: split_id && split_id},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {

                setVidData(res.data.data);
                setBlockVid(res.data.status);
                setPlayId(res.data.data.id);
                setPrivacy(res.data.data.privacy);
                setVidURL(res.data.data.url)

                if (res.data.data.privacy !== "password" && res.data.data.privacy !== "private") {
                    setFetchStatus(true);
                }

                getAnalytic(res.data.data.id, countryIp, region);
                getLinkClick(res.data.data.id);
                liveVisitor(countryIp, region, res.data.data.id);
                // watchDurationVideo(res.data.data.id, countryIp, region)
            }
            setLoadEdit(false);
            setBlockVid(res.data.message);

        }).catch(err => {
            console.log(err);
            setLoadEdit(false);
        })
    }

    const getAnalytic = (id, ip, region) => {
        axios({
            method: "POST",
            url: `${baseURL}analytic`,
            data: {
                id: id,
                ip: ip,
                region: region,
                split_id: split_id && split_id
            },
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                console.log(res.data.status)
            }
        })
    }


    const watchDurationVideo = (id, ip, region) => {
        axios({
            method: "POST",
            url: `${baseURL}view-watch-video`,
            data: {
                ip: ip,
                region: region,
                id: id,
                watch_time: "50"
            },
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                console.log(res.data.status)
            }
        })
    }


    const liveVisitor = (ip, countryName, id) => {
        axios({
            method: "POST",
            url: `${baseURL}view-video`,
            data: {
                ip: ip,
                region: countryName,
                id: id,
                split_id: split_id && split_id
            },
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {

            }
        })

    }

    const getLinkClick = (id) => {
        axios({
            method: "POST",
            url: `${baseURL}engagement`,
            data: {
                id: id,
                clicks: 1,
                split_id: split_id && split_id
            },
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if (res.data.status === true) {
            }
        })

    }


    /* useEffect(() => {
         const introContainer = document.querySelector('.intro');
         const videoContainer = introContainer.querySelector('.popout-video');
         const video = videoContainer.querySelector('video');
         let videoHeight = videoContainer.offsetHeight;


         let popOut = true;

         introContainer.style.height = `${videoHeight}px`;

         window.addEventListener('scroll', function () {
             if (window.scrollY > videoHeight) {
                 console.log(videoHeight)
                 // only pop out the video if it wasnt closed before
                 if (popOut) {
                     videoContainer.classList.add('popout-video--popout');
                     // set video container off the screen for the slide in animation
                     videoContainer.style.top = `-${videoHeight}px`;
                     console.log("video scroll")
                 }
             } else {
                 videoContainer.classList.remove('popout-video--popout');
                 videoContainer.style.top = `0px`;
                 popOut = true;
             }
         });


         window.addEventListener('resize', function () {
             videoHeight = videoContainer.offsetHeight;
             introContainer.style.height = `${videoHeight}px`;
         });

     }, []);
 */

    let d = Date.parse(`${currentLayer.countdown.date} ${currentLayer.countdown.time} ${currentLayer.countdown.timezone}`);
    const startDate = new Date(d).getTime();


    useEffect(() => {
        getEmbedData();

    }, []);


    useEffect(() => {
        if (fetchStatus === true) {
            const playTime = document.querySelector('.video-js .vjs-big-play-button');
            playTime.addEventListener('click', function () {
                axios({
                    method: "POST",
                    url: `${baseURL}view-video`,
                    data: {
                        id: playId,
                        ip: countryIP,
                        region: countryName,
                        split_id: split_id && split_id
                    },
                    headers: {'Content-Type': 'application/json', 'Authorization': auth.token}

                }).then(res => {

                })
            })

        }



    }, [fetchStatus]);

  /*  useEffect(() => {
        let vidDuration = document.querySelector('video');

        // let vidPaused = document.querySelector('.video-js');

        if (vidDuration !== null) {
            vidDuration.addEventListener('timeupdate', function () {
                let duration = vidDuration.currentTime;

                setVidDuration(duration);
            })
        }
    },[]);*/


    useEffect(()=>{

        // window.addEventListener('beforeunload', function (e) {
        //     e.preventDefault();
        //     e.returnValue = '';
        // });
    })

    useEffect(() => {
        liveVisitor();
        return () => {

            watchDurationVideo(playId, countryIP, countryName);
        };
    }, []);



    return (
        <>
            <Helmet>
                <title>{vidData !== false ? vidData.title : 'PlayerReel'}</title>
                <meta name="title" content={vidData !== false ? vidData.title : 'PlayerReel'}/>
                <meta name="description" content={vidData !== false ? vidData.description : 'PlayerReel'}/>


                <meta property="og:type" content="website"/>
                <meta property="og:url" content={vidURL}/>
                <meta property="og:title" content={vidData !== false ? vidData.title : 'PlayerReel'}/>
                <meta property="og:description"
                      content={vidData !== false ? vidData.description : 'PlayerReel'}/>
                <meta property="og:image"
                      content={vidData !== false ? vidData.thumbnail : ''}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={vidURL}/>
                <meta property="twitter:title"
                      content={vidData !== false ? vidData.title : 'PlayerReel'}/>
                <meta property="twitter:description"
                      content={vidData !== false ? vidData.description : 'PlayerReel'}/>
                <meta property="twitter:image"
                      content={vidData !== false ? vidData.thumbnail : ''}/>

            </Helmet>

            <Alert/>
            <div id="embed">
                {privacy === "password" ?
                    <div className={`modal ${showAuthModal ? 'show-modal' : ''} `} style={{zIndex: '1'}}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" style={{margin: "auto", marginRight: "0px"}}>
                                        {/* <img src={props.icon} width="56px" alt={props.name}
                                         className="mr-2"/> {`Connect ${props.name}`}*/}
                                        This video is password protected. Please enter password to watch!
                                    </h5>
                                    <button onClick={(e) => setShowAuthModal(false)} type="button" className="close"
                                            data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{color: "#ffffff"}}/></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form className="add-account-box mt-2" method="post" onSubmit={(e) => authUser(e)}>
                                        <div className="row">

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <small className="form-text text-muted">Enter video password</small>
                                                    <input type="password" className="form-control int_auth"
                                                           placeholder="********" required name="password"
                                                           onChange={(e) => handleInputChange(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="d-flex justify-content-center  mt-4">
                                                <button type="submit" className="gradiant-button-pb connect-btn"
                                                        disabled={loader}>
                                                    {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Submit &
                                                    Watch
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    : privacy === "private" ? <div className="block_loc">
                            <p>Video Is Private</p>
                        </div> :
                        <div className="intro">
                            <div className="popout-video">
                                {campaignData === "popup" ? <div className="vid_container" data-effect="mfp-zoom-in">
                                    {vidData !== false ? <Preview currentLayer={vidData} setVidDuration={setVidDuration}/> :
                                        loadEdit ?
                                            <div className="loader-sec">
                                                <div className="loader">
                                                </div>
                                            </div>
                                            : <div>{blockVid}</div>
                                    }
                                </div> : <div>
                                    {vidData !== false ? (
                                            <>

                                                <Preview currentLayer={vidData} setVidDuration={setVidDuration}/>
                                                <Countdown startDate={startDate} skin={currentLayer.countdown}/>
                                            </>) :
                                        loadEdit ?
                                            <div className="loader-sec">
                                                <div className="loader">
                                                </div>
                                            </div>
                                            : <div className="block_loc">
                                                <p> {blockVid}</p>
                                            </div>
                                    }
                                </div>
                                }
                            </div>
                        </div>

                }
            </div>
        </>
    )
}

export default Embed;
