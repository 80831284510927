import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import {useDispatch, useSelector} from "react-redux";
import Footer from "../Footer";
import randomstring from "randomstring";
import AbTestContent from "./AbTestContent";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";
import queryString from "query-string";
import {useHistory} from "react-router-dom";


const AbTestProject = (props) => {

    const initialData  = {
        id: randomstring.generate({length: 9, charset : 'numeric'}),
        player_id: ''
    }

    const id = queryString.parse(props.location.search).id;
    const auth = useSelector(state => state.auth);
    const brandName = useSelector(state => state.rebrand.data);
    const dispatch = useDispatch();
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [projects, setProjects] = useState([]);
    const [campName, setCampName] = useState('');

    const [playerCount, setPlayerCount] = useState([
        initialData
    ])

    const addMoreAds = () => {
        let obj = {
            id: randomstring.generate({length: 9, charset : 'numeric'}),
            player_id: ''
        }
        let arr = [...playerCount];
        arr.push(obj)

        setPlayerCount(arr);
    }

    const removeAdsButtons = (index) => {
        let arr = [...playerCount];
        arr.splice(index,1);

        setPlayerCount(arr);
    }

    const handleInputData = (value, index) => {

        let newTrans = [...playerCount];
        newTrans[index].player_id = value;

        setPlayerCount(newTrans);
    }

    const fetchProjects = () => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-campaigns`,
            data: {},
            headers: {'Content-Type': 'application/json','Authorization': auth.token }
        }).then(res=>{
            if(res.data.status===true){
                setProjects(res.data.data);
            }
        }).catch(err=>{

        })
    }

    const handleCreate = () => {
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}add-ab-testing`,
            data: {name: campName, players: playerCount, id : id && id},
            headers: {'Content-Type': 'application/json','Authorization': auth.token }
        }).then(res=>{
            if(res.data.status===true){
                dispatch(setAlert(res.data.message,'success'));
                history.push(`/ab-testing`);
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }

            setLoader(false);
        }).catch(err=>{
            setLoader(false);
        })
    }

    useEffect(()=>{
        fetchProjects();

        if(id !== undefined && id !== ''){

            axios({
                method: 'POST',
                url: `${baseURL}edit-ad-testing`,
                data: {id},
                headers: {'Content-Type': 'application/json','Authorization': auth.token }
            }).then(res=>{
                if(res.data.status === true){
                    setPlayerCount(res.data.data[0].players);
                    setCampName(res.data.data[0].name)
                }
            }).catch(err=>{

            })

        }

    },[])

    return(
        <>
            <Alert/>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Create Player </title>
            </Helmet>
            <Navbar/>

            <section className="create-player">
                <div className="container">
                    <h3>Create A/B Test</h3>

                    <div className="test-container">
                        <div className="input-cover">
                            <input type="text" className="form-control" placeholder="Enter A/B Campaign Name"
                                   onChange={(e)=>setCampName(e.target.value)}
                                   value={campName}
                            />
                        </div>

                        {
                            playerCount.length > 0 ?
                                playerCount.map((item, index)=>(
                                    <AbTestContent
                                        key={item.id}
                                        index={index}
                                        id={item.id}
                                        player={item.player_id}
                                        removeAdsButtons={removeAdsButtons}
                                        handleInputData={handleInputData}
                                        projects={projects}
                                    />
                                ))
                                : ''
                        }

                        <div className="input-cover">
                            <button className="btn btn-gradient-orange" onClick={()=>handleCreate()}>
                                {loader ? <i className="fa fa-spinner fa-spin mr-1"/> : ''} Create
                            </button>
                            <button className="btn btn-gradient-purple" onClick={()=>addMoreAds()}>Add More</button>
                        </div>

                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}


export default React.memo(AbTestProject);