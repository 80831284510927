import React, {useEffect, useRef, useState} from "react";
import {SketchPicker} from "react-color";

import template_1 from "../../../images/cdownSkinDefault.png"
import template_2 from "../../../images/cdownCustSkin1.png";
import template_3 from "../../../images/cdownCustSkin2.png";
import template_4 from "../../../images/cdownCustSkin3.png";
import template_5 from "../../../images/cdownCustSkin4.png";
import template_6 from "../../../images/cdownCustSkin5.png";
import template_7 from "../../../images/cdownCustSkin6.png";
import template_8 from "../../../images/cdownCustSkin7.png";
import template_9 from "../../../images/cdownCustSkin8.png";
import template_10 from "../../../images/cdownCustSkin10.png"
import progressBar from "../../../images/controls1.png"
import volume_ctr from "../../../images/controls2.png";
import play_ctr from "../../../images/controls3.png"
import resize_ctr from "../../../images/controls4.png";
import pip_ctr from "../../../images/PIP.png";
import resolution from "../../../images/Resolution.png"
import plyBack from "../../../images/Play_speed.png";
import Play_icon_1 from "../../../images/play1.svg";
import Play_icon_2 from "../../../images/play2.svg";
import Play_icon_3 from "../../../images/play3.svg";
import Play_icon_4 from "../../../images/play4.svg"
import Play_icon_5 from "../../../images/play-button.svg"

import axios from "axios";
import {baseURL} from "../../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {
    addSkinColor, removeLogoData, updateButtonText,
    updateControlIcon, updateControlPIP, updateControlPlay, updateControlProgress, updateControlResize,
    updateControlSetting, updateControlSpeed,
    updateControlStyle, updateControlVolume, updateCountDown, updateLogoData, uploadThumbnail
} from "../../../actions/currentCampaignAction";
import TimezoneSelect from "react-timezone-select";
import sample from "../../../images/sample.png";
import swal from "sweetalert";
import {setAlert} from "../../../actions/alert";

const Customize = () => {
    const ref = useRef("chgLogo");


    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const currentData = useSelector(state => state.currentCampaign.data);
    const controls = currentData.controls;
    const skin = currentData.skin;
    const autoPlay = currentData.autoplay;
    const loop = currentData.loop;
    const allowEmbed = currentData.allow_embed;
    const rightClick = currentData.right_click;
    const logoStatus = currentData.logo;
    const vid_type = currentData.video_type;


    const [playButtons, setPlayButtons] = useState([]);
    const [controlButtons, setControlButtons] = useState([]);
    const [loadLogo, setLoadLogo] = useState(false);

    const [animateCss, setAnimateCss] = useState('animate__bounce');
    const [skinColor, setSkinColor] = useState(skin);
    const [bgStatus, setBgStatus] = useState(false);
    const [playIcon, setPlayIcon] = useState(false);
    const [countDownStatus, setCountDownStatus] = useState(false);
    const [countCheckStatus, setCountCheckStatus] = useState(false);
    const [loopStatus, setLoopStatus] = useState(false);
    const [autoPlayStatus, setAutoPlayStatus] = useState(false);
    const [showAlert, setShowAlert] = useState('none');
    const [buttonType, setButtonType] = useState('')

    const [button, setButton] = useState({
        text: '',
        color: '#ffffff',
        size: '10'
    });
    const [buttonColorStatus, setButtonColorStatus] = useState(false);

    const [selectedTimezone, setSelectedTimezone] = useState({
        value: null,
        label: null
    });

    const [countdown, setCountdown] = useState({
        time: '',
        date: '',
        timezone: ''
    })
    const [logo, setLogo] = useState({
        file: '',
        position: 'top-left'
    })

    const onInputBgColor = (e) => {
        setSkinColor(e);

        dispatch(addSkinColor(e));
    }

    const fetchPlayIcons = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-play-con`,
            data: {},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                setPlayButtons(res.data.data);
            }
        }).catch(err => {

        })
    }


    // const timeSelectStyles = {
    //     menu: (provided, state) => ({
    //       ...provided,
    //       width: state.selectProps.width,
    //       borderBottom: '1px dotted pink',
    //       color: state.isSelected ? 'white' : 'orange',
    //       padding: 20,
    //     }),

    //     control: (_, { selectProps: { width }}) => ({
    //       width: width
    //     }),

    //     singleValue: (provided, state) => {
    //       const opacity = state.isDisabled ? 0.5 : 1;
    //       const transition = 'opacity 300ms';

    //       return { ...provided, opacity, transition };
    //     }
    //   }

    //   const timeSelectStyles = {
    //     option: (base, state) => ({
    //         ...base,
    //         backgroundColor: state.isSelected ? "red" : "#303030",
    //         backgroundColor: state.isFocused ? "red" : "#303030",
    //         backgroundColor: state.data ? "red" : "#303030",
    //         backgroundColor: state.data ? "red" : "#303030",
    //         color:state.isSelected ? "white" : "white",
    //       })
    //     }

    ////

    const timeSelectStyles = {
        "&:hover": {
            background: "#ea4c89"
        },
        control: (base, state) => ({
            ...base,
            background: "#303030",
            // match with the menu
            borderRadius: state.isFocused ? "10px" : 10,
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#707070" : "#707070",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "#707070Make all dropdown selection css/color uniform across the tabs & across the app. Make it as shown in the screenshot" : "#707070"
            }
        }),
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            // const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isFocused ? "#1e90ff" : '#000',
                "&:hover": {
                    // Overwrittes the different states of border
                    backgroundColor: "#1e90ff"
                },
                color: "#fff"
            };
        }

    };

    const fileUpload = (file, type) => {

        if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {

            if (file.size < 5000000) {
                if (type === "logo") {
                    setLoadLogo(true);
                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('file_type', type);

                    axios({
                        method: 'POST',
                        url: `${baseURL}/upload-media`,
                        data: formData,
                        headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
                    }).then(res => {
                        if (res.data.status === "success") {
                            setLogo({...logo, file: res.data.data.file_url})
                            dispatch(updateLogoData(logo.position, res.data.data.file_url))
                        } else {
                            dispatch(setAlert(res.data.message, 'danger'))
                        }
                        setLoadLogo(false);
                    }).catch(err => {
                        setLoadLogo(false);
                    })
                }

            } else {
                swal("Oops!", "Max allowed size 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }

    }

    const changePosition = (value) => {
        setLogo({...logo, position: value});
        dispatch(updateLogoData(value, logo.file))
    }

    const onLogoUpload = (e) => {
        console.log(e.target.value)
        fileUpload(e.target.files[0], 'logo');
        setLogo({...logo, position: "top-left"});

        document.getElementById('chgLogo').value = '';
    }

    const removeLogo = () => {
        dispatch(removeLogoData());
        setLogo({...logo, position: "top-left"});
    }


    const handleAnimate = (e) => {
        setAnimateCss(e.target.value);

        dispatch(updateControlStyle(e.target.value));
    }

    const handlePlayIcon = (url, type) => {
        setPlayIcon(url);
        dispatch(updateControlIcon(url));
        setButtonType(type)
    }


    const handleControls = (type, value) => {
        if (type === 'progressBar') {
            if (controls.progressBar === false) {
                dispatch(updateControlProgress(value));
            } else {
                dispatch(updateControlProgress(false));
            }
        } else if (type === 'play') {
            if (controls.play === false) {
                dispatch(updateControlPlay(value));
            } else {
                dispatch(updateControlPlay(false));
            }
        } else if (type === 'volume') {
            if (controls.volume === false) {
                dispatch(updateControlVolume(value));
            } else {
                dispatch(updateControlVolume(false));
            }
        } else if (type === 'resize') {
            if (controls.resize === false) {
                dispatch(updateControlResize(value));
            } else {
                dispatch(updateControlResize(false));
            }
        } else if (type === 'speed') {
            if (controls.speed === false) {
                dispatch(updateControlSpeed(value));
            } else {
                dispatch(updateControlSpeed(false));
            }
        } else if (type === 'pip') {
            if (controls.pip === false) {
                dispatch(updateControlPIP(value));
            } else {
                dispatch(updateControlPIP(false));
            }
        } else if (type === 'setting') {
            if (controls.setting === false) {
                dispatch(updateControlSetting(value));
            } else {
                dispatch(updateControlSetting(false));
            }
        }
    }

    const handleButton = (e, type) => {
        setButton({...button, [type]: e});

        dispatch(updateButtonText(`UPDATE_BUTTON_${type.toUpperCase()}`, e));
    }

    const handleCustomData = (e, type, val) => {
        setCountDownStatus(true);
        setCountCheckStatus(false);

        setCountdown({...countdown, [type]: e});

        if (type === 'timezone') {
            setSelectedTimezone({label: e, value: val})
        }

        dispatch(updateCountDown(`UPDATE_COUNTDOWN_${type.toUpperCase()}`, e));
    }

    const handleLoop = () => {
        if (loopStatus === false) {
            setLoopStatus(true);

            dispatch(updateCountDown('UPDATE_LOOP_STATUS', true));
        } else {
            setLoopStatus(false);

            dispatch(updateCountDown('UPDATE_LOOP_STATUS', false));
        }
    }

    const handleAutoPlay = () => {
        if (autoPlay === false) {
            dispatch(updateCountDown('UPDATE_AUTOPLAY_STATUS', true));
        } else {
            dispatch(updateCountDown('UPDATE_AUTOPLAY_STATUS', false));
        }
    }

    const handleEmbed = () => {
        if (allowEmbed === false) {
            dispatch(updateCountDown('UPDATE_EMBED_STATUS', true));
        } else {
            dispatch(updateCountDown('UPDATE_EMBED_STATUS', false));
        }
    }

    const handleRight = () => {
        if (rightClick === false) {
            dispatch(updateCountDown('UPDATE_CLICK_STATUS', true));
        } else {
            dispatch(updateCountDown('UPDATE_CLICK_STATUS', false));
        }
    }


    const removeCountDown = () => {
        console.log(countDownStatus);
       /* if (countDownStatus === false) {
            console.log(countDownStatus, "inside if")
            setCountDownStatus(true);
            setCountCheckStatus(false)
            dispatch(updateCountDown('REMOVE_COUNTDOWN', ''))
        } else {
            setCountCheckStatus(true);
            setCountDownStatus(false);
            console.log(countDownStatus, "Inside Else")
        }*/
        if(countDownStatus===true){
            setCountDownStatus(false);
            setCountCheckStatus(true);
            dispatch(updateCountDown('REMOVE_COUNTDOWN', ''));
        }else{
            setCountDownStatus(true);
            setCountCheckStatus(false);
        }
    }


    const handleAlert = () => {
        if (showAlert === "none") {
            setShowAlert("block")
        } else {
            setShowAlert("none")
        }

    }

    useEffect(() => {
        fetchPlayIcons();
    }, [])

    return (
        <>
            <h5>Customization</h5>
            <div className="customization-content-box bg-sec-box mb-4 customization-color-picker">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="PlayButtonAnimation"
                                   className="form-label d-flex justify-content-between mb-2">
                                Skin Color
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-md-12 ">
                        <div className="color-picker-box align-items-center d-flex left-picker ">
                            {
                                bgStatus ?
                                    <>
                                        <div className="swatch-cover" onClick={() => setBgStatus(false)}/>
                                        <SketchPicker disableAlpha={true} color={skinColor}
                                                      onChange={(e) => onInputBgColor(e.hex, 'bg_color')}/>
                                    </>
                                    : ''
                            }
                            <div style={{backgroundColor: `${skinColor}`}} onClick={() => setBgStatus(true)}
                                 className="color-picker white cursor-pointer"></div>
                            <p className="color-text ms-2 text-white">{skinColor}</p>
                        </div>
                        <p className="small">HEX</p>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="row">
                            <div className="col-xl-4 col-12 px-xl-1 px-none">
                                <input type="text" className="form-control" id="exampleInputText" name="255" placeholder="255"/>
                                <p className="small">R</p>
                            </div>
                            <div className="col-xl-4 col-12 px-xl-1 px-none">
                                <input type="text" className="form-control" id="exampleInputText" name="255" placeholder="255"/>
                                <p className="small">G</p>
                            </div>
                            <div className="col-xl-4 col-12 px-xl-1 px-none">
                                <input type="text" className="form-control" id="exampleInputText" name="255" placeholder="255"/>
                                <p className="small">B</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="color-picker-box-colors">
                    <ul>
                        <li onClick={() => onInputBgColor('#FDFDFD')}>
                            <div className="color-pick" style={{backgroundColor: "#FDFDFD"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#E1E1E1')}>
                            <div className="color-pick" style={{backgroundColor: "#E1E1E1"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#E1E1E1')}>
                            <div className="color-pick" style={{backgroundColor: "#E1E1E1"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#CCCCCC')}>
                            <div className="color-pick" style={{backgroundColor: "#CCCCCC"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#C2C2C2')}>
                            <div className="color-pick" style={{backgroundColor: "#C2C2C2"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#B7B7B7')}>
                            <div className="color-pick" style={{backgroundColor: "#B7B7B7"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#A1A1A1')}>
                            <div className="color-pick" style={{backgroundColor: "#A1A1A1"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#959595')}>
                            <div className="color-pick" style={{backgroundColor: "#959595"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#E8C9FF')}>
                            <div className="color-pick" style={{backgroundColor: "#E8C9FF"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#C87EFF')}>
                            <div className="color-pick" style={{backgroundColor: "#C87EFF"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#F34AFF')}>
                            <div className="color-pick" style={{backgroundColor: "#F34AFF"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#D19DF8')}>
                            <div className="color-pick" style={{backgroundColor: "#D19DF8"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#9B33E9')}>
                            <div className="color-pick" style={{backgroundColor: "#9B33E9"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#8133DB')}>
                            <div className="color-pick" style={{backgroundColor: "#8133DB"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#7033D5')}>
                            <div className="color-pick" style={{backgroundColor: "#7033D5"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#23803F')}>
                            <div className="color-pick" style={{backgroundColor: "#23803F"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#54C423')}>
                            <div className="color-pick" style={{backgroundColor: "#54C423"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#606888')}>
                            <div className="color-pick" style={{backgroundColor: "#606888"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#7C83AC')}>
                            <div className="color-pick" style={{backgroundColor: "#7C83AC"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#5B69BE')}>
                            <div className="color-pick" style={{backgroundColor: "#5B69BE"}}/>
                        </li>
                        <li onClick={() => onInputBgColor('#30409B')}>
                            <div className="color-pick" style={{backgroundColor: "#30409B"}}/>
                        </li>
                    </ul>
                </div>
            </div>
            <h5>Video Controls</h5>
            <div className="customization-content-box bg-sec-box mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="PlayButtonAnimation" className="form-label d-flex mb-2">
                                Play Button Animation
                                <div className="my_tooltip"><i className="fa fa-question-circle" aria-hidden="true"/>
                                    <span className="tooltiptext">Choose an animation style for play/pause button</span>
                                </div>
                            </label>
                            <select onChange={(e) => handleAnimate(e)} className="form-select"
                                    aria-label="Default select example">
                                <option selected={controls.style === "animate__bounce" ? true : false}
                                        value="animate__bounce">Bounce
                                </option>
                                <option selected={controls.style === "animate__flash" ? true : false}
                                        value="animate__flash">Flash
                                </option>
                                <option selected={controls.style === "animate__pulse" ? true : false}
                                        value="animate__pulse">Pulse
                                </option>
                                <option selected={controls.style === "animate__pulse" ? true : false}
                                        value="animate__rubberBand">RubberBand
                                </option>
                                <option selected={controls.style === "animate__shakeX" ? true : false}
                                        value="animate__shakeX">ShakeX
                                </option>
                                <option selected={controls.style === "animate__shakeY" ? true : false}
                                        value="animate__shakeY">ShakeY
                                </option>
                                <option selected={controls.style === "animate__headShake" ? true : false}
                                        value="animate__headShake">HeadShake
                                </option>
                                <option selected={controls.style === "animate__swing" ? true : false}
                                        value="animate__swing">Swing
                                </option>
                                <option selected={controls.style === "animate__tada" ? true : false}
                                        value="animate__tada">Tada
                                </option>
                                <option selected={controls.style === "animate__wobble" ? true : false}
                                        value="animate__wobble">Wobble
                                </option>
                                <option selected={controls.style === "animate__jello" ? true : false}
                                        value="animate__jello">Jello
                                </option>
                                <option selected={controls.style === "animate__heartBeat" ? true : false}
                                        value="animate__heartBeat">HeartBeat
                                </option>
                                <option selected={controls.style === "animate__heartBeat" ? true : false}
                                        value="animate__slideInDown">SlideInDown
                                </option>
                                <option selected={controls.style === "animate__heartBeat" ? true : false}
                                        value="animate__flip">Flip
                                </option>
                                <option selected={controls.style === "animate__heartBeat" ? true : false}
                                        value="animate__rotateIn">RotateIn
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="customization-content-box bg-sec-box mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <p className="d-flex mb-2">
                                Play Button Style
                                <div className="my_tooltip"><i className="fa fa-question-circle" aria-hidden="true"/>
                                    <span className="tooltiptext">Select a style for play/pause button</span>
                                </div>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <ul className="play-button-list bg-sec-list">
                            {/* <img src={ply}/>*/}

                            {/*{
                                playButtons.length > 0 ?
                                    playButtons.map((item, index) => (
                                        <li onClick={() => handlePlayIcon(item.image)} key={item.id}>
                                            <a>
                                                <img src={item.image} className="cursor-pointer"
                                                     alt="Play Button Style Icon"/>
                                            </a>
                                        </li>
                                    ))
                                    : ''
                            }*/}
                            <li onClick={() => handlePlayIcon(Play_icon_1, "play1")}>
                                <a>
                                    <img src={Play_icon_1} className="cursor-pointer"
                                         alt="Play Button Style Icon"/>
                                </a>
                            </li>
                            <li onClick={() => handlePlayIcon(Play_icon_2, "play2")}>
                                <a>
                                    <img src={Play_icon_2} className="cursor-pointer"
                                         alt="Play Button Style Icon"/>
                                </a>
                            </li>
                            <li onClick={() => handlePlayIcon(Play_icon_3, "play3")}>
                                <a>
                                    <img src={Play_icon_3} className="cursor-pointer"
                                         alt="Play Button Style Icon"/>
                                </a>
                            </li>
                            <li onClick={() => handlePlayIcon(Play_icon_4, "play4")}>
                                <a>
                                    <img src={Play_icon_4} className="cursor-pointer"
                                         alt="Play Button Style Icon"/>
                                </a>
                            </li>
                            <li onClick={() => handlePlayIcon(Play_icon_5, "play5")}>
                                <a>
                                    <img src={Play_icon_5} className="cursor-pointer"
                                         alt="Play Button Style Icon"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="customization-content-box bg-sec-box mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <p className="d-flex mb-2">
                                Controls
                                <div className="my_tooltip"><i className="fa fa-question-circle" aria-hidden="true"/>
                                    <span className="tooltiptext">Choose a control that you want to be in player</span>
                                </div>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <ul className="controls-button-list bg-sec-list" id="custom_size">
                            <li onClick={() => handleControls('progressBar', true)}>
                                <a>
                                    <img src={progressBar}
                                         className={`cursor-pointer ${controls.progressBar === false ? 'active-control' : ''}`}
                                         alt="Play Button Style Icon"/>
                                </a>
                            </li>
                            <li onClick={() => handleControls('volume', true)}>
                                <a>
                                    <img src={volume_ctr}
                                         className={`cursor-pointer ${controls.volume === false ? 'active-control' : ''}`}
                                         alt="Play Button Style Icon"/>
                                </a>
                            </li>
                            <li onClick={() => handleControls('play', true)}>
                                <a>
                                    <img src={play_ctr}
                                         className={`cursor-pointer ${controls.play === false ? 'active-control' : ''}`}
                                         alt="Play Button Style Icon"/>
                                </a>
                            </li>
                            <li onClick={() => handleControls('resize', true)}>
                                <a>
                                    <img src={resize_ctr}
                                         className={`cursor-pointer ${controls.resize === false ? 'active-control' : ''}`}
                                         alt="Play Button Style Icon"/>
                                </a>
                            </li>
                            <li onClick={() => handleControls('speed', true)}>
                                <a>
                                    <img src={plyBack}
                                         className={`cursor-pointer ${controls.speed === false ? 'active-control' : ''}`}
                                         alt="Play Button Style Icon"/>
                                </a>
                            </li>
                            {
                                vid_type === "youtube" || vid_type === "vimeo" ? <li onClick={() => handleAlert()}>
                                    <a>
                                        <img src={pip_ctr}
                                             className={`cursor-pointer ${showAlert === "block" ? 'active-control' : ''}`}
                                             alt="Play Button Style Icon"/>
                                    </a>
                                </li> : <li onClick={() => handleControls('pip', true)}>
                                    <a>
                                        <img src={pip_ctr}
                                             className={`cursor-pointer ${controls.pip === false ? 'active-control' : ''}`}
                                             alt="Play Button Style Icon"/>
                                    </a>
                                </li>
                            }

                            {/*<li onClick={() => handleControls('resolution', true)}>
                                <a>
                                    <img src={resolution}
                                         className={`cursor-pointer ${controls.timer === false ? 'active-control' : ''}`}
                                         alt="Play Button Style Icon"/>
                                </a>
                            </li>*/}
                        </ul>
                        {
                            vid_type === "youtube" || vid_type === "vimeo" ?
                                <div className="alert alert-info" style={{display: showAlert}} role="alert">
                                    <p className="tos">
                                        Sorry, As per {vid_type === "youtube" ?
                                        <a href="https://developers.google.com/youtube/iframe_api_reference#Playback_controls"
                                           target="_blank">YouTube
                                            TOS</a> :
                                        <a href="https://developer.vimeo.com/player/sdk/embed" target="_blank">Vimeo
                                            TOS</a>}, you can't
                                        use PIP button. Choose another video source to use this feature.
                                    </p>
                                </div> : ""}

                    </div>
                </div>
            </div>
            <div className="customization-content-box bg-sec-box mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="YourUrl" className="form-label d-flex mb-2">Play
                                Button Title
                                <div className="my_tooltip"><i className="fa fa-question-circle" aria-hidden="true"/>
                                    <span className="tooltiptext">Choose a control that you want to be in player</span>
                                </div>
                            </label>
                            <input value={controls.text} name="text"
                                   onChange={(e) => handleButton(e.target.value, 'text')} type="text"
                                   className="form-control" id="YourUrl" placeholder="Your Title"/>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <p>Text Color</p>
                        <div className="color-picker-box align-items-center d-flex bor-radius-10">
                            {
                                buttonColorStatus ?
                                    <>
                                        <div className="swatch-cover" onClick={() => setButtonColorStatus(false)}/>
                                        <SketchPicker disableAlpha={true} color={controls.color}
                                                      onChange={(e) => handleButton(e.hex, 'color')}/>
                                    </>
                                    : ''
                            }
                            <div style={{backgroundColor: `${controls.color}`}}
                                 onClick={() => setButtonColorStatus(true)} className="color-picker white"/>
                            <p className="color-text ms-2 text-white">{controls.color}</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="form-group">
                            <label htmlFor="YourUrl" className="form-label d-flex mb-2">
                                Text Size
                            </label>
                            <input value={controls.size} name='size'
                                   onChange={(e) => handleButton(e.target.value, 'size')} type="number" min="10"
                                   className="form-control" id="YourUrl" placeholder="Your text size"/>
                        </div>
                    </div>
                </div>
            </div>
            <h5 style={{marginBottom: "10px", justifyContent: "space-between"}} className="d-flex align-items-center">
                Count Down Timer
                <label className="switch ms-3">
                    <input type="checkbox" onChange={() => removeCountDown()}
                           disabled={countDownStatus === true ? false : true} checked={countCheckStatus}/>
                    <span className="slider round"/>
                </label>
            </h5>
            <div className="countdown-sec bg-sec-box mb-3">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="form-group">
                            <input value={countdown.date}
                                   onChange={(e) => handleCustomData(e.target.value, 'date', false)} type="date"
                                   className="form-control"/>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="form-group">
                            <input value={countdown.time}
                                   onChange={(e) => handleCustomData(e.target.value, 'time', false)} type="time"
                                   className="form-control "/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="select-wrapper">
                            <TimezoneSelect
                                styles={timeSelectStyles}
                                value={selectedTimezone}
                                onChange={(e) => handleCustomData(e.label, 'timezone', e.value)
                                }
                                className="timeSelect"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="select-template-box bg-sec-box mb-4 upload-form">
                <p>Select Template</p>
                <div className="row mt-2">
                    <div className="col-12 col-md-6">
                        <a onClick={(e) => handleCustomData('skin-default-1', 'skin', false)}
                           className="d-block select-template-img h-100">
                            <img src={template_1} className="img-thumbnail cursor-pointer" alt="image-placeholder"/>
                        </a>
                    </div>
                    <div className="col-12 col-md-6">
                        <a onClick={(e) => handleCustomData('skin-default-2', 'skin', false)}
                           className="d-block select-template-img">
                            <img src={template_2} className="img-thumbnail cursor-pointer" alt="image-placeholder"/>
                        </a>
                    </div>
                    <div className="col-12 col-md-6">
                        <a onClick={(e) => handleCustomData('skin-default-3', 'skin', false)}
                           className="d-block  select-template-img h-100">
                            <img src={template_3} className="img-thumbnail cursor-pointer" alt="image-placeholder"/>
                        </a>
                    </div>
                    <div className="col-12 col-md-6">
                        <a onClick={(e) => handleCustomData('skin-default-4', 'skin', false)}
                           className="d-block  select-template-img h-100">
                            <img src={template_4} className="img-thumbnail cursor-pointer" alt="image-placeholder"/>
                        </a>
                    </div>
                    <div className="col-12 col-md-6">
                        <a onClick={(e) => handleCustomData('skin-default-5', 'skin', false)}
                           className="d-block  select-template-img h-100">
                            <img src={template_5} className="img-thumbnail cursor-pointer" alt="image-placeholder"/>
                        </a>
                    </div>
                    <div className="col-12 col-md-6">
                        <a onClick={(e) => handleCustomData('skin-default-6', 'skin', false)}
                           className="d-block  select-template-img h-100">
                            <img src={template_6} className="img-thumbnail cursor-pointer" alt="image-placeholder"/>
                        </a>
                    </div>
                    <div className="col-12 col-md-6">
                        <a onClick={(e) => handleCustomData('skin-default-7', 'skin', false)}
                           className="d-block  select-template-img h-100">
                            <img src={template_7} className="img-thumbnail cursor-pointer" alt="image-placeholder"/>
                        </a>
                    </div>
                    <div className="col-12 col-md-6">
                        <a onClick={(e) => handleCustomData('skin-default-8', 'skin', false)}
                           className="d-block select-template-img h-100">
                            <img src={template_8} className="img-thumbnail cursor-pointer" alt="image-placeholder"/>
                        </a>
                    </div>
                    <div className="col-12 col-md-6">
                        <a onClick={(e) => handleCustomData('skin-default-9', 'skin', false)}
                           className="d-block  select-template-img h-100">
                            <img src={template_9} className="img-thumbnail cursor-pointer" alt="image-placeholder"/>
                        </a>
                    </div>
                    <div className="col-12 col-md-6">
                        <a onClick={(e) => handleCustomData('skin-default-10', 'skin', false)}
                           className="d-block  select-template-img h-100">
                            <img src={template_10} className="img-thumbnail cursor-pointer" alt="image-placeholder"/>
                        </a>
                    </div>
                </div>
                <div className="box-tools d-flex justify-content-end">
                    {/*<button type="button" className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">*/}
                    {/*    Remove*/}
                    {/*    <label className="switch ms-3">*/}
                    {/*        <input type="checkbox" onChange={()=>removeCountDown()} disabled={countDownStatus===true?false:true} checked={countCheckStatus} />*/}
                    {/*        <span className="slider round"/>*/}
                    {/*    </label>*/}
                    {/*</button>*/}
                </div>

            </div>

            <h5>Branding</h5>
            <div className="ads-content-box bg-sec-box  remove-box">
                <div className="row d-flex align-items-center">

                    <div className="col-md-12">
                        <div className="form-group" style={{paddingBottom: "0px"}}>
                            <div className="dropzone-wrapper">
                                <div className="dropzone-desc">
                                    <p>
                                        <i className="fa fa-cloud-upload" aria-hidden="true"/> Upload a Logo <br/>
                                        <small>(JPG, JPEG, PNG upto 5MB allowed)</small>
                                    </p>
                                </div>
                                <input type="file" name="img_logo" onChange={(e) => onLogoUpload(e)}
                                       className="dropzone" id="chgLogo"/>
                            </div>
                            <div className="preview-zone hidden">
                                <div className="box box-solid d-flex align-items-center">
                                    <div className="col-sm-2 col-3 p-0 d-flex justify-content-center">
                                        <div className="style-remove-cricle">
                                            {
                                                <img
                                                    src={logoStatus !== "" && logoStatus !== false ? logoStatus.file : sample}
                                                    alt
                                                    className="img-fluid"
                                                />
                                            }
                                            {
                                                loadLogo ? <div className="loader-icon"><i
                                                        className="fa fa-spinner fa-spin"/></div>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="col-sm-10 col-9 p-0 d-flex ">
                                        <div className="box-header with-border">
                                            <div className="box-tools pull-right">
                                                <button type="button"
                                                        className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center text-white p-0">
                                                    Remove
                                                    <label className="switch ms-3">
                                                        <input onClick={() => removeLogo()} type="checkbox"
                                                               disabled={logoStatus !== "" && logoStatus !== false ? false : true}/>
                                                        <span className="slider round"/>
                                                    </label>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-body"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <select disabled={logoStatus === "" ? true : false}
                                    onChange={(e) => changePosition(e.target.value)} className="form-select"
                                    aria-label="Default select example">
                                <option selected={logo.position === "top-left" ? true : false} value="top-left">Top
                                    Left
                                </option>
                                <option selected={logo.position === "top-center" ? true : false} value="top-center">Top
                                    Center
                                </option>
                                <option selected={logo.position === "top-right" ? true : false} value="top-right">Top
                                    Right
                                </option>
                                <option selected={logo.position === "center-left" ? true : false}
                                        value="center-left">Center Left
                                </option>
                                <option selected={logo.position === "center" ? true : false} value="center">Center
                                </option>
                                <option selected={logo.position === "center-right" ? true : false}
                                        value="center-right">Center Right
                                </option>
                                <option selected={logo.position === "bottom-left" ? true : false}
                                        value="bottom-left">Bottom Left
                                </option>
                                <option selected={logo.position === "bottom-center" ? true : false}
                                        value="bottom-center">Bottom Center
                                </option>
                                <option selected={logo.position === "bottom-right" ? true : false}
                                        value="bottom-right">Bottom Right
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {/*<h5>Loop</h5>
            <div className="ads-content-box bg-sec-box mb-4 remove-box">
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-sm-8">
                        <div className="form-group">
                            <label htmlFor="Loop" className="form-label">Enable Video Looping
                                <div className="my_tooltip"><i className="fa fa-question-circle" aria-hidden="true"/>
                                    <span className="tooltiptext">Play in loop when video ends</span>
                                </div></label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="box-tools pull-right">
                            <button type="button"
                                    className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">
                                <label className="switch ms-3">
                                    <input type="checkbox" onChange={() => handleLoop()}
                                           checked={loop === true ? true : false}/>
                                    <span className="slider round"/>
                                </label>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <h5>Autoplay</h5>
            <div className="ads-content-box bg-sec-box mb-4 remove-box">
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-sm-8">
                        <div className="form-group">
                            <label htmlFor="RelatedVideos" className="form-label">Autoplay The Video
                                <div className="my_tooltip"><i className="fa fa-question-circle" aria-hidden="true"/>
                                    <span className="tooltiptext">Set your player on Autoplay</span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="box-tools pull-right">
                            <button type="button"
                                    className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">
                                <label className="switch ms-3">
                                    <input type="checkbox" onChange={() => handleAutoPlay()}
                                           checked={autoPlay}/>
                                    <span className="slider round"/>
                                </label>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
                 <div className="ads-content-box bg-sec-box mb-4 remove-box">
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-sm-8">
                        <div className="form-group">
                            <label htmlFor="RelatedVideos" className="form-label">Allowed URL to Embed
                                <div className="my_tooltip"><i className="fa fa-question-circle" aria-hidden="true"/>
                                    <span className="tooltiptext">This player can only be enbeded on URL you allow to</span>
                                </div></label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="box-tools pull-right">
                            <button type="button"
                                    className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">
                                <label className="switch ms-3">
                                    <input type="checkbox" onChange={() => handleEmbed()}
                                           checked={allowEmbed === true ? true : false}/>
                                    <span className="slider round"/>
                                </label>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <h5>Disable Right Click</h5>
            <div className="ads-content-box bg-sec-box mb-4 remove-box">
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-sm-8">
                        <div className="form-group">
                            <label htmlFor="RelatedVideos" className="form-label">Disable Right Click On This Player
                                <div className="my_tooltip"><i className="fa fa-question-circle" aria-hidden="true"/>
                                    <span className="tooltiptext">When set on, viewer can't right click on player</span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="box-tools pull-right">
                            {vid_type === "youtube" || vid_type === "vimeo" ? <button style={{cursor: "notAllowed"}} disabled={true} type="button" className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">
                                <label className="switch ms-3">
                                    <input type="checkbox"
                                           checked={false}/>
                                    <span className="slider round"/>
                                </label>
                            </button> : <button type="button"
                                                className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center p-0">
                                <label className="switch ms-3">
                                    <input type="checkbox" onChange={() => handleRight()}
                                           checked={rightClick === true ? true : false}/>
                                    <span className="slider round"/>
                                </label>
                            </button>}
                        </div>
                    </div>
                    <div className="col-12 col-sm-12">
                        {vid_type === "youtube" || vid_type === "vimeo" ? <div className="form-group">
                            <label htmlFor="RelatedVideos" className="form-label">
                            <p className="tos">Sorry, As per {vid_type==="youtube" ? <a href="https://developers.google.com/youtube/iframe_api_reference#Playback_controls">YouTube TOS</a>  : <a href="https://developer.vimeo.com/player/sdk/embed">Vimeo TOS</a>}, you can't prevent right-click. Choose another
                                video source to use this feature.</p>
                            </label>
                        </div> : ""  }
                    </div>
                </div>
            </div>*/}
        </>
    )
}

export default React.memo(Customize);
