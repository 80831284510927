import React from "react";

const AbTestContent = ({player,index,removeAdsButtons,projects,handleInputData}) => {

    const addProjects = (value, index) => {
        handleInputData(value, index)
    }

    return(
        <>
            <div className="input-cover-select">
                <select onChange={(e)=>addProjects(e.target.value, index)} className="form-control">
                    <option selected disabled>--Select Projects--</option>
                    {
                        projects.map((project)=>(
                            <option key={project.id} value={project.id} selected={player==project.id?true:false} >{project.campaign}</option>
                        ))
                    }
                </select>
                <button className="btn btn-danger ml-2" onClick={()=>removeAdsButtons(index)}>
                    <i className="fa fa-trash" />
                </button>
            </div>
        </>
    )
}


export default React.memo(AbTestContent);
