import {combineReducers} from "redux";
import authReducer from "./authReducer";
import socialReducer from "./socialReducer";
import rebrandReducer from "./rebrandReducer";
import integrateReducer from "./integrateReducer";
import videoFinderReducer from "./videoFinderReducer";
import alertReducer from "./alertReducer";
import supportReducer from "./supportReducer";
import currentVideoReducer from "./currentCampaignReducer";
import embedReducer from "./embedReducer";
import adsReducer from "./adsReducer";


const appReducer = combineReducers({
    auth: authReducer,
    social: socialReducer,
    rebrand: rebrandReducer,
    integrate: integrateReducer,
    youtubevideo: videoFinderReducer,
    alert: alertReducer,
    support: supportReducer,
    currentCampaign:currentVideoReducer,
    embedVid: embedReducer,
  })

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;
