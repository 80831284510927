import React, {useState} from "react";
// import { Tooltip } from 'react-bootstrap';

const MenuButton = ({index,id, inputAnalyticsData, removeMenuButtons}) => {

    const [status, setStatus] = useState(false);
    const [error, setError] = useState(false);
    const [errorMgs, setErrorMsg] = useState('');

    const handleInputChange = (e, type) => {

        if(type === "button_url"){
            let value = e.target.value;
            if(value.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)){
                inputAnalyticsData(e.target.value, id, type);
                setError(false)
            }else{
                setError(true);
                setErrorMsg('Invalid URL Ex- http://example.com/');
            }
        }else{
            inputAnalyticsData(e.target.value, id, type);
        }

    }

    const removeIcon = () => {
        removeMenuButtons(index)
        setStatus(true);
    }

    // $(function () {
    //     $('[data-toggle="tooltip"]').tooltip()
    // })

    return (
        <div className={`social-content-box bg-sec-box ${status ? 'hide-html' : ''}`} style={{marginBottom: "20px"}}>
            <div className="header-close d-flex justify-content-end">
                <button onClick={()=>removeIcon()} type="button" className="btn-close">
                    <i className="fa fa-times-circle" aria-hidden="true"/>
                </button>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                    
                        <label htmlFor="MenuTitle" className="form-label">Menu Title
                            {/* <a data-toggle="tooltip"
                            data-bs-toggle="tooltip" 
                               title="It is title of your main menu"
                               className="tooltip-box"
                               data-bs-placement="top"><i className="fa fa-question-circle"aria-hidden="true"/></a> */}
                                <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                    <span className="tooltiptext">It is title of your main menu</span>
                                </div>
                        </label>
                        <input type="text" name="title" className="form-control" onChange={(e)=>handleInputChange(e, 'title')} placeholder="Your Title (Home, About Us)"/>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="Headline"
                               className="form-label">Headline
                                <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                    <span className="tooltiptext">It is a headline text that appears when menu is opened</span>
                                </div>
                        </label>
                        <input type="text" name="heading" onChange={(e)=>handleInputChange(e,'heading')} className="form-control" placeholder="Your Headline"/>
                    </div>
                </div>
                <div>
                    <div className="form-group">
                        <label htmlFor="YourText"
                               className="form-label">Text
                                <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                    <span className="tooltiptext">It is text or content that shows more elaborative message</span>
                                </div>
                        </label>
                        <textarea className="form-control" name="text" onChange={(e)=>handleInputChange(e,'text')} rows="4" placeholder="Your Text"/>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="ButtonText" className="form-label">Button
                            Text:
                            <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                <span className="tooltiptext">Name of this button</span>
                            </div>
                        </label>
                        <input type="text" className="form-control" name="button_text" onChange={(e)=>handleInputChange(e,'button_text')} placeholder="Your Button Text"/>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="YourUrl" className="form-label">Button URL
                            <div className="my_tooltip"><i className="fa fa-question-circle" aria-hidden="true"/>
                                <span className="tooltiptext">URL or link on this button</span>
                            </div>
                        </label>
                        <input style={{marginBottom: "0"}} type="url" className="form-control" name="button_url" onChange={(e)=>handleInputChange(e,'button_url')} placeholder="Your Button URL"/>
                        {
                            error ?
                            <small style={{color: "#ff5050"}}>{errorMgs}</small>
                            : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default MenuButton;