import React, {useEffect, useState} from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import ProjectCard from "./ProjectCard";
import axios from "axios";
import {baseURL} from "../../global/global";
import Alert from "../Alert";
import {Link} from "react-router-dom";


const Projects = () => {

    const auth = useSelector(state => state.auth);
    const brandName = useSelector(state => state.rebrand.data);
    const [projects , setProjects] = useState([]);
    const [playLists, setPlayLists] = useState([]);
    const [projectLoad, setLoadProject] = useState(false);

    const [q, setQ] = useState('');

    const searchCampaign = (e) => {
        setQ(e.target.value)
    }

    const search = (projects) => {
        return projects.filter(
            project => project.campaign.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    }


    const fetchProjects = () => {
        setLoadProject(true);

        axios({
            method: 'POST',
            url: `${baseURL}fetch-campaigns`,
            data: {},
            headers: {'Content-Type': 'application/json','Authorization': auth.token }
        }).then(res=>{
            if(res.data.status===true){
                setProjects(res.data.data);
            }
            setLoadProject(false);
        }).catch(err=>{
            setLoadProject(false);
        })
    }

    const fetchPlaylist = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-playlist`,
            data: {},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res=>{
            if(res.data.status === true){
                setPlayLists(res.data.data);
            }
        }).catch(err=>{

        })
    }

    useEffect(()=>{
        fetchProjects();
        fetchPlaylist();
    },[])


    return(
        <>
            <Alert/>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | My Player </title>
            </Helmet>
            <Navbar/>
            <section id="my-video-projects" className="video-projects-sec">
                <div className="container">
                    <div className="my-video-projects-header mt-3">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-12 col-lg-5  d-flex justify-content-lg-start justify-content-center">
                                <div className="project-header d-sm-flex d-block">
                                    <h4 className="project-title">ALL PROJECTS</h4>
                                    <h6 className="project-count">{ search(projects).length} Project</h6>
                                </div>
                            </div>
                            <div className="col-12 col-lg-7  d-flex justify-content-lg-end justify-content-center">
                                <div className="project-button d-sm-flex d-block">
                                    <div className="project-button-search">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
										<span className="input-group-text" id="basic-addon1">
											<i className="fa fa-search" aria-hidden="true"></i>
										</span>
                                            </div>
                                            <input type="text" className="form-control search_thumb" placeholder="Search Player" onChange={(e)=>searchCampaign(e)} />
                                        </div>
                                    </div>
                                    {
                                        auth.user.is_client_account === "0" ?
                                            <>
                                                <div className="project-button-create altx">
                                                    <Link to="/create-player" className="gradiant-button-pb create-playlist-btn">
                                                        <i className="fa fa-plus-circle" aria-hidden="true"></i> Create Player
                                                    </Link>
                                                </div>
                                                <div className="project-button-create altx">
                                                    <Link to="/ab-test" className="gradiant-button-pb create-playlist-btn">
                                                        <i className="fa fa-plus-circle" aria-hidden="true"></i> Create A/B Test
                                                    </Link>
                                                </div>
                                            </>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-video-projects-box mt-3 ">
                        <hr />
                        {
                            projectLoad ? <h5 className="text-center"><i className="fa fa-spinner fa-spin mr-2"/> Loading Players</h5> : ''
                        }
                        {
                            search(projects).length < 1 && projects.length > 0  ? <h5 className="text-center">Sorry, you have not created any player with this '{q}' name</h5> : ''
                        }
                            <div className="my-video-projects mt-5">
                                <div className="row gx-4">
                                    {
                                        projects.length > 0 ?
                                            search(projects).map((item,index)=>(
                                                <ProjectCard key={item.id} project={item} fetchCampaign={fetchProjects} playLists={playLists} type="" />
                                            ))
                                        : projectLoad===false ? <h5 className="text-center">You have not created player yet</h5> : ''
                                    }
                                </div>
                            </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Projects;
