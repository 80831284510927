import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import {setAlert} from "../../../actions/alert";
import {baseURL} from "../../../global/global";
import axios from "axios";
import {removeThumbnail, searchedDataThumbnail, uploadThumbnail} from "../../../actions/currentCampaignAction";
import sample from "../../../images/sample.png";
import InfiniteScroll from "react-infinite-scroll-component";
import {Link} from "react-router-dom";


const Thumbnail = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const currentCampaignData = useSelector(state => state.currentCampaign.data);


    const [loadLogo, setLoadLogo] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loadSearch, setLoadSearch] = useState(false);
    const [error, setError] = useState(false);
    const [removeLogo, setRemoveLogo] = useState(1);
    const [searchThumb, setSearchThumb] = useState({
        search_type: "gif",
        keyword: "",
        page: 1,
    });

    const [gifdata, setGifData] = useState({
        video: currentCampaignData.url,
        duration: 5,
    });

    const [imagePage, setImagePage] = useState(1);
    const [gifPage, setGifPage] = useState(1);
    const [imageData, setImageData] = useState([]);
    const [gifImageData, setGifImageData] = useState([]);
    const [thumbreelData, setThumbreelData] = useState([]);
    const [removeType, setRemoveType] = useState('');

    const onInputChange = (e, type) => {
        setSearchThumb({...searchThumb, keyword: e.target.value});
        setImageData([]);
        setGifImageData([]);
    }

    const imageType = (e, type) => {
        setSearchThumb({...searchThumb, search_type: type});
    }

    const fetchReelAppsData = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-reelapps-data`,
            data: {},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                setThumbreelData(res.data.data.data.thumbreel);
            }

        }).catch(err => {
        })
    }


    const fileUpload = (file, type) => {

        if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {

            if (file.size < 5000000) {
                if (type === "image") {
                    setLoadLogo(true);
                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('file_type', type);
                    dispatch(uploadThumbnail(formData, setLoadLogo));
                }

            } else {
                swal("Oops!", "Max allowed size 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }

    }


    const onLogoUpload = (e) => {
        fileUpload(e.target.files[0], 'image');
        console.log(e.target.files[0])
    }

    const handleRemoveLogo = (e, type) => {
        if (removeLogo === 1) {
            setRemoveLogo(0);
            dispatch(removeThumbnail('REMOVE_THUMBNAIL'))
        } else {
            setRemoveLogo(1);
        }
        setRemoveType(type);
    }

    const searchImage = (page = 1) => {
        setLoadSearch(true);

        axios({
            method: "POST",
            url: `${baseURL}search-media`,
            data: JSON.stringify({
                keyword: searchThumb.keyword,
                search_type: searchThumb.search_type,
                page: page
            }),
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                setError(false);

                if (searchThumb.search_type === "gif") {
                    let arr = [...gifImageData];
                    setGifImageData(arr.concat(res.data.data));
                    setGifPage(page);
                } else {
                    let arr = [...imageData];
                    setImageData(arr.concat(res.data.data));
                    setImagePage(page)
                }

            } else {
                setError(res.data.message);
            }

            setLoadSearch(false);
        }).catch(err => {
            setLoadSearch(false);
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setError(false);
        searchImage();
    }

    const selectImage = (img) => {
        dispatch(searchedDataThumbnail(img))
    }

    const rangeSelector = (e) => {
        setGifData({...gifdata, duration: e.target.value});
    }

    const convertGif = () => {
        setLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}covert-gif`,
            data: {
                video: gifdata.video,
                duration: gifdata.duration
            },
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(searchedDataThumbnail(res.data.data))
                dispatch(setAlert('Gif generated successfully', 'success'));
            } else {
                dispatch(setAlert('Unable to generate this media, please try another one', 'danger'));
            }

            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    const submitGif = () => {
        setError(false);
        convertGif();
    }


    useEffect(() => {
        fetchReelAppsData();

        return () => {
            setGifImageData([]);
            setImageData([]);
            setThumbreelData([]);
        }
    }, [])


    return (
        <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                 aria-labelledby="pills-home-tab">
                <form action="" method="POST" encType="multipart/form-data">
                    <div className="thumbnail-content">
                        <h5 style={{marginBottom: "0px", display: "flex", justifyContent: "space-between"}}>
                            Thumbnail
                            {/*<label className="switch ms-3">*/}
                            {/*    <input type="checkbox"*/}
                            {/*           disabled={*/}
                            {/*               currentCampaignData.thumbnail.includes('images') ?*/}
                            {/*               currentCampaignData.thumbnail === '' ? true : false*/}
                            {/*               : true*/}
                            {/*           }*/}
                            {/*           checked={currentCampaignData.thumbnail === '' && removeType==="images" ? true : false}*/}
                            {/*           onChange={(e) => handleRemoveLogo(e,'images')}/>*/}
                            {/*    <span className="slider round"/>*/}
                            {/*</label>*/}
                        </h5>
                        <div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group thumbnail-form">
                                        <label className="control-label">Upload Your Thumbnail</label>
                                        <div className="dropzone-wrapper">
                                            <div className="dropzone-desc">
                                                <p>
                                                    <i className="fa fa-cloud-upload" aria-hidden="true"/> Upload An
                                                    Image <br/>
                                                    <small>(JPG, JPEG, PNG upto 5MB allowed)</small>
                                                </p>
                                            </div>
                                            <input type="file" name="img_logo"
                                                   className="dropzone"
                                                   onChange={(e) => onLogoUpload(e)}
                                            />
                                        </div>
                                        <div className="preview-zone hidden">
                                            <div className="box box-solid">
                                                <div className="col-sm-2 col-3 p-0 d-flex justify-content-center">
                                                    <div className="style-remove-cricle">
                                                        {
                                                            currentCampaignData.thumbnail.includes('images') ?
                                                                <img
                                                                    src={currentCampaignData.thumbnail !== "" ? currentCampaignData.thumbnail : sample}
                                                                    alt
                                                                    className="img-fluid"
                                                                />
                                                                : <img
                                                                    src={sample}
                                                                    alt
                                                                    className="img-fluid"
                                                                />
                                                        }
                                                        {
                                                            loadLogo ? <div className="loader-icon"><i
                                                                    className="fa fa-spinner fa-spin"/></div>
                                                                : ''
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-sm-10 col-9 p-0 d-flex ">
                                                    <div className="box-header with-border">
                                                        <div className="box-tools pull-right">
                                                            <button type="button"
                                                                    className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center">
                                                                Remove
                                                                <label className="switch ms-3">
                                                                    <input type="checkbox"
                                                                           disabled={
                                                                               currentCampaignData.thumbnail.includes('images') ?
                                                                                   currentCampaignData.thumbnail === '' ? true : false
                                                                                   : true
                                                                           }
                                                                           checked={currentCampaignData.thumbnail === '' && removeType === "images" ? true : false}
                                                                           onChange={(e) => handleRemoveLogo(e, 'images')}/>
                                                                    <span className="slider round"/>
                                                                </label>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {
                    currentCampaignData.video_type === "mp4" ?
                        <div className="gif-generator mt-4">
                            <h5 style={{marginBottom: "0px", display: "flex", justifyContent: "space-between"}}>
                                Gif Generator
                            </h5>
                            <div className="price-range-slider">

                                <p className="range-value">
                                    <label htmlFor="">Seconds ({gifdata.duration})</label>
                                </p>
                                <input id="slider-range" className="range-bar" type="range"
                                       defaultValue={gifdata.duration} min="1" max="10"
                                       onClick={(e) => rangeSelector(e)}/>

                                <div className="cover-gif mt-2">
                                    <div className="style-remove-cricle">

                                        {
                                            currentCampaignData.thumbnail.includes('gif') && currentCampaignData.thumbnail.includes('ReelMergeApp') ?
                                                <img
                                                    src={currentCampaignData.thumbnail !== "" ? currentCampaignData.thumbnail : sample}
                                                    alt
                                                    className="img-fluid"
                                                />
                                                : <img
                                                    src={sample}
                                                    alt
                                                    className="img-fluid"
                                                />
                                        }
                                    </div>

                                    <button className="btn gradiant-button-pb btn-submit" onClick={(e) => submitGif(e)}
                                            style={{margin: "5px", fontSize: "15px", width: "auto"}}>
                                        {loader ? <i className="fa fa-spinner fa-spin"/> : ''} Apply
                                    </button>
                                    <button type="button"
                                            className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center" style={{color: "#6E6E6E"}}>
                                        Remove
                                        <label className="switch ms-3">

                                            <input type="checkbox"
                                                   disabled={
                                                       currentCampaignData.thumbnail.includes('gif') && currentCampaignData.thumbnail.includes('ReelMergeApp') ?
                                                           currentCampaignData.thumbnail === '' ? true : false
                                                           : true
                                                   }
                                                   checked={currentCampaignData.thumbnail === '' && removeType === "gif" ? true : false}
                                                   onChange={(e) => handleRemoveLogo(e, 'gif')}/>
                                            <span className="slider round"/>
                                        </label>
                                    </button>
                                </div>

                                <div className="apply-btn d-flex justify-content-end">
                                    <button className="btn gradiant-button-pb btn-submit" onClick={(e) => submitGif(e)}
                                            style={{margin: "5px", fontSize: "15px", width: "auto"}}>
                                        {loader ? <i className="fa fa-spinner fa-spin"/> : ''} Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                        : ''
                }
                <div className="img-library">
                    <h5>Image & GIF Library</h5>
                    <div className="img-library-box">
                        <ul className="nav nav-pills justify-content-center align-items-center mb-3"
                            id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-giphy-tab"
                                        data-bs-toggle="pill" data-bs-target="#pills-giphy"
                                        type="button" role="tab" aria-controls="pills-giphy"
                                        aria-selected="true" onClick={(e) => imageType(e, "gif")}>Giphy
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-tenor-tab"
                                        data-bs-toggle="pill" data-bs-target="#pills-tenor"
                                        type="button" role="tab" aria-controls="pills-tenor"
                                        aria-selected="false" onClick={(e) => imageType(e, "image")}>Image
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-pixabay-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-pixabay" type="button" role="tab"
                                        aria-controls="pills-pixabay" aria-selected="false">ThumbReel
                                </button>
                            </li>

                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-giphy" role="tabpanel"
                                 aria-labelledby="pills-giphy-tab">
                                <div className="giphy-box ">
                                    <form className="search-bg-white" method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
														<span className="input-group-text" id="basic-addon1">
															<i className="fa fa-search" aria-hidden="true"/>
														</span>
                                            </div>
                                            <input style={{color: "#000"}} type="text"
                                                   className="form-control search_thumb"
                                                   placeholder="Search your keyword here..."
                                                   aria-label="SearchPlaylish"
                                                   aria-describedby="basic-addon1" onInput={(e) => onInputChange(e)}/>
                                        </div>
                                    </form>
                                    <div className="img-thumbnails mt-3">
                                        <div className="row" id="scrollableMeme"
                                             style={{overflowX: "hidden", maxHeight: "70vh"}}>

                                            <InfiniteScroll
                                                dataLength={gifImageData.length} //This is important field to render the next data
                                                next={() => searchImage(gifPage + 1)}
                                                hasMore={true}
                                                scrollableTarget="scrollableMeme"
                                            >
                                                {gifImageData.length > 0 ? gifImageData.map((item, index) => (
                                                    <div className="col-sm-12 col-md-6" key={index}>
                                                        <a onClick={() => selectImage(item.image)}
                                                           className="d-block mb-4 h-100 giphy-img cursor-pointer">
                                                            <img src={item.image}
                                                                 className="img-thumbnail"
                                                                 alt="image-placeholder"/>
                                                        </a>
                                                    </div>
                                                )) : ""
                                                }
                                                {
                                                    loadSearch ?
                                                        <div className="col-md-12">
                                                            <h4 className="text-center"><i
                                                                className="fa fa-spinner fa-spin mr-2"/></h4>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    error ?
                                                        <div className="col-md-12">
                                                            <h6 className="text-center">{error}</h6>
                                                        </div>
                                                        : ''
                                                }
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-tenor" role="tabpanel"
                                 aria-labelledby="pills-tenor-tab">
                                <div className="giphy-box">
                                    <form className="search-bg-white" method="post"
                                          onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
														<span className="input-group-text" id="basic-addon1">
															<i className="fa fa-search" aria-hidden="true"/>
														</span>
                                            </div>
                                            <input style={{color: "#000"}} type="text"
                                                   className="form-control search_thumb"
                                                   placeholder="Search your keyword here..."
                                                   aria-label="SearchPlaylish"
                                                   aria-describedby="basic-addon1"
                                                   onInput={(e) => onInputChange(e)}/>
                                        </div>
                                    </form>
                                    <div className="img-thumbnails mt-3">
                                        <div className="row" id="scrollableImage"
                                             style={{overflowX: "hidden", maxHeight: "70vh"}}>
                                            <InfiniteScroll
                                                dataLength={imageData.length} //This is important field to render the next data
                                                next={() => searchImage(imagePage + 1)}
                                                hasMore={true}
                                                scrollableTarget="scrollableImage"
                                            >
                                                {imageData.length > 0 ? imageData.map((item, index) => (
                                                    <div className="col-sm-12 col-md-6" key={index}>
                                                        <a onClick={() => selectImage(item.image)}
                                                           className=" cursor-pointer">
                                                            <img src={item.thumb}
                                                                 className="img-thumbnail"
                                                                 alt="image-placeholder"/>
                                                        </a>
                                                    </div>
                                                )) : ""
                                                }
                                                {
                                                    loadSearch ?
                                                        <div className="col-md-12">
                                                            <h4 className="text-center"><i
                                                                className="fa fa-spinner fa-spin mr-2"/></h4>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    error ?
                                                        <div className="col-md-12">
                                                            <h6 className="text-center">{error}</h6>
                                                        </div>
                                                        : ''
                                                }
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-pixabay" role="tabpanel"
                                 aria-labelledby="pills-pixabay-tab">
                                <div className="row">
                                    {
                                        thumbreelData.length > 0 ? thumbreelData.map((item, index) => (

                                            <div className="col-sm-12 col-md-6" key={index}>
                                                <a onClick={() => selectImage(item.thumbnail)}
                                                   className="d-block mb-4 h-100 giphy-img">
                                                    <img src={item.thumbnail}
                                                         className="img-thumbnail"
                                                         alt="image-placeholder"/>
                                                </a>
                                            </div>

                                        )) : <div className="alert alert-primary" role="alert">
                                            You haven't connected your ThumbReel account yet. Click <Link
                                            to="/integration" className="custom-anchor">Here</Link> to connect and
                                            use those thumbnails</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default React.memo(Thumbnail);
