import React, {useEffect, useState} from "react";
import analyticsImg from "../../images/analytics.png";
import Navbar from "../Navbar";
import Footer from "../Footer";
import queryString from "query-string";
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";
import {Link} from "react-router-dom";
import TablePagination from "./Pagination";
import Pagination from "./Pagination";
import ReactPaginate from "react-paginate";
import {Helmet} from "react-helmet";


const Analytics = ({location}) => {
    const brandName = useSelector(state => state.rebrand.data);
    const id = queryString.parse(location.search).id;
    const ab_id = queryString.parse(location.search).ab_id;

    console.log(id);

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [loadData, setLoadData] = useState(false);
    const [loader, setLoader] = useState(false);
    const [searchData, setSearchData] = useState({
        id: id,
        start: '',
        end: ''
    })
    const [dataCount, setDataCount] = useState({
        impressions: 0,
        live_visitors: 0,
        unique_impressions: 0,
        viewers: 0,
        views: 0,
        watch_time: 0
    });

    const [watchTime, setWatchTime] = useState(0);
    const [CTR, setCTR] = useState();

    const [browser, setBrowser] = useState([]);
    const [os, setOs] = useState([]);
    const [players, setPlayers] = useState([]);
    const [durationTime, setDurationTime] = useState('all');
    const [viewVideo, setViewVideo] = useState(1)

    const selectDuration = (e) => {
        setDurationTime(e.target.value)

        fetchAnalytics(e.target.value);
    }

    const handleChange = (e) => {
        setSearchData({...searchData, [e.target.name]: e.target.value});
    }

    const fetchAnalytics = async (type=undefined) => {
        setLoadData(true);

        if (id === undefined) {
            searchData.user_id = auth.user.id;
        }
        if(ab_id !== undefined && ab_id !== '' && id === undefined){
            searchData.ab_test = ab_id;
        }
        if(id !== undefined){
            searchData.id = id;
            delete searchData.ab_test;
            delete searchData.user_id;
            setViewVideo(1);
        }


        if(type!==''){
            searchData.type = type;
        }


        axios({
            method: "POST",
            url: `${baseURL}fetch-overview`,
            data: searchData,
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                setDataCount(res.data.data);
                setBrowser(res.data.browser);
                setOs(res.data.os);
                convertTime(res.data.data.watch_time);


                if (id === undefined) {
                    setPlayers(res.data.players);
                    receivedData(res.data.players, 0, 10);
                    setViewVideo(res.data.players.length);
                }


            } else {
                // dispatch(setAlert(res.data.message,'danger'));
            }

            setLoader(false);
            setLoadData(false);
        }).catch(err => {
            setLoader(false);
            setLoadData(false);

            dispatch(setAlert('Something went wrong, Please try again', 'danger'));
        })
    }

    const handleFilter = (e) => {
        e.preventDefault();
        setLoader(true);

        fetchAnalytics();
    }

    const convertTime = (time)=>{
        let t = time;

        let min = Math.round(t/60);
        setWatchTime(min)
    }

    const convertCTR = ()=>{
        let impression = dataCount.impressions;
        let ctr = (dataCount.views/impression)*100;
        setCTR(ctr);
    }

    const [offset, setOffset] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0)
    const [postData, setPostData] = useState([]);

    const receivedData = (data, offset, perPage) => {

        const slice = data.slice(offset, offset + perPage);

        setPageCount(Math.ceil(data.length / perPage));
        let tempData = slice.map(item =>
            <>
                <tr>
                    <th scope="row">{item['name'] !== null ? item['name'] : 'N/A'}</th>
                    <td>{item['total_views']}</td>
                    <td>{item['total_impressions']}</td>
                    <td>{item['total_viewers']}</td>
                    <td>{item['total_live_visitors']}</td>
                    <td>{item['total_unique_impressions']}</td>
                    <td>{item['total_watch_time']}</td>
                    <td>
                        <Link to={`/analytics?id=${item.player_id}`}
                              className="btn btn-gradient-orange">View</Link>
                    </td>
                </tr>
            </>
        )

        setPostData(tempData);

    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffset(offset);
        receivedData(players, offset, 10);

    }



    useEffect(() => {
        fetchAnalytics();
        convertCTR();

    }, [id]);

    useEffect(() => {
        receivedData(players, 0, 10);
    }, []);



    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Analytics </title>
            </Helmet>
            <Navbar/>
            <Alert/>
            <div id="Analytics" className="analytics">
                <div className="container">
                    <div>
                        <div className="row d-flex justify-content-center mt-4">
                            <div className="col-md-12 col-12">
                                <h3><span className="orange">Analytics</span></h3>
                                <div className="content-box content-box-bg-black date-range">
                                    <div className="row mt-5">
                                        <div className="col-lg-5 col-12">
                                            <div className="form-group col-md-12 p-0">
                                                <label for="Duration">Duration</label>
                                                <div className="input-group">
                                                    <select id="Duration" className="form-control select-custom w-100"
                                                            onChange={(e) => selectDuration(e)}>
                                                        <option value="all" selected>All Time</option>
                                                        <option value={24} selected>Daily</option>
                                                        <option value={168} selected>Weekly</option>
                                                        <option value={672} selected>Monthly</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <form method="post" onSubmit={(e) => handleFilter(e)} className="offset-lg-1 offset-sm-0 col-lg-6 col-12">
                                            <div className="form-row">
                                                <div className="form-group col-md-5">
                                                    <label for="From">From</label>
                                                    <div className="input-group">
                                                        <input onChange={(e) => handleChange(e)} name="start"
                                                               type="date" className="form-control" id="from"/>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label for="To">To</label>
                                                    <div className="input-group">
                                                        <input onChange={(e) => handleChange(e)} type="date"
                                                               className="form-control" name="end" id="To"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    {/* <label for="search-text" className="">sadsad</label> */}
                                                    <button disabled={loader} type="submit"
                                                            className="btn btn-bundle primary-gradient btn-duration-submit">
                                                        {loader ? <i className="fa fa-spinner fa-spin "/> : ''} Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="content-box content-box-bg-dark-gray analytics-box">
                                    <div className="row">
                                        <div className="col-12 col-lg-3 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Videos</p>
                                                    <p className="number-views">
                                                        {loadData ? <i className="fa fa-spinner fa-spin "/> : viewVideo}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Views</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : dataCount.views}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Viewers</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : dataCount.viewers}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Live Visitors</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : dataCount.live_visitors}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Impressions</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : dataCount.impressions}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Impressions CTR%</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : dataCount.impressions}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Unique Impressions</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : dataCount.unique_impressions}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Watch Time</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : watchTime}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            id === undefined ?
                                <div className="content-bor20 content-box-bg-dark-gray my-5">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-12 col-12">
                                            <div className="analytics-table">
                                                <div className="table-box">
                                                    <table className="table table-borderless">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Views</th>
                                                            <th scope="col">Impressions</th>
                                                            <th scope="col">Unique Impressions</th>
                                                            <th scope="col">Viewers</th>
                                                            <th scope="col">Live Visitors</th>
                                                            <th scope="col">Watch Time</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            players.length > 0 ?

                                                                postData
                                                                : ''
                                                        }
                                                        <hr/>
                                                        </tbody>
                                                    </table>

                                                    <ReactPaginate
                                                        previousLabel={"prev"}
                                                        nextLabel={"next"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }

                        <div className="content-bor20 content-box-bg-dark-gray my-5">
                            <div className="row d-flex justify-content-center">

                                <div className="col-md-6 col-12">
                                    <div className="analytics-tables">

                                        <div className="table-box">
                                            <table className="table table-borderless">
                                                <thead>
                                                <tr>
                                                    <th scope="col">OS</th>
                                                    <th scope="col">Visits</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    os.length > 0 ?
                                                        os.map((item, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{item['os']}</th>
                                                                <td>{item['COUNT(*)']}</td>
                                                            </tr>
                                                        ))
                                                        : ''
                                                }
                                                <hr/>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12">
                                    <div className="analytics-tables">
                                        <div className="table-box">
                                            <table className="table table-borderless">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Browser</th>
                                                    <th scope="col">Visits</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    browser.length > 0 ?
                                                        browser.map((item, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{item['browser']}</th>
                                                                <td>{item['COUNT(*)']}</td>
                                                            </tr>
                                                        ))
                                                        : ''
                                                }
                                                <hr/>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}


export default Analytics;
