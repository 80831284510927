import './App.css';
import "./css/hover.css";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Login from "./components/auth/Login";
import ForgetPassword from "./components/auth/ForgetPassword";
import Dashboard from "./components/dashboard/Dashboard";
import {PrivateRoute} from "./components/PrivateRoute";
import Profile from "./components/user/Profile";
import Privacy from "./components/user/Privacy";
import ResetPassword from "./components/auth/ResetPassword";
import AccountManagement from "./components/user/AccountManagement";
import Integration from "./components/integration/Integration";
import Upgrades from "./components/user/Upgrades";
import ResellerAccount from './components/user/ResellerAccount';
import Connectapp from "./components/Connectapp";
import AutoResponder from "./components/AutoResponder";
import Videofinder from "./components/player/VideoFinder";
import Help from "./components/support/Help";
import Training from "./components/support/Training";
import HelpSupport from "./components/support/HelpSupport";
import CustomUpload from "./components/upload/CustomUpload";
import Editor from "./components/editor/Editor";
import TrainingArticles from "./components/support/TrainingArticles";
import FetchVideos from "./components/videos/FetchVideos";
import Projects from "./components/projects/Projects";
import Embed from "./components/editor/Embed";
import Analytics from "./components/player/Analytics";
import Engagement from "./components/player/Engagement";
import Abtesting from "./components/player/Abtesting";
import MasterLogin from "./components/MaterLogin";
import AbTestProject from "./components/projects/AbTestProject";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/embed/:id" component={Embed} />
          <Route exact path="/master-login" component={MasterLogin} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/privacy" component={Privacy} />
          <PrivateRoute exact path="/account-management" component={AccountManagement} />
          <PrivateRoute exact path="/integration" component={Integration} />
          <PrivateRoute exact path="/upgrades" component={Upgrades} />
          <PrivateRoute exact path="/reseller" component={ResellerAccount} />
          <PrivateRoute exact path="/connect" component={Connectapp}/>
          <PrivateRoute exact path="/help-and-support" component={HelpSupport}/>
          <PrivateRoute exact path="/create-player" component={CustomUpload}/>
          <PrivateRoute exact path="/training" component={Training}/>
          <PrivateRoute exact path="/support-article" component={TrainingArticles}/>
          <PrivateRoute exact path="/fetch" component={FetchVideos}/>

          <PrivateRoute exact path="/auto-responder" component={AutoResponder}/>
          <PrivateRoute exact path="/video-finder" component={Videofinder}/>
          <PrivateRoute exact path="/editor" component={Editor}/>
          <PrivateRoute exact path="/my-players" component={Projects}/>
          <PrivateRoute exact path="/ab-testing" component={Abtesting}/>
          <PrivateRoute exact path="/analytics" component={Analytics}/>
          <PrivateRoute exact path="/engagement" component={Engagement}/>
          <PrivateRoute exact path="/ab-test" component={AbTestProject}/>

        </Switch>
      </Router>
    </div>
  );
}

export default App;
