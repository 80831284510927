import React, {useEffect, useState} from "react";
import randomstring from "randomstring";
import MenuButton from "./MenuButton";
import {useDispatch} from "react-redux";
import {addAnalyticsData, addMenuData} from "../../../actions/currentCampaignAction";
import swal from "sweetalert";

const MenuButtons = ({menuButtons}) => {

    const dispatch = useDispatch();

    const [initialData, setInitialData] = useState({
        id: randomstring.generate({length: 9, charset : 'numeric'}),
        title: '',
        heading: '',
        text: '',
        button_text: '',
        button_url: ''
    })
    const [menuCount, setMenuCount] = useState([
        initialData
    ])

    const addMoreMenu = () => {
        let obj = {
            id: randomstring.generate({length: 9, charset : 'numeric'}),
            title: '',
            heading: '',
            text: '',
            button_text: '',
            button_url: ''
        }
        let arr = [...menuCount];
        arr.push(obj)

        setMenuCount(arr);
    }

    function search(nameKey, myArray){
        for (let i=0; i < myArray.length; i++) {
            if (myArray[i].id === nameKey) {
                return i;
            }
        }
    }

    const inputAnalyticsData = (value, id, type) => {
        let index = search(id, menuCount);

        let newTrans = [...menuCount];

        if(type === 'title')
            newTrans[index].title = value
        else if(type === 'heading')
            newTrans[index].heading = value
        else if(type === 'text')
            newTrans[index].text = value
        else if(type === 'button_text')
            newTrans[index].button_text = value
        else if(type === 'button_url')
            newTrans[index].button_url = value


        setMenuCount(menuCount);
    }

    const removeMenuButtons = (index) => {
        let arr = [...menuCount];
        arr.splice(index,1);

        setMenuCount(arr);
    }




    const saveMenuData = () =>{
        const validation = menuCount.every((item) => {
            return Boolean(item.button_text && item.button_url  && item.heading  && item.title);
        });

        if(validation){
            dispatch(addMenuData(menuCount));
        }
        else{
            swal('Please, fill the required details!');
        }

    }

    useEffect(()=>{
       if(menuButtons !== false && menuButtons !== "" && menuButtons!==null){
           setMenuCount(menuButtons)
       }
    },[])

    return(
        <>

            {
                menuCount.length > 0 ?
                    menuCount.map((item,index)=>(
                        <MenuButton key={item.id} index={index} id={item.id} inputAnalyticsData={inputAnalyticsData} removeMenuButtons={removeMenuButtons} />
                    ))
                    : ''
            }
            <div className="row mt-4">
                <div className="col-12 d-md-flex d-block justify-content-center text-center">
                    <a onClick={()=>addMoreMenu()} className="btn btn-link btn-gradient-orange btn-small border-radius-circle" role="button">
                        <span><i className="fa fa-plus-circle" aria-hidden="true"/></span> Add More
                    </a>
                    <a onClick={()=>saveMenuData()}  className="btn btn-link btn-gradient-purple btn-small border-radius-circle" role="button">
                        <span><i className="fa fa-film" aria-hidden="true"/></span> Save
                    </a>
                </div>
            </div>
        </>
    )

}

export default React.memo(MenuButtons);
