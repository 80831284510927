import React, {useEffect, useState} from "react";
import {uploadVideo} from "../../actions/currentCampaignAction";
import {useDispatch} from "react-redux";
 import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import FetchVideos from "../videos/FetchVideos";
import {useHistory} from "react-router-dom";
import ImportFiles from "./ImportFiles";
import axios from "axios";
import randomstring from "randomstring";

const UploadVideo = ()=>{

    const dispatch = useDispatch();
    const history = useHistory();

    const [errorStatus, setErrorStatus] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const defaultName = randomstring.generate({
        length: 5,
        charset: 'numeric'
    })

    const [fileInfo, setFileInfo] = useState({
        name: `campaign_${defaultName}`,
        video_type: "youtube",
    });
    const [fileData, setFileData] = useState('');

    const onInputChange = (e, type)=>{

        setFileInfo({...fileInfo, [e.target.name]: e.target.value});

        if(type!==''){
            setFileData('');
        }
        setErrorStatus(false);
    }

    const onInputFile = (e) => {
        if(e.target.files[0].type === 'video/mp4') {
            setFileData(e.target.files[0]);
        }else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }

    const onInputFileUrl = async (value) => {


        if(fileInfo.video_type === "youtube"){
            if (value.match(/(\?|&)v=([^&#]+)/) || value.match(/(\.be\/)+([^\/]+)/) || value.match(/(\embed\/)+([^\/]+)/)){
                setErrorStatus(false);
            }else{
                setErrorStatus(true);
                setErrorMessage('It seems that your given input is not a valid Youtube Url')
            }
        }else if(fileInfo.video_type === "vimeo"){
            // if (value.match(/(\?|&)v=([^&#]+)/) || value.match(/(\.com\/)+([^\/]+)/) || value.match(/(\video\/)+([^\/]+)/)){
            //     setErrorStatus(false);
            // }
            // else{

                await axios({
                    method: 'GET',
                    url: `https://vimeo.com/api/oembed.json?url=${value}`
                }).then(res=>{
                    setErrorStatus(false);
                }).catch(err=>{
                    setErrorStatus(true);
                    setErrorMessage('It seems that your given input is not a valid Vimeo Url')
                })

            // }
        }else if(fileInfo.video_type === "other"){
            let fileExtension = value.replace(/^.*\./, '');
            if(fileExtension === "mp4" ){
                setErrorStatus(false);
            }else{
                setErrorStatus(true);
                setErrorMessage('It seems that your given input is not a valid MP4 Url')
            }
        }
        setFileData(value);
    }

    const onFormSubmit = (e)=> {
        e.preventDefault();
        setLoader(true);

        let formData = new FormData();
        formData.append('name', fileInfo.name);
        formData.append('video_type', fileInfo.video_type);
        formData.append('file', fileData);
        dispatch(uploadVideo(formData, setLoader,history))
    }



    return(
        <>

            <section className="create-player">
                <div className="container">
                    <div className="mx-auto">
                        <div className="create-player-card">
                            <h5 className="create-player-card-header">
                                <label className="form-label">Pick A <span className="highlight red">Video</span></label>
                            </h5>
                            <div className="create-player-card-body">
                            <div alt="Please fill out this field" className="tooltip-cus"><input type="text" className="form-control" name="name" onChange={(e)=>onInputChange(e,'')} placeholder="Enter player name"required title=" " /></div>
                                    <div className="social-checkbox d-inline d-lg-flex my-4">
                                        <div className="form-check">
                                            <label className="form-check-label" >
                                                <input checked={fileInfo.video_type==="youtube"?true:false} className="form-check-input" value="youtube" type="radio" name="video_type" onChange={(e)=>onInputChange(e,'youtube')} />
                                                    Youtube
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label" >
                                                <input checked={fileInfo.video_type==="vimeo"?true:false} className="form-check-input" value="vimeo" type="radio" name="video_type" onChange={(e)=>onInputChange(e,'vimeo')} />
                                                    Vimeo
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label" >
                                                <input checked={fileInfo.video_type==="other"?true:false} className="form-check-input" value="other" type="radio" name="video_type" onChange={(e)=>onInputChange(e,'other')} />
                                                    Other URLs
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label" >
                                                <input checked={fileInfo.video_type==="mp4"?true:false} className="form-check-input" value="mp4" type="radio" name="video_type" onChange={(e)=>onInputChange(e,'mp4')} />
                                                    Upload
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label" >
                                                <input checked={fileInfo.video_type==="import"?true:false} className="form-check-input" value="import" type="radio" name="video_type" onChange={(e)=>onInputChange(e, 'import')} />
                                                Import
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-3 pick-video">
                                        {/* <a href="#" data-title="This is the title to show">Test Me</a> */}
                                        {
                                            fileInfo.video_type === 'mp4' ?
                                            <div alt="MP4 upto size 100MB allowed" class="tooltip-cus"><input type="file" className="form-control" onChange={(e)=>onInputFile(e)} title=" " /></div>
                                            :
                                                fileInfo.video_type === 'import' ? <ImportFiles onInputFileUrl={onInputFileUrl}/>
                                                : <input type="text" value={fileData} className="form-control" onChange={(e)=>onInputFileUrl(e.target.value)} placeholder={`Enter a valid and complete ${fileInfo.video_type==="other"? "mp4": fileInfo.video_type} URL`} />
                                        }
                                        {
                                            errorStatus ?
                                                <span className="custom-error">{errorMessage}</span>
                                            : ''
                                        }
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button onClick={(e)=>onFormSubmit(e)}
                                                disabled={errorStatus === true || fileInfo.name==="" || fileData==="" ?true : false }
                                                type="submit" className="btn btn-bundle primary-gradient btn-next">
                                            {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : 'Create Player'}
                                            {loader ? ' Uploading' : ''}
                                        </button>
                                    </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default UploadVideo;
