import React from 'react'

function CustomerSupport() {
    return (
        <div>
            <section className="customer-sec">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 mx-auto ">
                        <div className="customer-content text-center">
                            <h6>CUSTOMER SUPPORT</h6>
                            <h5>Can't find your answer?</h5>
                            <p className="my-3">We're here to help! From general communication to specific ClipsReel
                                platform questions, please reach out so we can assist</p>
                            <a type="button" href="https://vineasx.helpscoutdocs.com/" target="_blank" className="gradiant-button-pb view-all"> Contact Our Helpdesk
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default CustomerSupport
