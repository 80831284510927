import React, {useEffect, useState} from "react";
import {SketchPicker} from "react-color";
import {useDispatch, useSelector} from "react-redux";
import {addCtaData, updateCtaData} from "../../../actions/currentCampaignAction";
import Alert from "../../Alert";
import axios from "axios";
import {baseURL} from "../../../global/global";

const CallToAction = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [emailOption, setEmailOption] = useState(props.ctaType);
    const [bgStatus, setBgStatus] = useState(false);
    const [textStatus, setTextStatus] = useState(false);
    const [color1Status, setColor1Status] = useState(false);
    const [color2Status, setColor2Status] = useState(false);
    const [color3Status, setColor3Status] = useState(false);
    const [buttonStatus, setButtonStatus] = useState(false);
    const [optinData, setOptinData] = useState([]);

    const [startTime, setStartTime] = useState({
        minutes: 0,
        seconds: 0
    })
    const [endTime, setEndTime] = useState({
        minutes: 0,
        seconds: 0
    })
    const [buttonData, setButtonData] = useState({
        headline: '',
        button_url: ''
    })
    const [emailData, setEmailData] = useState({
        auto_responder_campaign: '',
        headline: ''
    })

    const [buttonStyle, setButtonStyle] = useState({
        button_text: '',
        bg_color: '#FF0000',
        text_color: '#ffffff',
        button_color_1: '#FF9B4E',
        button_color_2: '#00B23B',
        button_color_3: '#FC9CFF',
        button_text_color: '#6E5400'
    })

    const handleStartTime = (e) => {
        setStartTime({...startTime, [e.target.name]: e.target.value});
    }

    const handleEndTime = (e) => {
        setEndTime({...endTime, [e.target.name]: e.target.value});
    }

    const handleButton = (e) => {
        setButtonData({...buttonData, [e.target.name]: e.target.value});
    }

    const handleEmail = (e) => {
        setEmailData({...emailData, [e.target.name]: e.target.value});
    }

    const onInputBgColor = (e, type) => {
        setButtonStyle({...buttonStyle, [type]: e})
    }

    const saveCtaData = () => {
        let mergeObj = {...buttonStyle};
        if(emailOption === 'email'){
            mergeObj = {...emailData, ...buttonStyle}
        }else if(emailOption === 'button'){
            mergeObj = {...buttonData, ...buttonStyle}
        }



        dispatch(addCtaData(mergeObj));
        dispatch(updateCtaData(emailOption, startTime, endTime))
    }

    function secondsToHms(d) {
        d = Number(d);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let mDisplay = m > 0 ? m : 0;
        let sDisplay = s > 0 ? s : 0;
        return mDisplay +'_'+ sDisplay;
    }

    const fetchOptin = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-optin`,
            data: {},
            headers: {'Content-Type': 'application/json','Authorization': auth.token }
        }).then(res=>{

            if(res.data.status===true){
                setOptinData(res.data.data);
            }
        }).catch(err=>{

        })
    }

    useEffect(()=>{
        setStartTime({
           minutes: parseInt(secondsToHms(props.startTime).split('_')[0]),
           seconds: parseInt(secondsToHms(props.startTime).split('_')[1])
       })

        setEndTime({
            minutes: parseInt(secondsToHms(props.endTime).split('_')[0]),
            seconds: parseInt(secondsToHms(props.endTime).split('_')[1])
        })

        // setButtonStyle(props.ctaData)
    },[])

    useEffect(()=>{
        fetchOptin();
    },[])

    return (
        <>
            <Alert/>
            <form action="" method="POST">
                <div className="call-to-action-content-box bg-sec-box mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="my-1 mr-2 small">Email</label>
                                <select className="custom-select my-1 mr-sm-2"
                                        onChange={(e) => setEmailOption(e.target.value)}>
                                    <option selected={emailOption==="none"?true:false} value="none">None</option>
                                    <option selected={emailOption==="email"?true:false} value="email">Email</option>
                                    <option selected={emailOption==="button"?true:false} value="button">Button</option>
                                </select>
                            </div>
                        </div>
                        {
                            emailOption !== "none" ?
                                <div className="col-12">
                                    <div className="time-seconds">
                                        <div className="row d-flex align-items-center mb-3 mt-3">
                                            <div className="col-12 col-sm-4">
                                                <p>Start Time:
                                                    <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                                        <span className="tooltiptext">When your CTA starts</span>
                                                    </div>
                                                </p>
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <div className="time-seconds-box">
                                                    <label className="my-1 mr-2 small" htmlFor="Minutes">Minutes</label>
                                                    <input min={0} value={startTime.minutes} onChange={(e) => handleStartTime(e)}
                                                           type="number" name="minutes" className="form-control my-1 mr-sm-2"/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <div className="time-seconds-box">
                                                    <label className="my-1 mr-2 small"
                                                           htmlFor="Seconds">Seconds</label>
                                                    <input min={0} max={60} value={startTime.seconds}
                                                           onChange={(e) => handleStartTime(e)}
                                                           type="number" name="seconds" className="form-control my-1 mr-sm-2"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-12 col-sm-4">
                                                <p>End Time:
                                                <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                                    <span className="tooltiptext">When your CTA ends</span>
                                                </div>
                                                </p>
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <div className="time-seconds-box">
                                                    <label className="my-1 mr-2 small" htmlFor="Minutes">Minutes</label>
                                                    <input value={endTime.minutes} min={0} type="number"
                                                           onChange={(e) => handleEndTime(e)}
                                                           name="minutes" className="form-control my-1 mr-sm-2"/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <div className="time-seconds-box">
                                                    <label className="my-1 mr-2 small"
                                                           htmlFor="Seconds">Seconds</label>
                                                    <input value={endTime.seconds} min={0} max={60} type="number"
                                                           onChange={(e) => handleEndTime(e)}
                                                           name="seconds" className="form-control my-1 mr-sm-2"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : ''
                        }
                    </div>
                </div>

                {
                    emailOption === 'email' ?
                        <>
                            <h5>EMAIL OPTIN OPTIONS:</h5>
                            <div className="cta-content-box bg-sec-box mb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="PlayButtonAnimation"
                                                   className="form-label d-flex justify-content-between mb-2">
                                                Autoresponder Campaign
                                            </label>
                                            <select name="auto_responder_campaign" onChange={(e) => handleEmail(e)}
                                                    className="form-select" aria-label="Default select example">
                                                <option selected disabled>Select an "Autoresponder Campaign"</option>
                                                {
                                                    optinData.length > 0 ?
                                                        optinData.map((item,index)=>(
                                                            <option key={index} value={item.id}>{item.campaign}</option>
                                                        ))
                                                     : ''
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="FacebookPixelCode"
                                                   className="form-label">Headline :  
                                                {/* <a data-toggle="tooltip"
                                                   title="It is headline text to show on optin screen"
                                                   className="tooltip-box"><i
                                                    className="fa fa-question-circle"
                                                    aria-hidden="true"/></a> */}
                                                <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                                    <span className="tooltiptext">It is headline text to show on optin screen</span>
                                                </div>
                                            </label>
                                            <textarea name="headline" onChange={(e) => handleEmail(e)}
                                                      className="form-control" id="YourText" rows="4"
                                                      placeholder="Your Headline"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label d-flex mb-2">
                                                Button Text
                                                {/* <a data-toggle="tooltip"
                                                   title="Give a name on button when CTA appears"
                                                   className="tooltip-box-left-auto ms-2"><i
                                                    className="fa fa-question-circle"
                                                    aria-hidden="true"/></a> */}
                                                <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                                    <span className="tooltiptext">Give a name on button when CTA appears</span>
                                                </div>
                                            </label>
                                            <input type="text" onChange={(e) => onInputBgColor(e.target.value, 'button_text')}
                                                   className="form-control" defaultValue={buttonStyle.button_text} placeholder="Your button text"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : ''
                }

                {
                    emailOption === 'button' ?
                        <>
                            <h5>BUTTON OPTIONS:</h5>
                            <div className="cta-content-box bg-sec-box mb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="YourText"
                                                   className="form-label d-flex mb-2">Headline
                                                {/* <a data-toggle="tooltip"
                                                   title="It is headline text to show on optin screen"
                                                   className="tooltip-box-left-auto ms-2">
                                                    <i className="fa fa-question-circle" aria-hidden="true"/>
                                                </a> */}
                                                <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                                    <span className="tooltiptext">It is headline text to show on optin screen</span>
                                                </div>
                                            </label>
                                            <textarea className="form-control" id="YourText"
                                                      onChange={(e) => handleButton(e)}
                                                      name="headline" rows="4" placeholder="Headline"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="YourUrl"
                                                   className="form-label d-flex mb-2">Button
                                                URL
                                                <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                                    <span className="tooltiptext">URL or link for button on optin screen</span>
                                                </div>
                                            </label>
                                            <input type="text" className="form-control" id="YourUrl"
                                                   onChange={(e) => handleButton(e)} name="button_url"
                                                   placeholder="Your Button URL"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label d-flex mb-2">
                                                Button Text
                                                <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                                                    <span className="tooltiptext">Give a name on button when CTA appears</span>
                                                </div>
                                            </label>
                                            <input type="text" onChange={(e) => onInputBgColor(e.target.value, 'button_text')}
                                                   className="form-control" defaultValue={buttonStyle.button_text} placeholder="Your button text"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : ''
                }


                {
                    emailOption !== "none" ?
                        <div className="cta-content-box bg-sec-box mb-4">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <div
                                        className="color-picker-box align-items-center d-flex justify-content-between mb-3">
                                        <div className="color-picker-box-text d-flex">
                                            <p>Background:</p>
                                        </div>
                                        <div className="color-picker-box-row d-flex">
                                            {
                                                bgStatus ?
                                                    <>
                                                        <div className="swatch-cover" onClick={() => setBgStatus(false)}/>
                                                        <SketchPicker disableAlpha={true} color={buttonStyle.bg_color}
                                                                      onChange={(e) => onInputBgColor(e.hex, 'bg_color')}/>
                                                    </>
                                                    : ''
                                            }
                                            <div onClick={() => setBgStatus(true)}
                                                 style={{backgroundColor: `${buttonStyle.bg_color}`}}
                                                 className="color-picker white cursor-pointer"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div
                                        className="color-picker-box align-items-center d-flex justify-content-between mb-3">
                                        <div className="color-picker-box-text d-flex">
                                            <p>Font Color:</p>
                                        </div>
                                        <div className="color-picker-box-row d-flex">
                                            {
                                                textStatus ?
                                                    <>
                                                        <div className="swatch-cover" onClick={() => setTextStatus(false)}/>
                                                        <SketchPicker disableAlpha={true} color={buttonStyle.text_color}
                                                                      onChange={(e) => onInputBgColor(e.hex, 'text_color')}/>
                                                    </>
                                                    : ''
                                            }
                                            <div onClick={() => setTextStatus(true)}
                                                 style={{backgroundColor: `${buttonStyle.text_color}`}}
                                                 className="color-picker red cursor-pointer"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div
                                        className="color-picker-box align-items-center d-flex justify-content-between mb-3">
                                        <div className="color-picker-box-text d-flex">
                                            <p>Button Gradient 1:</p>
                                        </div>
                                        <div className="color-picker-box-row d-flex">
                                            {
                                                color1Status ?
                                                    <>
                                                        <div className="swatch-cover" onClick={() => setColor1Status(false)}/>
                                                        <SketchPicker disableAlpha={true} color={buttonStyle.button_color_1}
                                                                      onChange={(e) => onInputBgColor(e.hex, 'button_color_1')}/>
                                                    </>
                                                    : ''
                                            }
                                            <div onClick={() => setColor1Status(true)}
                                                 style={{backgroundColor: `${buttonStyle.button_color_1}`}}
                                                 className="color-picker light-orange cursor-pointer"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div
                                        className="color-picker-box align-items-center d-flex justify-content-between mb-3">
                                        <div className="color-picker-box-text d-flex">
                                            <p>Button Gradient 2:</p>
                                        </div>
                                        <div className="color-picker-box-row d-flex">
                                            {
                                                color2Status ?
                                                    <>
                                                        <div className="swatch-cover" onClick={() => setColor2Status(false)}/>
                                                        <SketchPicker disableAlpha={true} color={buttonStyle.button_color_2}
                                                                      onChange={(e) => onInputBgColor(e.hex, 'button_color_2')}/>
                                                    </>
                                                    : ''
                                            }
                                            <div onClick={() => setColor2Status(true)}
                                                 style={{backgroundColor: `${buttonStyle.button_color_2}`}}
                                                 className="color-picker light-green cursor-pointer"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div
                                        className="color-picker-box align-items-center d-flex justify-content-between mb-3">
                                        <div className="color-picker-box-text d-flex">
                                            <p>Button Gradient 3:</p>
                                        </div>
                                        <div className="color-picker-box-row d-flex">
                                            {
                                                color3Status ?
                                                    <>
                                                        <div className="swatch-cover" onClick={() => setColor3Status(false)}/>
                                                        <SketchPicker disableAlpha={true} color={buttonStyle.button_color_3}
                                                                      onChange={(e) => onInputBgColor(e.hex, 'button_color_3')}/>
                                                    </>
                                                    : ''
                                            }
                                            <div onClick={() => setColor3Status(true)}
                                                 style={{backgroundColor: `${buttonStyle.button_color_3}`}}
                                                 className="color-picker pink cursor-pointer"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div
                                        className="color-picker-box align-items-center d-flex justify-content-between mb-3">
                                        <div className="color-picker-box-text d-flex">
                                            <p>Button Text Color:</p>
                                        </div>
                                        <div className="color-picker-box-row d-flex">
                                            {
                                                buttonStatus ?
                                                    <>
                                                        <div className="swatch-cover" onClick={() => setButtonStatus(false)}/>
                                                        <SketchPicker disableAlpha={true} color={buttonStyle.button_text_color}
                                                                      onChange={(e) => onInputBgColor(e.hex, 'button_text_color')}/>
                                                    </>
                                                    : ''
                                            }
                                            <div onClick={() => setButtonStatus(true)}
                                                 style={{backgroundColor: `${buttonStyle.button_text_color}`}}
                                                 className="color-picker brown cursor-pointer"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    : ''
                }
            </form>
            <div className="row mt-4">
                <div
                    className="col-12 d-flex align-items-center justify-content-end">
                    <p className="save-button-cta">Click the save button to add
                        CTA</p>
                    <a onClick={() => saveCtaData()}
                       className="btn btn-link btn-gradient-orange btn-small border-radius-circle" role="button">
                        Save
                    </a>
                </div>
            </div>
        </>
    )
}

export default React.memo(CallToAction);
