import axios from "axios";
import {baseURL} from "../global/global";

export const embedData = (data)=>(dispatch, getState)=>{
    axios({
        method: 'POST',
        url: `${baseURL}fetch-campaign`,
        data:{id: data},
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token }
    }).then(res=>{
        console.log(res.data.data);
        dispatch({type:'GET_EMBED', payload: res.data.data})
    }).catch(err=>{
        console.log(err);
    })
}

export const midAdTime = (data)=>(dispatch)=>{
    dispatch({type: "GET_MID TIME", payload: data});
}

export const adDuration = (data)=>(dispatch)=>{
    dispatch({type: "GET_DURATION", payload: data});
}
