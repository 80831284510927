import React, {useEffect, useState} from "react";
 import HelpSubContent from "./HelpSubContent";
import {useSelector} from "react-redux";
import searching_icon from "../../images/searching.png";
import addAcount_icon from "../../images/add-account.png";
import privacy_icon from "../../images/privacy-settings.png";
import connect_icon from "../../images/add-account.png";
import videos_icon from "../../images/videos.png";
import editor_icon from "../../images/editor.png";
import video_train from "../../images/video-training.png";


const HelpContent = (props) => {

    const supportData = useSelector(state => state.support);

    const [articlesData, setArticlesData] = useState(supportData.articles);
    const [q, setQ] = useState('');

    const onSearch = (e) => {
        setQ(e.target.value);
    }

    const search = (articles) => {
        return articles.filter(
            article => article.tags.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    }

    useEffect(()=>{
        setArticlesData(supportData.articles)
    },[supportData.articles])

    return(
        <>
            <section className="help-banner">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="help-banner-content">
                                <h3>What do you want <br/>
                                    <span className="highlight red">to Know?</span>
                                </h3>
                                <div className="help-search">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
									<span className="input-group-text" id="basic-addon1">
										<i className="fa fa-search" aria-hidden="true"></i>
									</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Search"
                                               onChange={(e)=>onSearch(e)}  aria-label="SearchPlaylish" aria-describedby="basic-addon1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="help-banner-img">
                                <img src={searching_icon} alt="Search"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="important-topics">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center mb-5">
                            <h3>Important Topics</h3>
                        </div>

                        <div className="col-md-10 mx-auto">
                            <div className="important-topics-icons">
                                <div className="row">
                                    {
                                        articlesData?.length > 0 ?
                                            search(articlesData).map((article, index)=>{
                                                return(
                                                    <HelpSubContent
                                                        name={article.name}
                                                        image={article.logo}
                                                        url={`/training?id=${article.id}`}
                                                        key={index}
                                                    />
                                                )
                                            })
                                            : ''
                                    }
                                    {
                                        props.loadArticles ?
                                            <div className="col-12">
                                                <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2"/></h4>
                                            </div>
                                            : ''
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HelpContent;
