import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";

import Footer from "../Footer";
import {useSelector} from "react-redux";
import TrainingTitles from "./TrainingTiles";
import TrainingContents from "./TrainingContent";
import TrainingBreadcrumbs from "./TrainingBreadcrumbs";

const Training = ({location}) => {
    const brandName = useSelector(state => state.rebrand.data);

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Training</title>
            </Helmet>

            <Navbar/>
            <section className="traning-sec">
                <div className="container">
                    <div className="row">
                        <TrainingBreadcrumbs location={location} name={false}/>
                    </div>
                    <div className="row">

                        <TrainingTitles location={location} name={false}/>
                        <TrainingContents location={location} />

                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Training;
