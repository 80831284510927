import React, {useState} from "react";

const AnalyticCode = ({index,id,item,inputAnalyticsData, removeMenuButtons}) => {

    const [status, setStatus] = useState(false);

    const removeIcon = () => {
        removeMenuButtons(index)
        setStatus(true);
    }

    const handleInputChange = (e) => {
        inputAnalyticsData(e.target.value, id);
    }

    return(
        <>
            <div className={`col-12 ${status ? 'hide-html' : ''}`}>
                <div className="form-group">
                    <label htmlFor="FacebookPixelCode" className="form-label" style={{width: "100%"}}>
                        Code:
                        
                        <div className="my_tooltip"><i className="fa fa-question-circle"aria-hidden="true"/>
                            <span className="tooltiptext">Get this code from Google or Facebook Pixel and paste here to <br/>track & analyze traffic on this player. You can add multiple codes.</span>
                        </div>

                        {/* <a data-toggle="tooltip" title="Get this code from Google or Facebook Pixel and paste here to track & analyze traffic on this player. You can add multiple codes." className="tooltip-box"><i className="fa fa-question-circle" aria-hidden="true"/></a> */}
                        <a onClick={()=>removeIcon()} className="border-radius-circle" role="button" style={{float: "right"}}>
                            <span><i className="fa fa-trash" aria-hidden="true"/></span>
                        </a>
                    </label>
                    <textarea value={item.value} className="form-control" onChange={(e)=>handleInputChange(e)} rows="4" placeholder="Add code to be placed before <body>"/>
                </div>
            </div>
        </>
    )
}


export default AnalyticCode;