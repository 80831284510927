import React, {useState} from "react";
import {useEffect} from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-overlay";
import "videojs-youtube";
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/forest/index.css';


import playReel from "../../../images/project1.jpg"
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    RedditShareButton,
    LinkedinIcon,
    TwitterIcon,
    RedditIcon,

} from "react-share";
import ButtonDisplay from "../menu/ButtonDisplay";
import {baseURL, feUrl} from "../../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {midAdsTime} from "../../../actions/adsAction";
import axios from "axios";
import {adDuration} from "../../../actions/embedAction";


export const VideoJS = (props) => {

    const videoRef = React.useRef(null);
    const dispatch = useDispatch();

    const playerRef = React.useRef(null);
    let currentTimePlus = 0;

    const {
        options,
        onReady,
        logo,
        change,
        browser,
        duration,
        color,
        control,
        vidType,
        infoType,
        displayicon,
        resolution,

        thumb,
        id,
        relatedVid,
        right_click,
        title,
        description,
        poster,
        url,
        menu_buttons,
        cta_type,
        start,
        end,
        cta,
        skin,
        loop,
        playlistData,
        countdown,
        embed,
        preType,
        midType,
        postType
    } = props;

    let cta_Type = cta_type;
    let start_time = Number(start);
    let end_time = Number(end);
    let cta_bg_color = cta.bg_color;
    let cta_heading = cta.headline;
    let cta_textColor = cta.text_color;
    let cta_buttonUrl = cta.button_url;
    let cta_buttonTextColor = cta.button_text_color;
    let cta_button_color1 = cta.button_color_1;
    let cta_button_color2 = cta.button_color_2;
    let cta_button_color3 = cta.button_color_3;
    let cta_btntext = cta.button_text;
    let time = countdown.time;
    let counterDate = countdown.date;
    let timeZone = countdown.timezone;
    let counterSkin = countdown.skin

    const [showRel, setShowRel] = useState("hidden");
    const [durations, setDurations] = useState(0);

    const showRelatedVid = () => {
        if (showRel === "hidden") {
            setShowRel("visible")
        } else {
            setShowRel("hidden");
        }
    }


    const getShare = () => {
        axios({
            method: "POST",
            url: `${baseURL}engagement`,
            data: {
                id: id,
                shares: 1
            },
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if (res.data.status === true) {
                console.log(res.data.status);
            }
        })
    }


    useEffect(() => {
        // make sure VideoJS.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, change, () => {
                onReady && onReady(player);
            });

            player.ads();

        } else {
            const player = playerRef.current;
            player.poster(thumb);
            player.loop(loop);


            const overlay_content =
                `<div class="row">
                     <div class="text-truncate" id="infoAds">
                     <a href=${infoType === undefined || infoType === null ? '#' : infoType.url} target="_blank">${infoType === null || infoType === undefined ? ' ' : infoType.text}</a>
                     </div>
                 </div>`

            if (control.resize === true) {
                player.controlBar.fullscreenToggle.hide();
            } else {
                player.controlBar.fullscreenToggle.show();
            }
            if (control.play === true) {
                player.controlBar.playToggle.hide();
            } else {
                player.controlBar.playToggle.show();
            }
            if (control.volume === true) {
                player.controlBar.volumePanel.hide();

            } else {
                player.controlBar.volumePanel.show();
            }

            if (control.progressBar === true) {
                player.controlBar.progressControl.hide();
                player.controlBar.remainingTimeDisplay.hide();
            } else {
                player.controlBar.progressControl.show();
                player.controlBar.remainingTimeDisplay.show();

            }
            if (control.speed === true) {
                player.controlBar.playbackRateMenuButton.hide();
            } else {
                player.controlBar.playbackRateMenuButton.show();
            }

            if (browser !== "Firefox") {
                if (control.pip === true) {
                    player.controlBar.pictureInPictureToggle.hide();
                } else {
                    player.controlBar.pictureInPictureToggle.show();
                }
            } else {
                console.log('')
            }


            //mid-ads start

            if (midType!== undefined || midType!==null){

                player.on('contentupdate', function () {
                    player.trigger('adsready');
                });

                player.on('timeupdate', function () {
                    let skipAds = document.querySelector('.video-js #adSkip');
                    let currentTime = player.currentTime() + currentTimePlus;
                    if (Math.round(currentTime) === duration) {
                        let skipAds = document.querySelector('.video-js #adSkip');
                        player.loop(false);

                        if (logo !== "") {
                            let hideLogo = document.querySelector('.video-js .logo_size');
                            hideLogo.style.display = "none"
                        }
                        if (infoType !== undefined || infoType !== null) {
                            let infoads = document.querySelector('.video-js #infoAds');
                            infoads.style.display = "none"
                        }
                        let socialShare = document.querySelector('.video-js .social-share-btns');
                        socialShare.style.display = "none";
                        if (relatedVid === true) {
                            let relateVid = document.querySelector('.video-js .related-vid');
                            relateVid.style.display = "none";
                        }
                        /*if (menu_buttons !== null) {
                            let menuBtn = document.querySelector('.video-js .videojs-row-box');
                            menuBtn.style.display = "none";
                        }*/
                        if (logo !== "") {
                            let hideLogo = document.querySelector('.video-js .logo_size');
                            hideLogo.style.display = "none"
                        }

                        if (midType !== null || midType !== undefined) {
                            skipAds.style.display = "block"

                            let adtitle = document.querySelector('.video-js #adTitle');
                            adtitle.style.display = "block";
                        };

                        player.ads.startLinearAdMode();
                        player.src(`${midType.video_url}`);
                        player.one('adplaying', function () {
                            player.trigger('ads-ad-started');
                        });

                    }
                    if(midType !==null || midType !==undefined){
                        skipAds.addEventListener('click', function () {
                            currentTimePlus = 2;
                            player.ads.endLinearAdMode();
                            currentTimePlus = 2;
                            player.loop(loop);

                            let adTitle = document.querySelector('.video-js #adTitle');
                            adTitle.style.display = "none";
                            if (logo !== "") {
                                let hideLogo = document.querySelector('.video-js .logo_size');
                                hideLogo.style.display = "block"
                            }

                            let skipAds = document.querySelector('.video-js #adSkip');
                            skipAds.style.display = "none"

                            let socialShare = document.querySelector('.video-js .social-share-btns');
                            socialShare.style.display = "block";
                            if (relatedVid === true) {
                                let relateVid = document.querySelector('.video-js .related-vid');
                                relateVid.style.display = "block";
                            }
                            /* if (menu_buttons.length > 0) {
                                 let menuBtn = document.querySelector('.video-js .videojs-row-box');
                                 menuBtn.style.display = "block";
                             }*/

                            if (infoType !== undefined || infoType !== null) {
                                let infoads = document.querySelector('.video-js #infoAds');
                                infoads.style.display = "block"
                            }

                        });
                    }


                    player.one('adended', function () {
                        currentTimePlus = 2;
                        player.ads.endLinearAdMode();
                        currentTimePlus = 2;
                        player.loop(loop);

                        let adTitle = document.querySelector('.video-js #adTitle');
                        adTitle.style.display = "none";

                        if (logo !== "") {
                            let hideLogo = document.querySelector('.video-js .logo_size');
                            hideLogo.style.display = "block"
                        }
                        let skipAds = document.querySelector('.video-js #adSkip');
                        skipAds.style.display = "none"

                        let socialShare = document.querySelector('.video-js .social-share-btns');
                        socialShare.style.display = "block";
                        let relateVid = document.querySelector('.video-js .related-vid');
                        relateVid.style.display = "block";
                        let menuBtn = document.querySelector('.video-js .videojs-row-boxTest');
                        menuBtn.style.display = "block";
                        if (logo !== "") {
                            let hideLogo = document.querySelector('.video-js .logo_size');
                            hideLogo.style.display = "block"
                        }
                        if (infoType !== undefined || infoType !== null) {
                            let infoads = document.querySelector('.video-js #infoAds');
                            infoads.style.display = "block"
                        }
                    });

                });

            }


            //pre-ads start
            if (preType !== undefined) {
                player.on('contentchanged', function () {

                    player.trigger('adsready');
                });

                player.on(`readyforpreroll`, function () {

                    player.ads.startLinearAdMode();
                    player.src(`${preType.video_url}`);

                    let adtitle = document.querySelector('.video-js #adTitle');
                    adtitle.style.display = "block";
                    let skipAds = document.querySelector('.video-js #adSkip');
                    skipAds.style.display = "block"
                    if (logo !== "") {
                        let hideLogo = document.querySelector('.video-js .logo_size');
                        hideLogo.style.display = "none"
                    }

                    if (infoType !== undefined || infoType !== null) {
                        let infoads = document.querySelector('.video-js #infoAds');
                        infoads.style.display = "none"
                    }

                    /*if (menu_buttons.length > 0) {
                        let menuBtn = document.querySelector('.video-js .videojs-row-box');
                        menuBtn.style.display = "none";
                    }*/

                    let socialShare = document.querySelector('.video-js .social-share-btns');
                    socialShare.style.display = "none";
                    if (relatedVid === true) {
                        let relateVid = document.querySelector('.video-js .related-vid');
                        relateVid.style.display = "none";
                    }

                    player.loop(false);

                    player.one('adplaying', function () {
                        player.trigger('ads-ad-started');
                    });

                    skipAds.addEventListener('click', function () {
                        player.ads.endLinearAdMode();
                        let adtitle = document.querySelector('.video-js #adTitle');
                        adtitle.style.display = "none";
                        let skipAds = document.querySelector('.video-js #adSkip');
                        skipAds.style.display = "none"
                        player.loop(loop);


                        if (logo !== "") {
                            let hideLogo = document.querySelector('.video-js .logo_size');
                            hideLogo.style.display = "block"
                        }

                        let socialShare = document.querySelector('.video-js .social-share-btns');
                        socialShare.style.display = "block";
                        if (relatedVid === true) {
                            let relateVid = document.querySelector('.video-js .related-vid');
                            relateVid.style.display = "block";
                        }
                        /*if (menu_buttons.length > 0) {
                            let menuBtn = document.querySelector('.video-js .videojs-row-box');
                            menuBtn.style.display = "block";
                        }*/
                        if (logo !== "") {
                            let hideLogo = document.querySelector('.video-js .logo_size');
                            hideLogo.style.display = "block"
                        }

                        if (infoType !== undefined || infoType !== null) {
                            let infoads = document.querySelector('.video-js #infoAds');
                            infoads.style.display = "block"
                        }


                    })


                    player.one('adended', function () {
                        player.ads.endLinearAdMode();
                        let adtitle = document.querySelector('.video-js #adTitle');
                        adtitle.style.display = "none";
                        player.loop(loop);


                        if (logo !== "") {
                            let hideLogo = document.querySelector('.video-js .logo_size');
                            hideLogo.style.display = "block"
                        }


                        let skipAds = document.querySelector('.video-js #adSkip');
                        skipAds.style.display = "none"

                        let socialShare = document.querySelector('.video-js .social-share-btns');
                        socialShare.style.display = "block";
                        let relateVid = document.querySelector('.video-js .related-vid');
                        relateVid.style.display = "block";
                        let menuBtn = document.querySelector('.video-js .videojs-row-boxTest2');
                        menuBtn.style.display = "block";
                        if (logo !== "") {
                            let hideLogo = document.querySelector('.video-js .logo_size');
                            hideLogo.style.display = "block"
                        }
                        if (infoType !== undefined || infoType !== null) {
                            let infoads = document.querySelector('.video-js #infoAds');
                            infoads.style.display = "block"
                        }

                    });
                });

                player.trigger('adsready');
            }


            //pre-ads end here

            //post-ads start here

            if (postType !== null || postType !== undefined) {
                player.on('contentchanged', function () {

                    player.trigger('adsready');
                });

                player.on(`readyforpostroll`, function () {
                    player.ads.startLinearAdMode();
                    player.src(`${postType.video_url}`);
                    let skipAds = document.querySelector('#adSkip');


                    if (logo !== "") {
                        let hideLogo = document.querySelector('.video-js .logo_size');
                        hideLogo.style.display = "none"
                    }
                    if (infoType !== undefined || infoType !== null) {
                        let infoads = document.querySelector('.video-js #infoAds');
                        infoads.style.display = "none"
                    }
                    let socialShare = document.querySelector('.video-js .social-share-btns');
                    socialShare.style.display = "none";
                    if (relatedVid === true) {
                        let relateVid = document.querySelector('.video-js .related-vid');
                        relateVid.style.display = "none";
                    }
                    /* if (menu_buttons !== null) {
                         let menuBtn = document.querySelector('.video-js .videojs-row-box');
                         menuBtn.style.display = "none";
                     }*/
                    if (logo !== "") {
                        let hideLogo = document.querySelector('.video-js .logo_size');
                        hideLogo.style.display = "none"
                    }

                    player.loop(false);

                    if (postType.video_url !== null || postType.video_url !== undefined) {
                        skipAds.style.display = "block"

                        let adtitle = document.querySelector('.video-js #adTitle');
                        adtitle.style.display = "block";
                    }

                    player.one('adplaying', function () {
                        player.trigger('ads-ad-started');
                    });

                    skipAds.addEventListener('click', function () {
                        let adtitle = document.querySelector('.video-js #adTitle');
                        adtitle.style.display = "none";
                        player.ads.endLinearAdMode();
                        player.loop(loop);


                        if (logo !== "") {
                            let hideLogo = document.querySelector('.video-js .logo_size');
                            hideLogo.style.display = "block"
                        }


                        let socialShare = document.querySelector('.video-js .social-share-btns');
                        socialShare.style.display = "block";
                        if (relatedVid === true) {
                            let relateVid = document.querySelector('.video-js .related-vid');
                            relateVid.style.display = "block";
                        }
                        /*if (menu_buttons !== null) {
                            let menuBtn = document.querySelector('.video-js .videojs-row-box');
                            menuBtn.style.display = "block";
                        }*/
                        if (logo !== "") {
                            let hideLogo = document.querySelector('.video-js .logo_size');
                            hideLogo.style.display = "block"
                        }
                        if (infoType !== undefined || infoType !== null) {
                            let infoads = document.querySelector('.video-js #infoAds');
                            infoads.style.display = "block"
                        }

                    })


                    player.one('adended', function () {
                        let adtitle = document.querySelector('.video-js #adTitle');
                        adtitle.style.display = "none";
                        player.ads.endLinearAdMode();
                        player.loop(loop);

                        if (logo !== "") {
                            let hideLogo = document.querySelector('.video-js .logo_size');
                            hideLogo.style.display = "block"
                        }
                        if (infoType !== undefined || infoType !== null) {
                            let infoads = document.querySelector('.video-js #infoAds');
                            infoads.style.display = "block"
                        }

                        let socialShare = document.querySelector('.video-js .social-share-btns');
                        socialShare.style.display = "block";
                        let relateVid = document.querySelector('.video-js .related-vid');
                        relateVid.style.display = "block";
                        let menuBtn = document.querySelector('.video-js .videojs-row-boxTest3');
                        menuBtn.style.display = "block";

                    });
                });

                player.trigger('adsready');

            }


            //post-ads end here


            let overlay = "";

            if (cta_type === "email") {

                overlay = `<div class="cta_content" style="background-color: ${cta_bg_color}">
                           <h2 class="cta_heading" style="color: ${cta_textColor}">${cta_heading}</h2>
                                <input type="text" class="form-control" id="subName" placeholder="Name" style="width: 80%; margin: auto" value=""/> <br>
                                <input type="email" class="form-control" id="subMail" placeholder="Enter Your Email" style="width: 80%; margin: auto" > <br>
                                <button type="submit" id="getValue" class="gradiant-button-pb connect-btn"  style="background-image: linear-gradient(${cta_button_color1}, ${cta_button_color2}, ${cta_button_color3})"  >${cta_btntext}</button>
                           </div>`

            } else if (cta_type === "button") {

                overlay = `<div class="cta_content" style="background-color: ${cta_bg_color}">
                            <h2 class="cta_heading" style="color: ${cta_textColor}">${cta_heading}</h2>
                            <a target='_blank' href=${cta_buttonUrl} class="gradiant-button-pb connect-btn" style="background-image: linear-gradient(${cta_button_color1}, ${cta_button_color2}, ${cta_button_color3}); width: 30%; margin: auto; word-break: break-word">${cta_btntext}</a>
                           </div>`
            }


            player.overlay({
                overlays: [

                    {
                        content: overlay,
                        class: "overlayBtn",
                        start: start_time,
                        end: end_time,
                        showBackground: true,
                    },
                    {
                        content: overlay_content,
                        class: "overlaycss",
                        start: 0,
                        end: "end",
                        showBackground: true,
                        align: "top-left",
                    }

                ]
            })


            if (cta_type === "email") {
                let subClick = document.querySelector('#getValue');
                subClick.addEventListener('click', function () {
                    let name = document.getElementById('subName').value;
                    let email = document.getElementById('subMail').value;
                    let player_id = id;


                    let xhttp = new XMLHttpRequest();
                    xhttp.open("POST", "https://backend.playerneos.com/subscribe-channel", true);
                    xhttp.setRequestHeader("Content-Type", "application/json");
                    xhttp.onreadystatechange = function () {
                        if (this.readyState == 4 && this.status == 200) {
                            // Response
                            var response = this.responseText;
                            console.log(response)
                        }
                    };
                    let data = {name: name, email: email, id: player_id};
                    xhttp.send(JSON.stringify(data));
                })
            }


        }


    }, [options, videoRef, change]);


    // Dispose the VideoJS.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);


    useEffect(() => {

    }, [options, onReady])


    useEffect(() => {
        let subBtn = document.getElementById('getValue');

    }, [])


    return (
        <>

            <div data-vjs-player className='popup_inner' id="featured-media">


                {/* {
                menu_buttons?.length > 0 ? menu_buttons.map((content, index) => (
                    <ButtonDisplay key={index} content={content} skin={skin}/>
                )) : ""
            }*/}

                {
                    menu_buttons?.length > 0 ?
                        <div className="edit-videojs-row-box">
                            {
                                menu_buttons.map((content, index) => (
                                    <ButtonDisplay key={index} content={content} skin={skin}/>
                                ))
                            }
                        </div>
                        : ''
                }

                <div className="social-share-btns">
                    <ul>
                        <li>
                            <FacebookShareButton url={`${feUrl}embed/${id}`}
                                                 title={title}
                                                 hashtag={"#hashtag"}
                                                 description={description}>
                                <FacebookIcon size={32} round style={{display: displayicon.fbIcon}}
                                              onClick={() => getShare()}/>
                            </FacebookShareButton>
                        </li>

                        <li>
                            <RedditShareButton url={`${feUrl}embed/${id}`}
                                               title={title}
                                               hashtag={"#hashtag"}
                                               description={description}>
                                <RedditIcon size={32} round style={{display: displayicon.instaIcon}}
                                            onClick={() => getShare()}/>
                            </RedditShareButton>
                        </li>
                        <li>
                            <TwitterShareButton url={`${feUrl}embed/${id}`}
                                                title={title}
                                                hashtag={"#hashtag"}
                                                description={description}>
                                <TwitterIcon size={32} round style={{display: displayicon.twitterIcon}}
                                             onClick={() => getShare()}/>
                            </TwitterShareButton>
                        </li>
                        <li>
                            <LinkedinShareButton url={`${feUrl}embed/${id}`}
                                                 title={title}
                                                 hashtag={"#hashtag"}
                                                 description={description}>
                                <LinkedinIcon size={32} round style={{display: displayicon.linkedIcon}}
                                              onClick={() => getShare()}/>
                            </LinkedinShareButton>
                        </li>

                    </ul>
                </div>

                <video ref={videoRef} className="video-js vjs-theme-city" data-effect="mfp-zoom-in"
                       id="featured-video"/>

                {preType !== null || midType !== null || postType !== null ?

                    <div id="adTitle"><h4>Advertisement</h4></div>


                    : ""}

                {preType !== null || midType !== null || postType !== null ?
                    <div id="adSkip"><h4>Skip</h4></div>
                    : ""}

                {relatedVid === true ? (
                    <div className="related-vid" onClick={() => showRelatedVid()} style={{fontSize: "1.7rem"}}>
                        <button type="button" className="close" aria-label="Close">
                        <span aria-hidden="true" className="relVid">
                            {
                                showRel === 'hidden' ?
                                    <i className="fa fa-info-circle " data-toggle="tooltip"
                                       data-placement="left" title="Related Videos" aria-hidden="true"></i>
                                    : <i className="fa fa-times-circle" aria-hidden="true"></i>
                            }
                           </span>
                        </button>
                    </div>
                ) : (
                    ""
                )}

                <div className="vjs-grid" style={{visibility: showRel}}>
                    {playlistData?.length > 0
                        ? playlistData.slice(0, 4).map((item, i) => (
                            <>
                                <div className="rel-item">
                                    <a target="_blank" href={`/embed/${item.id}`}>
                                        {" "}
                                        <div
                                            className="rel-bg"
                                            style={{
                                                backgroundImage: `URL(${item.thumbnail === "" ? playReel : item.thumbnail})`,
                                            }}
                                        />
                                    </a>
                                </div>
                            </>
                        ))
                        : ""
                    }
                </div>

                {
                    logo === "" ? '' : <div className={`logo_size ${logo.position}`}><img src={logo.file}/></div>
                }


            </div>


        </>
    );
}

export default VideoJS;
