

import React, {useEffect, useState} from "react";
import "./countdown.css";

const Countdown = (props) => {

    const {startDate, skin} = props;

    let countdownId = null;
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [expired, setExpired] = useState(false);


    const timerInit = () => {
        const now = new Date().getTime();
        if (!startDate) {
            setExpired(true);
            return;
        }
        const countDownStartDate = new Date(startDate).getTime();
        const distance = countDownStartDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // For countdown is finished
        if (distance < 0) {
            clearInterval(countdownId);
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
            setExpired(true);

            return;
        }
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
        setExpired(false);
    };




    useEffect(() => {
        countdownId = setInterval(() => {
            timerInit()
        }, 1000);

        return () => {
            if (countdownId) {
                clearInterval(countdownId)
            }
        }
    }, [days, hours, minutes, seconds, startDate]);

    return (
        <>
        {
            expired===true ? "" :
                <div id="countDown-cont" style={{position: "relative", display: "block", opacity: "1"}}>
                    <div id="vidCdowntimer" className="countdownItem" cdown-skin={`cdown-${skin.skin}`}
                         style={{textAlign: "center"}}>
                        <div id="days" className="cdown-element"><span className="count">{days}</span><span
                            className="text">days</span></div>
                        <div id="hours" className="cdown-element"><span className="count"> {hours}</span><span
                            className="text">hours</span></div>
                        <div id="minutes" className="cdown-element"><span className="count">{minutes}</span><span
                            className="text">minutes</span></div>
                        <div id="seconds" className="cdown-element"><span className="count"> {seconds}</span><span
                            className="text">seconds</span></div>
                    </div>
                </div>
        }
        </>
    )

}
export default Countdown;
