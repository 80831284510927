import React, {useState} from "react";
import {useDispatch} from "react-redux";
import randomstring from "randomstring";
import AdsContent from "./AdsContent";
import MenuButton from "./MenuButton";
import {saveAdsData} from "../../../actions/currentCampaignAction";
import {midAdsTime} from "../../../actions/adsAction";
import {midAdTime} from "../../../actions/embedAction";

const Ads = () => {

    const dispatch = useDispatch();


    const [initialData, setInitialData] = useState({
        id: randomstring.generate({length: 9, charset : 'numeric'}),
        type: 'pre',
        file_type: 'video',
        video_url: '',
        thumbnail: '',
        text: '',
        url: ''

    })
    const [adsCount, setAdsCount] = useState([
        initialData
    ])

    const addMoreAds = () => {
        let obj = {
            id: randomstring.generate({length: 9, charset : 'numeric'}),
            type: 'pre',
            file_type: 'video',
            video_url: '',
            thumbnail: '',
            text: '',
            url: ''
        }
        let arr = [...adsCount];
        arr.push(obj)

        setAdsCount(arr);
    }

    function search(nameKey, myArray){
        for (let i=0; i < myArray.length; i++) {
            if (myArray[i].id === nameKey) {
                return i;
            }
        }
    }

    const inputAdsData = (value, id, type) => {
        let index = search(id, adsCount);

        let newTrans = [...adsCount];

        if(type === 'type')
            newTrans[index].type = value
        else if(type === 'file_type')
            newTrans[index].file_type = value
        else if(type === 'video_url')
            newTrans[index].video_url = value
        else if(type === 'thumbnail')
            newTrans[index].thumbnail = value
        else if(type === 'text')
            newTrans[index].text = value
        else if(type === 'url')
            newTrans[index].url = value
        else if(type === 'remove_thumbnail') {
            newTrans[index].video_url = ''
            newTrans[index].thumbnail = ''
        }

        setAdsCount(newTrans);
    }

    const removeAdsButtons = (index) => {
        let arr = [...adsCount];
        arr.splice(index,1);

        setAdsCount(arr);
    }

    const handleSave = () => {
        dispatch(saveAdsData(adsCount));
        if(initialData.type==='mid'){
            let durationTime = document.querySelector('.video-js .vjs-duration .vjs-duration-display').innerHTML;
            dispatch(midAdsTime(durationTime));
            dispatch(midAdTime(durationTime));
          }
    }



    return(
        <>
            {
                adsCount.length > 0 ?
                    adsCount.map((item, index)=>(
                        <AdsContent key={item.id} index={index} id={item.id} inputAdsData={inputAdsData} removeAdsButtons={removeAdsButtons} thumbnail={item.thumbnail}  />
                    ))
                : ''
            }
            <div className="row mt-4">
                <div className="col-12 d-md-flex justify-md-content-end justify-content-center text-center text-md-right">
                    <a onClick={()=>addMoreAds()} className="btn btn-link btn-gradient-orange btn-small border-radius-circle" role="button">
                        <span><i className="fa fa-plus-circle" aria-hidden="true"/></span> Add More
                    </a>
                    <a onClick={()=>handleSave()} className="btn btn-link btn-gradient-purple btn-small border-radius-circle" role="button">
                        <span><i className="fa fa-film" aria-hidden="true"/></span> Save
                    </a>
                </div>
            </div>
        </>
    )
}

export default React.memo(Ads);
