import React, {useEffect, useState} from "react";
import analyticsImg from "../../images/analytics.png";
import Navbar from "../Navbar";
import Footer from "../Footer";
import queryString from "query-string";
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";
import {Link} from "react-router-dom";
import TablePagination from "./Pagination";
import Pagination from "./Pagination";
import ReactPaginate from "react-paginate";
import {Helmet} from "react-helmet";


const Engagement = ({location}) => {
    const brandName = useSelector(state => state.rebrand.data);
    const id = queryString.parse(location.search).id;

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [loadData, setLoadData] = useState(false);
    const [loader, setLoader] = useState(false);
    const [searchData, setSearchData] = useState({
        id: id,
        start: '',
        end: ''
    })
    const [dataCount, setDataCount] = useState({
        stick_length: 0,
        disengagement: 0,
        clicks: 0,
        subscriptions: 0,
        shares: 0,
        downloads: 0
    })


    const [players, setPlayers] = useState([]);
    const [durationTime, setDurationTime] = useState('all');

    const selectDuration = (e) => {
        setDurationTime(e.target.value)

        fetchEngage(e.target.value);
    }

    const handleChange = (e) => {
        setSearchData({...searchData, [e.target.name]: e.target.value});
    }

    const fetchEngage = async (type = '') => {
        setLoadData(true);

        if (id === undefined) {
            searchData.user_id = auth.user.id;
        }

        if (type !== '') {
            searchData.type = type;
        }

        axios({
            method: "POST",
            url: `${baseURL}fetch-engagement`,
            data: searchData,
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                setDataCount(res.data.data);


                if (id === undefined) {
                    setPlayers(res.data.players);
                    receivedData(res.data.players, 0, 10);
                }

            } else {
                // dispatch(setAlert(res.data.message,'danger'));
            }

            setLoader(false);
            setLoadData(false);
        }).catch(err => {
            setLoader(false);
            setLoadData(false);

            dispatch(setAlert('Something went wrong, Please try again', 'danger'));
        })
    }

    const handleFilter = (e) => {
        e.preventDefault();
        setLoader(true);

        fetchEngage();
    }


    const [offset, setOffset] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0)
    const [postData, setPostData] = useState([]);

    const receivedData = (data, offset, perPage) => {

        const slice = data.slice(offset, offset + perPage);

        setPageCount(Math.ceil(data.length / perPage));
        let tempData = slice.map(item =>
            <>
                <tr>
                    <th scope="row">{item['name'] !== null ? item['name'] : 'N/A'}</th>
                    <td>{item['stick_length']}</td>
                    <td>{item['disengagement']}</td>
                    <td>{item['clicks']}</td>
                    <td>{item['subscriptions']}</td>
                    <td>{item['shares']}</td>
                     <td>
                        <Link to={`/engagement?id=${item.player_id}`}
                              className="btn btn-gradient-orange">View</Link>
                    </td>
                </tr>
            </>
        )

        setPostData(tempData);

    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffset(offset);
        receivedData(players, offset, 10);
        console.log(postData)

    }


    useEffect(() => {
        fetchEngage();
    }, [id]);

    useEffect(() => {
        receivedData(players, 0, 10);
    }, [])

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Engagement </title>
            </Helmet>
            <Navbar/>
            <Alert/>
            <div id="Analytics" className="analytics">
                <div className="container">
                    <div>
                        <div className="row d-flex justify-content-center mt-4">
                            <div className="col-md-12 col-12">
                                <h3><span className="orange">Engagement</span></h3>
                                <div className="content-box content-box-bg-black date-range">
                                    <div className="row mt-5">
                                        <div className="col-lg-5 col-12">
                                            <div className="form-group col-md-12 p-0">
                                                <label for="Duration">Duration</label>
                                                <div className="input-group">
                                                    <select id="Duration" className="form-control select-custom w-100"
                                                            onChange={(e) => selectDuration(e)}>
                                                        <option value={"all"} selected>All Time</option>
                                                        <option value={24} selected>Daily</option>
                                                        <option value={168} selected>Weekly</option>
                                                        <option value={672} selected>Monthly</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <form method="post" onSubmit={(e) => handleFilter(e)}
                                              className="offset-lg-1 col-lg-6 col-12">
                                            <div className="form-row">
                                                <div className="form-group col-md-5">
                                                    <label for="From">From</label>
                                                    <div className="input-group">
                                                        <input onChange={(e) => handleChange(e)} name="start"
                                                               type="date" className="form-control" id="from"/>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label for="To">To</label>
                                                    <div className="input-group">
                                                        <input onChange={(e) => handleChange(e)} type="date"
                                                               className="form-control" name="end" id="To"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    {/* <label for="search-text" className="">sadsad</label> */}
                                                    <button disabled={loader} type="submit"
                                                            className="btn btn-bundle primary-gradient btn-duration-submit">
                                                        {loader ? <i className="fa fa-spinner fa-spin "/> : ''} Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="content-box content-box-bg-dark-gray analytics-box">
                                    <div className="row">

                                        <div className="col-12 col-lg-4 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Stick Length</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : dataCount.stick_length}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Disengagement</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : dataCount.disengagement}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Clicks</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : dataCount.clicks}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Subscriptions</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : dataCount.subscriptions}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-4">
                                            <div
                                                className="analytics-box-row d-flex align-items-center d-flex justify-content-between">
                                                <div className="analytics-box-row-text">
                                                    <p>Shares</p>
                                                    <p className="number-views">
                                                        {loadData ?
                                                            <i className="fa fa-spinner fa-spin "/> : dataCount.shares}
                                                    </p>
                                                </div>
                                                <div className="analytics-box-row-img">
                                                    <img src={analyticsImg} alt="Analytics" className="analyticsImg"/>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            id === undefined ?
                                <div className="content-bor20 content-box-bg-dark-gray my-5">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-12 col-12">
                                            <div className="analytics-table">
                                                <div className="table-box">
                                                    <table className="table table-borderless">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Stick Length</th>
                                                            <th scope="col">Disengagement</th>
                                                            <th scope="col">Clicks</th>
                                                            <th scope="col">Subscriptions</th>
                                                            <th scope="col">Shares</th>
                                                             <th scope="col">Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            players?.length > 0 ?

                                                                postData
                                                                : ''
                                                        }
                                                        <hr/>
                                                        </tbody>
                                                    </table>

                                                    <ReactPaginate
                                                        previousLabel={"prev"}
                                                        nextLabel={"next"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }


                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}


export default Engagement;
