import React, {useEffect} from "react";
import VideoJS from "./VideoJS";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import "videojs-overlay";
import videojs from "video.js";
import axios from "axios";
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm';
import "videojs-vimeo-tech/dist/Vimeo.min";
import {baseURL} from "../../../global/global";
 import {midAdsTime} from "../../../actions/adsAction";
 import "videojs-contrib-ads"
require('@silvermine/videojs-quality-selector')(videojs);
require('@silvermine/videojs-quality-selector/dist/css/quality-selector.css');

const Preview = ({currentLayer, setVidDuration}) => {
    const {
        thumbnail,
        id,
        url,
        resolution,
        ads_data,
        logo,
        video_type,
        social_share,
        title,
        description,
        poster,
        cta_type,
        menu_buttons,
        start_time,
        end_time,
        cta_data,
        skin,
        controls,
        playlist_id,
        related_videos,
        loop,
        autoplay,
        countdown,
        embed_type,
        right_click
    } = currentLayer;

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const midsTime = useSelector(state => state.currentCampaign.data.mid_time);
    const midsTimes = useSelector(state => state.embedVid.mid_time);


    const typeVideo = ['other', 'import'];


    const playerRef = React.useRef(null);

    const [thumb] = useState(thumbnail);
    const [infoType, setInfoType] = useState(null);
    const [preType, setPreType] = useState(null);
    const [midType, setMidType] = useState(null);
    const [postType, setPostType] = useState(null);
    const [videoUrl, setVideoURL] = useState(url);
    const [vid360, setVid360] = useState();
    const [vid480, setVid480] = useState();
    const [vid720, setVid720] = useState();
    const [vid1080, setVid1080] = useState();
    const [vid_type, setVid_type] = useState(`video/${typeVideo.includes(video_type) ? 'mp4' : video_type}`);
    const [displayIcon, setDisplayIcon] = useState({
        fbIcon: 'none',
        instaIcon: 'none',
        twitterIcon: 'none',
        linkedIcon: 'none'
    });
    const [browser, setBrowser] = useState('')
    const [playlistData, setPlaylistData] = useState([]);


    const getPlayVideos = (playlist_id) => {
        if (related_videos === true) {
            axios({
                method: "POST",
                url: `${baseURL}fetch-related-videos`,
                data: {id: playlist_id, video_id: id},
                headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
            }).then(res => {
                if (res.data.status === true) {
                    /*
                                    dispatch(setAlert(res.data.message, "success"));
                    */
                    setPlaylistData(res.data.data);

                } else {
                    /*
                                    dispatch(setAlert(res.data.message, "danger"));
                    */
                }

            }).catch(err => {

            })
        }

    }


    const [playBtn, setPlayBtn] = useState(controls.icon);
    const [autoPlay, setautoPlay] = useState(autoplay);
    const [muteVid, setMuteVid] = useState(autoPlay);
    const setAutoPlay = () => {
        if (autoplay === true) {
            setautoPlay("muted");
            setMuteVid(true)


        } else {
            setautoPlay(false);
            setMuteVid(false)

        }
    }


    const vidType = () => {
        if (video_type === "other") {
            setVid_type("video/mp4");
        }
    }

    const videoJsOptions = {
        autoplay: autoPlay,
        muted: muteVid,
        controls: true,
        responsive: true,
        fluid: true,
        poster: thumb,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [
            {
                src: vid360,
                type: vid_type,
                label: "1080",
                res: 1080
            },
            {
                src: vid480,
                type: vid_type,
                label: "720",
                res: 720
            },
            {
                src: vid720,
                type: vid_type,
                label: "480",
                res: 480
            }, {
                src: vid1080,
                type: vid_type,
                label: "360",
                res: 360
            },
            {
                src: videoUrl,
                type: vid_type
            }
        ],
        techOrder: ['html5', 'youtube', 'vimeo'],

    }


    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });

    };


    const getAdsData = (chkAds) => {


        if (chkAds === null || chkAds === '') {
            console.log('null')
        } else {
            let infoads = ads_data.find(ads => ads.type === "info");
            setInfoType(infoads);
            let preAds = ads_data.find(ads => ads.type === "pre");
            setPreType(preAds);
            let midAds = ads_data.find(ads => ads.type === "mid");
            setMidType(midAds);
            let postAds = ads_data.find(ads => ads.type === "post");
            setPostType(postAds)

        }
    }


    const [duration, setDuration] = useState(null)


    const adTime = () => {
        if (midsTimes === 'undefined' || midsTimes === undefined) {
            console.log('')
        } else {
            if (midType !== null || midType !== undefined || midType !== 'undefined') {
                if (midsTimes !== 'undefined' || midsTimes !== null || midsTimes !== '0' || midsTimes !== undefined) {
                    if (midsTimes.length >= 4) {
                        let min = midsTimes.slice(0, 1)
                        let second = midsTimes.slice(2, 4);
                        let totalTime = Math.floor((min * 60 + Number(second)) / 2);
                        setDuration(Math.round(totalTime))
                    } else if (midsTimes.length >= 3) {
                        let min = midsTimes.slice(0, 1)
                        let second = midsTimes.slice(2, 3);
                        let totalTime = Math.floor((min * 60 + Number(second)) / 2);
                        setDuration(Math.round(totalTime))
                    }
                } else {
                    console.log('')
                }
            } else {
                console.log('')
            }
        }
    }


    const customizeButton = () => {

        const icon = document.querySelector(".video-js .vjs-big-play-button");
        icon.className = '';
        icon.classList.add('vjs-big-play-button', 'animate__animated', `${controls.style}`, 'animate__slow', 'animate__infinite');
       // icon.id='position'

        icon.style.backgroundImage = `url(${controls.icon})`;
        icon.style.backgroundColor = skin;
        let play1 = controls.icon;
        let getPlay1 = play1.search('play1');
        if (getPlay1 === 14) {
            icon.style.backgroundSize = "75%"
        } else {
            icon.style.backgroundSize = "100%"
        }
    }



    const textBtn = () => {
        let btnText = document.querySelector(".video-js .vjs-big-play-button span");
        btnText.innerHTML = `${controls.text}`
        btnText.style.color = `${controls.color}`;
        btnText.style.fontSize = `${controls.size}px`


    }


    const customizeSkin = () => {
        const volumeColor = document.querySelector(".video-js .vjs-volume-level");
        const playProgress = document.querySelector(".video-js .vjs-play-progress");
        const playBackRate = document.querySelector(".video-js .vjs-menu li.vjs-selected");

        const spinner = document.querySelector('.video-js .vjs-loading-spinner');
        const loading = document.querySelector('.video-js .video-js .vjs-load-progress');
        volumeColor.style.backgroundColor = skin;
        playProgress.style.backgroundColor = skin;
        playBackRate.style.backgroundColor = skin;
        spinner.style.border = `6px solid ${skin}`;

    }

    const customSkinHover = () => {
        const playBackRate = document.querySelector(".vjs-play-control");
        const PIP = document.querySelector(".video-js .vjs-picture-in-picture-control");
        const volumeBtn = document.querySelector(".video-js .vjs-mute-control");
        const playRateBtn = document.querySelector(".video-js .vjs-playback-rate");
        const fullScreen = document.querySelector(".video-js .vjs-fullscreen-control");


        fullScreen.addEventListener('mouseenter', function (event) {
            event.target.style.backgroundColor = skin;
        });
        fullScreen.addEventListener('mouseleave', function (event) {
            event.target.style.backgroundColor = "";
        });
        playBackRate.addEventListener('mouseenter', function (event) {
            event.target.style.backgroundColor = skin;
        });
        playBackRate.addEventListener('mouseleave', function (event) {
            event.target.style.backgroundColor = "";
        });

        if (navigator.appCodeName !== "Mozilla") {
            PIP.addEventListener('mouseenter', function (event) {
                event.target.style.backgroundColor = skin;
            });
            PIP.addEventListener('mouseleave', function (event) {
                event.target.style.backgroundColor = "";
            });
        }

        volumeBtn.addEventListener('mouseenter', function (event) {
            event.target.style.backgroundColor = skin;
        });
        volumeBtn.addEventListener('mouseleave', function (event) {
            event.target.style.backgroundColor = "";
        });
        playRateBtn.addEventListener('mouseenter', function (event) {
            event.target.style.backgroundColor = skin;
        });
        playRateBtn.addEventListener('mouseleave', function (event) {
            event.target.style.backgroundColor = "";
        });
    }


    const disableClk = () => {
        if (right_click === true) {
            document.querySelector('.video-js').addEventListener('contextmenu', (e) => {
                e.preventDefault();
            })
        } else {
            document.querySelector('.video-js').removeEventListener('contextmenu', (e) => {
                e.preventDefault();
            });
        }
    }


    function getBrowserName() {

        if (navigator.userAgent.indexOf("Edge") > -1 && navigator.appVersion.indexOf('Edge') > -1) {
            return setBrowser('Edge');
        } else if (navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1) {
            return setBrowser('Opera');
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            return setBrowser('Chrome');
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            return 'Safari';
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            return setBrowser('Firefox');
        } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
        {
            return setBrowser('IE');
        } else {
            return 'unknown';
        }
    }

    /*const vidResolution = ()=>{
        if(video_type==='mp4'){
            videojs("featured-video", {}, function () {
                let player = this;
                player.src([
                    {
                        src: videoUrl,
                        type: vid_type
                    },
                    {
                        src: vid1080,
                        type: vid_type,
                        label: "1080",
                        res: 1080
                    },
                    {
                        src: vid720,
                        type: vid_type,
                        label: "720",
                        res: 720
                    },
                    {
                        src: vid480,
                        type: vid_type,
                        label: "480",
                        res: 480
                    }, {
                        src: vid360,
                        type: vid_type,
                        label: "360",
                        res: 360
                    }])
                player.controlBar.addChild('QualitySelector');

            });
        }

    }*/




    useEffect(() => {
        if (resolution !== null) {
            let videosRes = JSON.parse(resolution);
            let videoResArray = Object.values(videosRes);

            videoJsOptions.sources[0].src = videoResArray[0];

            setVid360(videoResArray[0]);
            setVid480(videoResArray[1]);
            setVid720(videoResArray[2]);
            setVid1080(videoResArray[3]);
        }
    }, [resolution]);



    useEffect(() => {
        vidType();
        customizeButton();
        customizeSkin();
        getPlayVideos(playlist_id);
        customSkinHover();
        textBtn();
        setAutoPlay();
        disableClk();

        getAdsData(ads_data);
        getBrowserName();
        adTime();


    }, [vid_type, duration, preType, postType, midType, controls.color, controls.size, resolution, ads_data, setVideoURL, right_click, video_type, skin, controls.icon, controls.play, related_videos, playlist_id, controls.text, controls.style, autoPlay, vid1080, vid720, vid480, vid360]);

    useEffect(() => {
        if (social_share) {
            setDisplayIcon({
                fbIcon: social_share.facebook,
                instaIcon: social_share.instagram,
                twitterIcon: social_share.twitter,
                linkedIcon: social_share.linkedIn
            })
        }
    }, [social_share]);

   // console.log(menu_buttons)



    return (
        <>

            <VideoJS options={videoJsOptions} onReady={handlePlayerReady}
                     vidType={video_type} resolution={resolution} displayicon={displayIcon} title={title}
                     description={description}
                     poster={poster} url={url} videoType={vid_type} vid360={vid360} vid480={vid480} vid720={vid720}
                     vid1080={vid1080}
                     menu_buttons={menu_buttons} start={start_time} end={end_time}
                     cta={cta_data} cta_type={cta_type} skin={skin} loop={loop} playlistData={playlistData}
                     countdown={countdown} embed={embed_type} logo={logo} right_click={right_click}
                     relatedVid={related_videos} thumb={thumbnail} id={id}
                     color={controls.color} infoType={infoType} control={controls} browser={browser}
                     preType={preType}
                     midType={midType} postType={postType} duration={duration} setDuration={setVidDuration}/>

        </>
    );
}

export default Preview;
